import { Routes } from '@angular/router';
import { MyDashboardComponent } from './my-dashboard/my-dashboard.component';

const homeRoutes: Routes = [
  {
    path: 'my-dashboard',
    component: MyDashboardComponent
  }
];

export default homeRoutes;
