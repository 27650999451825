import { AclResource } from '../model/acl-resource';
import * as _ from 'lodash';
import { AclResourcesGroupedByResourceCategory } from '../model/acl-resource';
import { IamAuthUtils } from '../../shared/utils/iam-auth-utils';

export class AclUtils {
  static groupAclResources(aclResources: AclResource[]) {
    const categories = aclResources.map(
      (resource) => resource.resourceCategory
    ); // collect modules

    const uniqSortedCategories = _.sortBy(
      _.uniqBy(categories, (o) => o.displayName),
      [(o) => o.displayOrder]
    );

    const aclResourcesGroupedByCategory = _.chain(aclResources)

      .groupBy('resourceCategory.displayName')
      .map((value, key) => ({ resourceCategory: key, aclResources: value }))
      .value();

    const aclsSortedGroupedByResourceCategory: AclResourcesGroupedByResourceCategory[] =
      [];
    uniqSortedCategories.forEach((o) => {
      // @ts-ignore
      let acl = _.find(aclResourcesGroupedByCategory, {
        resourceCategory: o.displayName
      }).aclResources;
      acl = _.sortBy(acl, [(aclResource) => aclResource.displayOrder]);
      aclsSortedGroupedByResourceCategory.push({
        resourceCategory: o.displayName,
        aclResources: acl
      });
    });
    return aclsSortedGroupedByResourceCategory;
  }

  public static getACLS() {
    return IamAuthUtils.getCurrentUserIdentityDetails()['acls'];
  }

  static isPresent(array: any[], key: string, value: string) {
    let o;
    if (array) {
      array.some(function iter(a) {
        if (a === value) {
          o = a;
          return true;
        }
        return Array.isArray(a.aclResources) && a.aclResources.some(iter);
      });
    }
    return o;
  }

  public static hasAccess(resourceName: any) {

    if(resourceName) {
      const aclResourceNames = resourceName.split(',').map(value => value.trim());
      const userAcls = this.getACLS();
      const isAclPresent = aclResourceNames.some(aclKey => userAcls.includes(aclKey));
      return isAclPresent; //this.isPresent(acls, 'resourceName', resourceName);
    }
    return true; // if no resource name, assume user has permission, we are not restrictive

  }

  /*private static hasAccess(resourceNameList: any[]) {
    const acls = this.getACLS();
      for (const item of resourceNameList) {
        return this.isPresent(acls, 'resourceName', item);
      }
      return false;
  }

  private static isPresent(array: any[], key: string, value: string) {
    let o;
    if (array) {
      array.some(function iter(a) {
        if (a[key] === value) {
          o = a;
          return true;
        }
        return Array.isArray(a.aclResources) && a.aclResources.some(iter);
      });
    }
    return o;
  }*/
}
