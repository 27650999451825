<p-timeline [value]="events" layout="horizontal">
  <ng-template pTemplate="marker" let-event>
    <span
      class="custom-marker p-shadow-2"
      (click)="eventClickedOnTimeline(event)"
      [style.backgroundColor]="event.color"
    >
      <i [ngClass]="event.icon"></i>
    </span>
  </ng-template>
  <ng-template pTemplate="content" let-event>
    <span class="status" [attr.data-status]="event.status">
      <b>{{ event.title }}</b>
      <br />
      <small *ngIf="event.date">({{ event.date }})</small>
    </span>
  </ng-template>
</p-timeline>
