export class ActionHandler {
  actionListener: ActionListener[];
}

export class ActionListener {
  widgetToListen: string;
  eventName: string;
  actionChain: ActionChain[];
}

export enum ActionType {
  CALL_API = 'callApi',
  EXECUTE_SCRIPT = 'executeScript',
  SHOW_POPUP = 'showPopup',
  SET_FIELD_VALUES = 'setValueOnFields',
  REFRESH_SMART_TABLE = 'refreshSmartTable'
}
export class ActionChain {
  actionType: ActionType;
  apiConfig: ApiConfig;
  scriptExecutionConfig: ScriptExecutionConfig;
  popupConfig: IPopupConfig;
  fieldValuesConfig: FieldValues[];

  smartTableConfig: SmartTableActionConfig;
}

/**
 * Renamed PopupConfig to IPopupConfig as this was conflicting with PopupConfig in CustomActionButton model.
 */
export class IPopupConfig {
  popupScreenId: string;
  contentStyle?: {
    padding: 0,
    overflow: 'scroll',
    'max-height': '80vh'
  }
}

export class SmartTableActionConfig {
  smartTableId: string;
}

export class FieldValues {
  fieldName: string;
  fieldValue: string;
  appendIfArray: boolean;
}

export class ScriptExecutionConfig {
  scriptToExecute: string;
}

export class ApiConfig {
  url: string;
  methodName: string;
  requestBodyParameter: string;
  skipValidation: string;
  responseContextVariable: string;
  beforePost: string;
}
