<div *ngIf="showLoginScreen">
  <div class="container-fluid login_page_body">
    <div class="row justify-content-center align-items-center">
      <div class="col-md-3 col-xs-3 mx-auto login_form_card">
        <div class="text-center mt-2"></div>

        <form
          class="mt-4"
          [formGroup]="loginForm"
          action=""
          onSubmit="{this.handleSubmit}"
        >
          <div class="mx-2 danger" *ngIf="error">
            <p>{{ error }}</p>
          </div>
          <div class="mt-4 mx-2">
            <input
              [ngClass]="{
                'is-invalid':
                  (loginForm.get('email')?.touched &&
                    loginForm.get('email')?.errors) ||
                  error
              }"
              class="form-control"
              formControlName="email"
              placeholder="Login User Email"
              pInputText
            />
            <!-- oninput="this.value = this.value.toLowerCase()"-->
            <div
              *ngIf="
                loginForm.get('email')?.touched &&
                loginForm.get('email')?.errors
              "
              class="invalid-feedback"
            >
              <div *ngIf="loginForm.controls['email'].hasError('required')">
                Email is required
              </div>
              <div *ngIf="loginForm.controls['email'].hasError('pattern')">
                Invalid Email
              </div>
            </div>
          </div>

          <div class="mt-4 mx-2">
            <input
              [ngClass]="{
                'is-invalid':
                  (loginForm.get('password')?.touched &&
                    loginForm.get('password')?.errors) ||
                  error
              }"
              class="form-control"
              type="password"
              formControlName="password"
              placeholder="Secret"
              pInputText
            />
            <div
              *ngIf="
                loginForm.get('password')?.touched &&
                loginForm.get('password')?.errors
              "
              class="invalid-feedback"
            >
              <div *ngIf="loginForm.controls['password'].hasError('required')">
                Password is required
              </div>
            </div>
          </div>

          <div class="mt-4 mx-2">
            <p-button
              label="Login"
              class="btn btn-primary col-md-12"
              (click)="login()"
              [disabled]="loginForm.invalid"
            ></p-button>
          </div>

          <!--   <div class="mx-2">
          <div class="text-center"><a routerLink="/auth/forgot-password">Forgot Password</a></div>
        </div>-->
        </form>
      </div>
    </div>
  </div>
</div>
