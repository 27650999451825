import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BaseRestService } from '../../../shared/services/base-rest.service';
import { environment } from '../../../../environments/environment';
import { User } from '../model/user';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserService extends BaseRestService<User> {
  constructor(public http_: HttpClient) {
    super(http_, `${environment.iamBaseApi}/user`);
  }

  getCurrentUserProfile(): Observable<any> {
    return this.http.get(`${this.restUrl}/my-profile`);
  }

  getIamUsersByAclResourceNamesList(
    aclResourceNames: string[]
  ): Observable<any> {
    return this.http
      .post<any>(`${this.restUrl}/users-with-permission`, aclResourceNames)
      .pipe(map((res) => res));
  }

  getUsersByIamIds(iamIds: string[]) {
    return this.http
      .post<any>(this.restUrl + '/users-with-iam-ids', iamIds)
      .pipe(map((data) => data));
  }
}
