import {
  CustomButtonActionHandler,
  CustomButtonActionHandlerContext
} from '@shared/components/custom-action-button/handlers/custom-button-action-handler';
import { CustomButtonActionType } from '@shared/components/custom-action-button/handlers/custom-button-action-handler-registry';
import { DialogService } from 'primeng/dynamicdialog';
import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { LodashTemplateEvaluator } from '@shared/components/smart-table/LodashTemplateEvaluator';
import { Utils as FormioUtils } from '@evi-ui/js';
import { FormRendererUtils } from '../../../../portal/application-forms/all-forms-renderer/form-renderer-utils';
import { Router } from '@angular/router';
import { CustomActionButtonUtils } from '@shared/components/custom-action-button/custom-action-button-utils';

@Injectable({ providedIn: 'root' })
export class NavigateToUrlActionProcessor implements CustomButtonActionHandler {
  constructor(
    private dialogService: DialogService,
    private formRendererUtils: FormRendererUtils,
    private router: Router,
    private customActionButtonUtils: CustomActionButtonUtils
  ) {}

  isApplicable(actionType: CustomButtonActionType): boolean {
    return actionType === CustomButtonActionType.navigateToUrl;
  }

  performAction(context: CustomButtonActionHandlerContext): Promise<any> {
    //={{_.random(1,1000000)}}

    let navigationConfig = context.actionProcessor.navigationConfig;
    let formInstance = context.formInstance;

    //Close any orphaned/dangling popups.
    this.dialogService.dialogComponentRefMap?.forEach((dialog) => {
      dialog?.destroy();
    });

    if (navigationConfig && navigationConfig.navigateToUrl) {
      let stateToPropagate = navigationConfig.stateToPropagate;
      if (navigationConfig.stateToPropagate) {
        stateToPropagate = _.get(
          formInstance.submission.data,
          navigationConfig.stateToPropagate
        );
      } else if (navigationConfig.stateToPropagateEvaluator) {
        stateToPropagate = LodashTemplateEvaluator.interpolate(
          navigationConfig.stateToPropagateEvaluator,
          formInstance.submission
        );
        stateToPropagate = FormioUtils.evaluate(
          stateToPropagate,
          formInstance.submission,
          'value',
          true
        );
      }

      if (stateToPropagate && stateToPropagate.action == 'cancelNavigation') {
        this.formRendererUtils.openConfirmationPopup({
          message: stateToPropagate.message,
          popupScreenId: 'generic-pop-up',
          headerMessage: ''
        });
        return Promise.reject({});
      }

      let navigateTo;
      let extractedVariables =
        this.customActionButtonUtils.extractDataMapVariables(
          navigationConfig.navigateToUrl,
          context.buttonName
        );

      if (navigationConfig.navigateToUrl === extractedVariables) {
        //This means the URL does not contain any extraction variables
        let interpolatedValue = LodashTemplateEvaluator.interpolate(
          navigationConfig.navigateToUrl,
          formInstance.submission
        );
        interpolatedValue = LodashTemplateEvaluator.interpolate(
          interpolatedValue,
          formInstance
        );
        navigateTo = interpolatedValue;
      } else {
        navigateTo = LodashTemplateEvaluator.interpolate(
          extractedVariables,
          formInstance.submission
        );
      }

      if (navigateTo) {
        return this.router.navigate([navigateTo], {
          state: { state: stateToPropagate }
        });
      } else {
        return Promise.reject({});
      }
    } else {
      return Promise.reject({});
    }
  }
}
