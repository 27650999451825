import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output
} from '@angular/core';
import {Formio, FormioCustomComponent} from '@evi-ui/angular';
import {Router} from '@angular/router';
import {FormioEvent} from '@evi-ui/angular/elements.common';
import * as _ from 'lodash';
import {isJson} from '@shared/utils/utils';
import {
  AfterPostToUrlAction,
  CustomActionType,
  CustomButtonActionHandlerRegistry
} from './handlers/custom-button-action-handler-registry';
import {LodashTemplateEvaluator} from '../smart-table/LodashTemplateEvaluator';
import {Utils as FormioUtils} from '@evi-ui/js';
import {EventBusService} from '../../event-bus.service';
import {EventType} from '../../model/event.data';
import {flatten} from 'flat';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {FormRendererUtils} from '../../../portal/application-forms/all-forms-renderer/form-renderer-utils';
import {
  ActionProcessor,
  ExecuteScriptAndShowPopupConfig,
  FileDownloadConfig,
  GetDataFromApiConfig,
  NavigationConfig,
  PopupConfig,
  PostToUrlConfig,
  SmartTableResetSearchResultsConfig
} from './custom-action-button-model';
import {IamAuthUtils} from '../../utils/iam-auth-utils';
import FileSaver from 'file-saver';
import {CustomActionButtonUtils} from './custom-action-button-utils';

import * as moment_ from 'moment';
import {ToastrService} from 'ngx-toastr';
import {environment} from '../../../../environments/environment';
import {SubmitSuccessPopup} from '../../../portal/application-forms/all-forms-renderer/screen-config';
import {IamUserService} from '../../../iam/iam-user/iam-user.service';
import {DialogService, DynamicDialogRef} from 'primeng/dynamicdialog';
import {
  CustomButtonActionHandlerContext
} from '@shared/components/custom-action-button/handlers/custom-button-action-handler';

const moment = moment_;

const regex = /\{\{([^}]+)\}\}/g;

@Component({
  selector: 'toggle-button',
  templateUrl: './custom-action-button.component.html',
  styleUrls: ['./custom-action-button.component.scss']
})
export class CustomActionButtonComponent
  implements FormioCustomComponent<any>, AfterViewInit, OnInit {
  destroyRef = inject(DestroyRef);
  public _data: any;
  public set data(value: any) {
    this._data = value;
  }


  private _formInstance: any;

  private _formData: any;

  @Input()
  name: string;

  constructor(
    private router: Router,
    private eventBusService: EventBusService,
    private ourUtils: CustomActionButtonUtils,
    private formRenderUtils: FormRendererUtils,
    private changeDetection: ChangeDetectorRef,
    private iamUserService: IamUserService,
    private toastrService: ToastrService,
    private dialogService: DialogService,
    private actionRegistry: CustomButtonActionHandlerRegistry,
    private dialogRef: DynamicDialogRef
  ) {
    this.actionRegistry.setDialogRef(dialogRef);

    this.eventBusService
      .on(EventType.FORM_READY)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((form) => {
        this._formInstance = form;
      });

    this.eventBusService
      .on(EventType.FORM_DATA_LOADED)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((formData) => {
        if (formData == null) return;
        this._formData = formData;
        if (this.dynamicLabelValue) {
          const updatedLabel = LodashTemplateEvaluator.interpolate(
            this.dynamicLabelValue,
            formData
          );
          if (updatedLabel) {
            this.label = updatedLabel;
            changeDetection.detectChanges();
          }
        }
      });
  }

  @Input()
  label: string;

  @Input()
  dynamicLabelValue: string;

  @Input()
  rightIcon?: string;

  @Input()
  leftIcon?: string;

  @Input()
  blockButton?: string = '';

  @Input()
  dropDownButton?: boolean;

  @Input()
  showInWorkflowItem?: boolean;

  @Input()
  dropDownButtonOptions?: any[];

  @Input()
  customConditional?: string;

  @Input()
  actionProcessors?: ActionProcessor[];

  _actionType: CustomActionType;
  @Input()
  public set actionType(val: CustomActionType) {
    if (!val) return;
    this._actionType = val;
  }

  @Input()
  private advanceSearchAttributesTemplate;

  @Input()
  private smartTableToApplyAdvancedSearchAction: string;

  @Input()
  private smartTableResetSearchResultsConfig?: SmartTableResetSearchResultsConfig;

  @Input()
  private navigationConfig?: NavigationConfig;

  @Input()
  private fileDownloadConfig?: FileDownloadConfig;

  @Input()
  private postToUrlConfig?: PostToUrlConfig;

  @Input()
  private scriptExecutionWithPopupConfig?: ExecuteScriptAndShowPopupConfig;

  @Input()
  private getDataFromApiConfig?: GetDataFromApiConfig;

  @Input()
  private showPopupConfig?: PopupConfig;

  private _tabToClone: string;
  @Input()
  public set tabToClone(val: string) {
    if (!val) return;
    this._tabToClone = val;
  }

  _parentKey: string;
  @Input()
  public set parentKey(val: string) {
    if (!val) return;
    this._parentKey = val;
  }

  _tabPosition: string;
  @Input()
  public set tabPosition(val: string) {
    if (!val) return;
    this._tabPosition = val;
  }

  private _value: any = {};
  @Input()
  public set value(val: any) {
    if (!val) {
      return;
    }
    this._value = val;
  }

  public get value() {
    return this._value;
  }

  @Output()
  valueChange = new EventEmitter<any>();

  @Output()
  formioEvent = new EventEmitter<FormioEvent>();

  @Input()
  disabled = false;

  private _options = new Map();
  @Input()
  public set options(opts: any) {
    this._options = new Map();
    if (opts && opts.length > 0) {
      opts.forEach((option: { label; value }) => {
        this._options[option.label] = option.value;
      });
    }
  }

  public get options(): any {
    return this._options;
  }

  @Input()
  customClass: string;

  @Input()
  theme = 'primary';

  @Input()
  public hidden = null;

  public isHidden() {
    console.log('called isHidden: ', this.hidden);
    return this.hidden;
  }

  ngAfterViewInit() {
  }

  public async onClick(event: any, navigateUrl?: any) {
    if (this.actionProcessors?.length) {
      console.log(
        'Using Action Processors Chain',
        this.actionProcessors,
        ' in ',
        this.label
      );
      for (const actionProcessor of this.actionProcessors) {
        console.log(`Processing actionProcessor ${actionProcessor.actionName} for ${this.label} with data ${this.data}`);
        if (actionProcessor.isConditional && actionProcessor.conditionToEvaluate) {
          const canExecute = FormioUtils.evaluate(
            actionProcessor.conditionToEvaluate,
            {
              submission: this._formInstance.submission,
              data: this._formData,
              formInstance: this._formInstance
            },
            'canExecute',
            true
          );
          if (!canExecute) {
            console.log(`Skipping execution of ${actionProcessor}`);
            this._formInstance[actionProcessor.actionName] = 'SKIPPED';
            continue;
          }
        }
        const actionHandlerContext = new CustomButtonActionHandlerContext();
        actionHandlerContext.buttonName = this.name;
        actionHandlerContext.actionProcessor = actionProcessor;
        actionHandlerContext.formInstance = this._formInstance;
        actionHandlerContext.formData = this._formData;
        actionHandlerContext.formioEvent = this.formioEvent;
        const actionResult = await this.actionRegistry
          .provider(actionProcessor.actionType)
          .performAction(actionHandlerContext);
        console.log(
          'Action Result',
          actionResult,
          ' for ',
          this.label,
          ' processor ',
          actionProcessor.actionName
        );
        //this._formInstance[actionHandlerContext.actionProcessor.actionName] =
        if (Array.isArray(actionResult) && this.isPromise(actionResult[0])) {
          this._formInstance[actionHandlerContext.actionProcessor.actionName] = await actionResult[0];
        } else {
          this._formInstance[actionHandlerContext.actionProcessor.actionName] = actionResult;
        }

        console.log("Action Processor Result: ", this._formInstance[actionHandlerContext.actionProcessor.actionName]);
      }
      return;
    }
    //Legacy implementation
    switch (this._actionType) {
      case CustomActionType.advanceFilterAction:
        this.performAdvanceSearch();
        break;
      case CustomActionType.resetSmartTableResults:
        this.resetSmartTableResults();
        break;
      case CustomActionType.navigate:
        this.navigate(event, navigateUrl);
        break;
      case CustomActionType.showPopup:
        this.handlePopup();
        break;
      case CustomActionType.postToUrl:
        this.postToUrl();
        break;
      case CustomActionType.getDataFromApi:
        this.getDataFromApi();
        break;
      case CustomActionType.executeScriptAndShowPopup:
        this.executeScript();
        break;
      case CustomActionType.downloadFile:
        this.triggerFileDownload();
        break;
      case CustomActionType.others:
        this.doSomething();
        break;
    }
    this.resolveInterpolation();
  }

  private refreshSmartTable(responseValueFromApi: any) {
    let smartTableComponent;
    console.log(this.getDataFromApiConfig?.smartTableComponent);

    // @ts-ignore
    if (
      this.getDataFromApiConfig?.smartTableComponent &&
      _.size(this.getDataFromApiConfig?.smartTableComponent)
    ) {
      FormioUtils.findComponent(
        this._formInstance.components,
        this.getDataFromApiConfig?.smartTableComponent[0],
        '',
        (component) => {
          smartTableComponent = component;
          if (this.getDataFromApiConfig?.action == 'setResponseAsValue') {
            smartTableComponent.setValue(responseValueFromApi, {
              fromSubmission: true
            });
          } else if (this.getDataFromApiConfig?.action == 'refresh') {
            smartTableComponent.setValue({
              action: 'refresh'
            });
          } else {
            //Backward Compatibility for SmartTable
            smartTableComponent.setValue({
              action: 'refresh'
            });
          }
        }
      );
    }
  }

  private performAdvanceSearch() {
    const componentsToApplyAction = this.smartTableToApplyAdvancedSearchAction;

    for (const componentId of componentsToApplyAction) {
      FormioUtils.findComponent(
        this._formInstance.components,
        componentId,
        '',
        (component) => {
          const actionValue = LodashTemplateEvaluator.interpolate(
            this.advanceSearchAttributesTemplate,
            this._formInstance.submission
          );
          const interpolateTemplate = FormioUtils.evaluate(
            actionValue,
            this._formInstance.submission,
            'value',
            true
          );
          console.log(interpolateTemplate);
          component.setValue({
            advanceSearchQuery: interpolateTemplate
          });
        }
      );
    }
  }

  private navigate(event: any, navigateUrl?: string) {
    this.dialogService.dialogComponentRefMap?.forEach((dialog) => {
      dialog?.destroy();
    });

    let navigateTo = _.defaultTo(this._options['navigateTo'], navigateUrl);
    console.log('navigateUrl is => ', navigateUrl);
    let stateToPropagate;

    if (this.navigationConfig && this.navigationConfig.navigateToUrl) {
      if (this.navigationConfig.stateToPropagate) {
        stateToPropagate = _.get(
          this._formInstance.submission.data,
          this.navigationConfig.stateToPropagate
        );
      } else if (this.navigationConfig.stateToPropagateEvaluator) {
        stateToPropagate = LodashTemplateEvaluator.interpolate(
          this.navigationConfig.stateToPropagateEvaluator,
          this._formInstance.submission
        );
        stateToPropagate = FormioUtils.evaluate(
          stateToPropagate,
          this._formInstance.submission,
          'value',
          true
        );
      }

      if (stateToPropagate && stateToPropagate.action == 'cancelNavigation') {
        this.formRenderUtils.openConfirmationPopup({
          message: stateToPropagate.message,
          popupScreenId: 'generic-pop-up',
          headerMessage: ''
        });
        return;
      }

      const extractedVariables = this.ourUtils.extractDataMapVariables(
        this.navigationConfig.navigateToUrl,
        this.name
      );

      if (this.navigationConfig.navigateToUrl === extractedVariables) {
        //This means the URL does not contain any extraction variables
        let interpolatedValue = LodashTemplateEvaluator.interpolate(
          this.navigationConfig.navigateToUrl,
          this._formInstance.submission
        );
        interpolatedValue = LodashTemplateEvaluator.interpolate(
          interpolatedValue,
          this._formInstance
        );
        navigateTo = interpolatedValue;
      } else {
        navigateTo = LodashTemplateEvaluator.interpolate(
          extractedVariables,
          this._formInstance.submission
        );
      }
    } else if (navigateUrl) {
      navigateTo = LodashTemplateEvaluator.interpolate(
        navigateTo,
        this._formInstance.submission
      );
    } else {
      // TODO: The else here is only for backward compatibility. It should be removed once the forms are changed to use the navigateToUrl input field
      if (navigateTo) {
        const matches = navigateTo.match(regex);
        if (matches) {
          const flattenedData = flatten(this._formInstance.submission.data);
          for (const m of matches) {
            const apiDataPath = m.replaceAll('{{', '').replaceAll('}}', '');
            const valueToReplace = flattenedData[apiDataPath];
            navigateTo = navigateTo?.replaceAll(m, valueToReplace);
          }
        }
      }
    }

    if (navigateTo) {
      this.router.navigate([navigateTo], {
        state: {state: stateToPropagate}
      });
    }
  }

  private handlePopup() {
    this.formRenderUtils.showPopup(
      this.showPopupConfig!,
      this._formInstance.submission.data
    );
  }

  private afterPostAction(requestBody, responseData, rawResponseWithHeaders?) {
    console.log(responseData);
    if (this.postToUrlConfig?.afterPost?.action) {
      //AfterPostToUrlAction
      switch (this.postToUrlConfig.afterPost.action) {
        case AfterPostToUrlAction.noAction:
          break;
        case AfterPostToUrlAction.processApiResponse:
          this.processApiResponse(responseData);
          break;
        case AfterPostToUrlAction.downloadFile:
          this.downloadFile(responseData, rawResponseWithHeaders);
          if (this.postToUrlConfig.afterPost.navigationUrl) {
            this.navigate({}, this.postToUrlConfig.afterPost.navigationUrl);
          }
          break;
        case AfterPostToUrlAction.navigate:
          if (this.postToUrlConfig?.afterPost?.navigationUrl) {
            this.navigateViaBrowserUrl(responseData);
          }
          break;
        case AfterPostToUrlAction.showPopup:
          break;
        case AfterPostToUrlAction.showPopupAndNavigate:
          this.showPopupAndNavigateIfApplicable(requestBody, responseData);
          break;
        default:
          break;
      }
    }
  }

  navigateViaBrowserUrl(responseData: any) {
    if (this.isPromise(responseData)) {
      responseData.then(
        (response) => {
          const urlToNavigate = LodashTemplateEvaluator.interpolate(
            this.postToUrlConfig?.afterPost?.navigationUrl,
            {data: response}
          );
          window.location.href = urlToNavigate;
        }
      );
    } else {
      const urlToNavigate = LodashTemplateEvaluator.interpolate(
        this.postToUrlConfig?.afterPost?.navigationUrl,
        responseData
      );
      window.location.href = urlToNavigate;
    }
  }

  private isPromise(responseData) {
    return Boolean(responseData && typeof responseData.then === 'function');
  }

  private showPopupAndNavigateIfApplicable(requestBody, submission) {
    if (this.isPromise(submission)) {
      submission.then(
        (response) => {
          this.showPopupAndNavigate(requestBody, response);
        },
        (error) => {
          console.log(error);
        }
      );
    } else {
      this.showPopupAndNavigate(requestBody, submission);
    }
  }

  private promptApproverSelectionForAcl(response: any) {
    return response && response.workflowRoles;
  }

  private promptApproverSelectionForResponse(submission: any) {
    return (
      this.postToUrlConfig &&
      submission.hasOwnProperty('message') &&
      submission.hasOwnProperty('nextAction') &&
      submission.nextAction &&
      _.size(submission.nextAction.allowedRoles) > 0 &&
      (_.isUndefined(submission.nextAction.broadcastRequest) ||
        !submission.nextAction.broadcastRequest)
    );
  }

  private showPopupAndNavigate(requestBody, submission) {
    //TODO: This if-block is specific to workflow. Needs refactoring.
    if (
      environment.enableWorkflow &&
      (this.promptApproverSelectionForAcl(submission) ||
        this.promptApproverSelectionForResponse(submission))
    ) {
      const aclList = submission.nextAction
        ? submission.nextAction['allowedRoles']
        : submission.workflowRoles;
      this.iamUserService
        .getFilteredUsersWithAcl(aclList, [
          IamAuthUtils.getCurrentLoggedUserIamId()
        ])
        .subscribe((users) => {
          //TODO: Change this snippet to choose the popup formId dynamically
          const workflowParticipantsPopup = new SubmitSuccessPopup();
          workflowParticipantsPopup.message = 'Choose';
          workflowParticipantsPopup.popupScreenId =
            'workflow-participant-chooser';
          workflowParticipantsPopup.headerMessage = 'Approvers!!';
          workflowParticipantsPopup.data = {
            workflowParticipants: users,
            submissionData: submission.data
          };
          workflowParticipantsPopup.returnValueStorageAttribute =
            submission.nextAction ? 'nextStepWorkflowActor' : 'workflowActor';

          const uiConfig = {
            width: '45%',
            closable: false,
            modal: true,
            showHeader: false,
            contentStyle: {
              padding: 0
            },
            baseZIndex: 10000
          };
          console.log(
            'ACLs: ',
            users,
            'Self: ',
            IamAuthUtils.getCurrentLoggedUserIamId()
          );
          this.formRenderUtils
            .openPopupWithUIConfig(
              workflowParticipantsPopup,
              uiConfig,
              requestBody
            )
            .then((popupResponse) => {
              console.log(popupResponse);
              console.log(submission.data);
              delete submission.roles;
              console.log(requestBody);
              console.log(submission);
              if (submission.nextAction) {
                requestBody.nextStepWorkflowActor = popupResponse;
              } else {
                requestBody.workflowActor = popupResponse;
              }
              requestBody.currentUser = {
                id: IamAuthUtils.getCurrentLoggedUserIamId(),
                name: IamAuthUtils.getCurrentUserDetails().displayName
              }
              this.postToUrlConfig!.requestBodyParameter = 'requestBody';
              this.postToUrlConfig!.beforePost = '';
              this._formInstance.submission['requestBody'] = requestBody; //Attaching to submission rather than `data` so as not to pollute the `data` JSON
             // to fix the popup issue with "submission completed message" and not closing upon clicking ok. fix is copied from allFormRenderee workflow poup handling
              setTimeout(() => {
                this.sendToServer();
              }, 1010);

            });
        });
    } else {
      const popupConfig = submission.hasOwnProperty('errorCode')
        ? this.postToUrlConfig?.afterPost?.errorPopupConfig
        : this.postToUrlConfig?.afterPost?.successPopupConfig;
      popupConfig!.message = LodashTemplateEvaluator.interpolate(
        popupConfig?.message,
        submission
      );
      popupConfig!.redirectUrlAfterSubmission =
        LodashTemplateEvaluator.interpolate(
          popupConfig?.redirectUrlAfterSubmission,
          submission
        );
      this.formRenderUtils.openPopup(popupConfig!);
    }
  }

  private postToUrl() {
    if (this.postToUrlConfig?.showPopup) {
      this.executeConditionAndShowPopup();
    } else {
      this.executeSendToServer();
    }
  }

  private executeSendToServer() {
    if (this.postToUrlConfig?.skipValidation) {
      this.sendToServer();
    } else {
      this._formInstance
        .executeSubmit()
        .then(() => this.sendToServer())
        .catch((err) => console.log(err));
    }
  }

  private executeConditionAndShowPopup() {
    if (this.postToUrlConfig?.executePopupCondition) {
      const scriptValue = LodashTemplateEvaluator.interpolate(
        this.postToUrlConfig?.executePopupCondition,
        this._formInstance.submission
      );
      const show = FormRendererUtils.evaluate(
        scriptValue,
        this._formInstance.submission,
        'show',
        true
      );
      if (show) {
        const dialogRef = this.formRenderUtils.openConfirmationPopup(
          this.showPopupConfig!
        );
        if (dialogRef) {
          dialogRef.onClose.subscribe((result: any) => {
            if (result?.confirm) {
              this.executeSendToServer();
            }
          });
        }
      } else {
        this.executeSendToServer();
      }
    }
  }

  private sendToServer() {
    if (this.postToUrlConfig) {
      const url = LodashTemplateEvaluator.interpolate(
        this.postToUrlConfig.url,
        this._formInstance.submission
      );
      const beforePost = this.postToUrlConfig.beforePost;
      let requestBody = this._formInstance.submission.data;
      let beforePostHookResponse;
      if (beforePost) {
        let actionValue = this.ourUtils.extractDataMapVariables(
          beforePost,
          this.name
        );
        actionValue = LodashTemplateEvaluator.interpolate(
          actionValue,
          this._formInstance.submission
        );
        beforePostHookResponse = FormioUtils.evaluate(
          actionValue,
          {
            ...this._formInstance.submission,
            formInstance: this._formInstance,
            moment
          },
          'value',
          true
        );
        if (!this.validateHookResponse(beforePostHookResponse)) {
          return;
        }
        requestBody = beforePostHookResponse;
      }

      if (this.postToUrlConfig.requestBodyParameter) {
        requestBody = _.get(
          this._formInstance.submission.data,
          this.postToUrlConfig.requestBodyParameter
        );
        if (!requestBody) {
          //The consumer can pass the data in submission instead of data
          requestBody = _.get(
            this._formInstance.submission,
            this.postToUrlConfig.requestBodyParameter
          );
        }
      }

      let apiMethod = this.postToUrlConfig.methodName;
      if (!apiMethod) {
        apiMethod = 'POST';
      }

      Formio.fetch(url, {
        body: JSON.stringify(requestBody),
        headers: {
          'content-type': 'application/json',
          Authorization: 'Bearer ' + IamAuthUtils.getCurrentUserToken()
        },
        method: apiMethod
      })
        .then((resp) => {
          if (resp.headers.has('content-disposition')) {
            // file download
            return [resp.blob(), resp.clone()];
          }
          const json = resp.json();
          this.processApiResponse(json);
          return [json, json];
        })
        .then(([responseData, rawResponseWithHeaders]) =>
          this.afterPostAction(
            requestBody,
            responseData,
            rawResponseWithHeaders
          )
        );
    }
  }

  private getDataFromApi() {
    let apiUrl = this.getDataFromApiConfig?.apiUrl;
    const _this = this;
    if (apiUrl) {
      const requestProcessor = this.getDataFromApiConfig?.beforeApiHook;
      if (requestProcessor) {
        FormRendererUtils.evaluate(
          requestProcessor,
          this._formInstance.submission,
          'value',
          true
        );
      }
      apiUrl = LodashTemplateEvaluator.interpolate(
        apiUrl,
        this._formInstance.submission
      );
      Formio.fetch(apiUrl, {
        // body: JSON.stringify(requestBody),
        headers: {
          'content-type': 'application/json',
          Authorization: 'Bearer ' + IamAuthUtils.getCurrentUserToken()
        },
        method: this.getDataFromApiConfig?.apiMethod
      })
        .then((resp) => resp.json())
        .then((apiResponse) => {
          let responseValueToUse;
          if (this.getDataFromApiConfig?.dataPath) {
            //responseValueToUse = apiResponse[this.getDataFromApiConfig.dataPath];
            responseValueToUse = _.get(
              apiResponse,
              this.getDataFromApiConfig.dataPath
            );
          } else {
            responseValueToUse = apiResponse;
          }

          if (this.getDataFromApiConfig?.storageAttribute) {
            const existingData = _.get(
              _this._formInstance.submission.data,
              this.getDataFromApiConfig.storageAttribute
            );
            if (existingData && isJson(existingData)) {
              responseValueToUse = _.merge(existingData, responseValueToUse);
            }
            _.set(
              _this._formInstance.submission.data,
              this.getDataFromApiConfig.storageAttribute,
              responseValueToUse
            );
          } else {
            if (_this._formInstance.submission.data && isJson(responseValueToUse)) {
              responseValueToUse = _.merge(
                _this._formInstance.submission.data,
                responseValueToUse
              );
            }
            _.set(_this._formInstance.submission, 'data', responseValueToUse);
          }
          if (this.getDataFromApiConfig?.smartTableComponent && this.getDataFromApiConfig?.smartTableComponent.length) {
            if (this.getDataFromApiConfig?.smartTableStorageAttribute) {
              _this.refreshSmartTable(
                _.get(
                  responseValueToUse,
                  this.getDataFromApiConfig.smartTableStorageAttribute
                )
              );
            } else {
              _this.refreshSmartTable(responseValueToUse);
            }
          }

          if (this.getDataFromApiConfig?.responseProcessor) {
            const valueFromResponseProcessor = FormRendererUtils.evaluate(
              this.getDataFromApiConfig.responseProcessor,
              {
                'formInstance': this._formInstance,
                'responseValue': responseValueToUse,
                'data': this._formInstance.submission.data
              },
              'value',
              true
            );
            if (valueFromResponseProcessor && this.getDataFromApiConfig?.storageAttribute) {
              _.set(
                _this._formInstance.submission.data,
                this.getDataFromApiConfig!.storageAttribute!,
                valueFromResponseProcessor
              );
            }
          }

        });
    }
  }

  private executeScript() {
    if (this.scriptExecutionWithPopupConfig?.scriptToExecute) {
      const scriptValue = LodashTemplateEvaluator.interpolate(
        this.scriptExecutionWithPopupConfig?.scriptToExecute,
        this._formInstance.submission
      );
      const response = FormRendererUtils.evaluate(
        scriptValue,
        [this._formInstance.submission, this._formInstance],
        'response',
        true
      );
      if (response?.showPopup && response?.popupMessage) {
        const popupConfig = new PopupConfig();
        popupConfig.message = response.popupMessage;
        popupConfig.popupScreenId =
          this.scriptExecutionWithPopupConfig.popupScreenId;
        this.formRenderUtils.showPopup(popupConfig, {});
      } else {
        this.setDataOnSmartTable(response);
      }

      if (response?.hasOwnProperty('data')) {
        this._formInstance.submission = {
          data: Object.assign({}, response.data)
        };
      }
    }
  }

  private setDataOnSmartTable(response) {
    if (
      response.hasOwnProperty('action') &&
      response.action == 'refreshSmartTable'
    ) {
      for (const [key, value] of Object.entries(response)) {
        if (key != 'action') {
          // @ts-ignore
          FormRendererUtils.setDataOnSmartTable(
            this._formInstance.components,
            key,
            value
          );
        }
      }
    }
    if (response?.hasOwnProperty('data')) {
      this._formInstance.submission = {
        data: Object.assign({}, response.data)
      };
    }
  }

  private downloadFile(blobDataPromise, rawResponseWithHeaders) {
    const contentDispositionHeader = rawResponseWithHeaders.headers.get(
      'Content-Disposition'
    );
    let fileName = contentDispositionHeader.match(
      /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
    )[1];
    if (fileName) {
      fileName = fileName.replaceAll('"', '');
    }
    this.saveOrOpenBlob(blobDataPromise, fileName);
    //Close all popups, if any are open
    this.dialogService.dialogComponentRefMap?.forEach((dialog) => {
      dialog?.destroy();
    });
    if (this.fileDownloadConfig?.navigateToUrl) {
      const navigateTo = LodashTemplateEvaluator.interpolate(
        this.fileDownloadConfig.navigateToUrl,
        {}
      );
      this.router.navigate([navigateTo]);
    }
  }

  saveOrOpenBlob(blobDataPromise, fileName?) {
    blobDataPromise.then((blob) => {
      FileSaver.saveAs(blob, fileName);
    });
  }

  private triggerFileDownload() {
    if (this.fileDownloadConfig) {
      let url = this.formRenderUtils.interpolate(
        this.fileDownloadConfig.apiUrl,
        this._formInstance.submission.data
      );

      url = this.formRenderUtils.interpolate(
        url,
        this._formInstance.submission
      );

      url = this.formRenderUtils.interpolate(
        url,
        this._formInstance
      );

      let apiMethod = this.fileDownloadConfig.apiMethod;
      if (!apiMethod) {
        apiMethod = 'GET';
      }

      let requestBody = {};
      if (apiMethod !== 'GET') {
        const apiRequest = this.fileDownloadConfig.apiRequest;
        if (apiRequest) {
          const actionValue = LodashTemplateEvaluator.interpolate(
            this.fileDownloadConfig.apiRequest,
            this._formInstance.submission
          );
          requestBody = FormioUtils.evaluate(
            actionValue,
            this._formInstance.submission,
            'value',
            true
          );
        }
      }

      Formio.fetch(url, {
        body: apiMethod !== 'GET' ? JSON.stringify(requestBody) : '',
        headers: {
          'content-type': 'application/json',
          Authorization: 'Bearer ' + IamAuthUtils.getCurrentUserToken()
        },
        method: apiMethod,
        cache: 'no-store'
      })
        .then((resp) => {
          if (resp.headers.has('content-disposition')) {
            // file download
            return [resp.blob(), resp.clone()];
          }
          return [resp.json(), resp.clone()];
        })
        .then(([responseData, rawResponseWithHeaders]) =>
          this.downloadFile(responseData, rawResponseWithHeaders)
        );
    }
  }

  private doSomething() {
  }

  private processApiResponse(responseData) {
    if (this.postToUrlConfig?.responseContextVariable) {
      if (this.isPromise(responseData)) {
        responseData.then((response) => {
          this.executeApiResponseProcessor(response);
        });
      } else {
        this.executeApiResponseProcessor(responseData);
      }
    }
  }

  private executeApiResponseProcessor(responseData) {
    if (!this.validateHookResponse(responseData)) {
      console.log(
        'Hook response has popupConfig which means, its an error and display the info to user and halt further processing, returning after showing popup'
      );
      return;
    }
    _.set(
      this._formInstance.submission.data,
      this.postToUrlConfig?.responseContextVariable!,
      responseData
    );

    if (this.postToUrlConfig?.afterPost?.apiResponseProcessor) {
      const scriptValue = LodashTemplateEvaluator.interpolate(
        this.postToUrlConfig?.afterPost.apiResponseProcessor,
        this._formInstance.submission
      );
      const responseData = FormRendererUtils.evaluate(
        scriptValue,
        this._formInstance.submission,
        'response',
        true
      );
      this.setDataOnSmartTable(responseData);
    }
  }

  /**
   * check for response in payload do the necessary Action and return
   * true  - if all fine to proceed
   * false  - to halt the process
   * the callee is responsible to act accordingly based on return type
   *
   * @param hookResponsePayload
   * @private
   */
  private validateHookResponse(
    hookResponsePayload: string | ValidationResponse
  ): boolean {
    if (hookResponsePayload?.hasOwnProperty('showPopup')) {
      //hookResponsePayload.hasOwnProperty('validationMessage')

      console.log('computed hook response : ', hookResponsePayload);
      const hookResponseWithDefaults =
        ValidationResponse.mergeWithDefaults(hookResponsePayload);

      console.log('computed hook response : ', hookResponseWithDefaults);

      if (hookResponseWithDefaults.proceed) {
        // TODO for proceed true, we cannot show popups, as popups cannot block the code execution, instead use toastr and move on with code executions
      }
      //else { // halting the process

      const popupConfig = new PopupConfig();
      popupConfig.message = hookResponseWithDefaults.popupMessage;
      popupConfig.headerMessage = hookResponseWithDefaults.popupHeader;
      if (hookResponseWithDefaults.displayType == 'toastr') {
        if (hookResponseWithDefaults.showPopup == 'warning') {
          this.toastrService.warning(
            popupConfig.headerMessage,
            popupConfig.message
          );
        } else if (hookResponseWithDefaults.showPopup == 'error') {
          this.toastrService.error(
            popupConfig.headerMessage,
            popupConfig.message
          );
        } else if (hookResponseWithDefaults.showPopup == 'info') {
          this.toastrService.info(
            popupConfig.headerMessage,
            popupConfig.message
          );
        }
      } else {
        this.formRenderUtils.showPopup(
          popupConfig,
          this._formInstance.submission.data
        );
      }
      return hookResponseWithDefaults.proceed;

      //  }
    }

    return true;
  }

  dropDownButtonClicked(event: any, navigateUrl?: any) {
    console.log('dropDownButton Event ', event);
    console.log('dropDownButton URL', navigateUrl);

    const scriptValue = LodashTemplateEvaluator.interpolate(
      navigateUrl,
      this._formInstance.submission
    );
    const evaluated = FormRendererUtils.evaluate(
      scriptValue,
      this._formInstance.submission,
      'value',
      true
    );
    if (evaluated) {
      _.merge(this._formInstance.submission.data, evaluated);
      _.merge(this._formData, evaluated);
    }

    this.onClick(event, navigateUrl);
  }

  private resetSmartTableResults() {
    if (
      this.smartTableResetSearchResultsConfig &&
      this.smartTableResetSearchResultsConfig.tablesToReset &&
      this.smartTableResetSearchResultsConfig.tablesToReset.length
    ) {
      for (const smartTableName of this.smartTableResetSearchResultsConfig!
        .tablesToReset) {
        FormioUtils.findComponent(
          this._formInstance.components,
          smartTableName,
          '',
          (component) => {
            component.setValue({
              advanceSearchQuery: ''
            });
          }
        );
      }
    }

    if (
      this.smartTableResetSearchResultsConfig &&
      this.smartTableResetSearchResultsConfig.otherFieldsToReset &&
      this.smartTableResetSearchResultsConfig.otherFieldsToReset.length
    ) {
      for (const fieldToReset of this.smartTableResetSearchResultsConfig!
        .otherFieldsToReset) {
        FormioUtils.findComponent(
          this._formInstance.components,
          fieldToReset,
          '',
          (component) => {
            component.setValue('');
          }
        );
      }
    }
  }

  ngOnInit() {
    console.log('Instance: ' + new Date().toString());
  }

  private resolveInterpolation() {
    this.formRenderUtils.resolveDynamicHtmlContent(this._formInstance.components, {'data': this._formData});
  }
}

/**
 * try to use this response as is and do not add any additional properties that are not related to validation handling.
 * for your use case create a new return type and write a  if condition in validateHookResponse method
 */
export class ValidationResponse {
  showPopup: true | 'info' | 'warning' | 'error' = 'error';
  displayType: 'popup' | 'toastr' = 'popup';
  popupHeader = '';
  popupMessage = '';
  proceed = false;
  value: any;

  static mergeWithDefaults(jsonData): ValidationResponse {
    const defaultValues = new ValidationResponse(); // Create an instance to get default values
    const mergedData = {...defaultValues, ...jsonData};
    return mergedData;
  }
}
