import { Injectable } from '@angular/core';
import { BaseRestService } from '../../shared/services/base-rest.service';
import { Form } from './models/form';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Submission } from './models/submission';

@Injectable({
  providedIn: 'root'
})
export class SubmissionService extends BaseRestService<Submission> {
  constructor(private httpClient: HttpClient) {
    super(httpClient, `${environment.apiBaseUrl}`);
  }

  saveSubmission(url: string, form: Submission) {
    return this.createEntityWithUrl(url, form);
  }

  createEntityWithUrl(url: string, entityData: Submission) {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http
      .post<Submission>(this.restUrl + url, entityData, { headers: headers })
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  override getById(id: any): Observable<Form> {
    return super.getById(id).pipe(map((data) => decodeData(data)));
  }

  getAllForms(): Observable<Form[]> {
    return this.http
      .get<Form[]>(this.restUrl + '/', {})
      .pipe(map((res) => res));
  }
}

export function decodeData(data: Form) {
  // console.log("Retrieved data: " + JSON.stringify(data));
  if (data?.components) {
    //console.log("Encoded Data => " + data?.components[0]);
    let decodedData = atob(<string>data?.components[0]);
    data.components[0] = decodedData;
    //console.log("Decoded Data => " + data?.components[0]);
  }
  return data;
}
