import { Injectable } from '@angular/core';
import { BaseRestService } from '../../shared/services/base-rest.service';
import { Form } from './models/form';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { TemplateEntity } from '../../shared/model/template-entity';

@Injectable({
  providedIn: 'root'
})
export class FormService extends BaseRestService<Form> {
  entities: TemplateEntity[] = [];

  constructor(private httpClient: HttpClient) {
    super(httpClient, `${environment.apiBaseUrl}/ignite/application`);
  }

  saveForm(form: Form) {
    return this.createEntity(form);
  }

  override getById(id: any): Observable<Form> {
    return super.getById(id).pipe(map((data) => decodeData(data)));
  }

  getAllForms(): Observable<Form[]> {
    return this.http
      .get<Form[]>(this.restUrl + '/', {})
      .pipe(map((res) => res));
  }

  getByCategory(category: string): Observable<Form[]> {
    return this.http
      .get<Form[]>(this.restUrl + '?category=' + category, {})
      .pipe(map((res) => res));
  }

  toggleLock(id: any, shouldLock: boolean): Observable<string> {
    return this.http.post(
      `${this.restUrl}/toggleLock/${id}/${shouldLock}`,
      null,
      { responseType: 'text' }
    );
  }
}

export function decodeData(data: Form) {
  // console.log("Retrieved data: " + JSON.stringify(data));
  if (data?.components) {
    //console.log("Encoded Data => " + data?.components[0]);
    let decodedData = atob(<string>data?.components[0]);
    data.components[0] = decodedData;
    //console.log("Decoded Data => " + data?.components[0]);
  }
  return data;
}
