import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StorageService {


  public addFormToLocalStorage(formName: string, formJson: Object) {
    const fromLocalStorage = this.getFormObjectFromLocalStorage();
    fromLocalStorage[formName] = formJson;
    this.addEntryToLocalStorage('forms', JSON.stringify(fromLocalStorage));
  }

  public getFormFromLocalStorage(key: string): any {
    const formJson = this.getFormObjectFromLocalStorage();
    const formJsonStr = formJson[key];
    return formJsonStr ? JSON.parse(formJsonStr) : {};
  }

  public getAllFormsFromLocalStorage() {
    return this.getFormObjectFromLocalStorage();
  }

  public addEntryToLocalStorage(key: string, value: string) {
    window.localStorage.setItem(key, value);
  }

  public removeFromLocalStorage(key: string): any {
    const value = this.getEntryFromLocalStorage(key);
    window.localStorage.removeItem(key);
    return value;
  }

  public getEntryFromLocalStorage(key: string): string | null {
    return window.localStorage.getItem(key);
  }

  private getFormObjectFromLocalStorage(): any {
    const locallyStoredFormsJsonStr = this.getEntryFromLocalStorage('forms');
    let locallyStoredFormJson = {};
    if (locallyStoredFormsJsonStr) {
      locallyStoredFormJson = JSON.parse(locallyStoredFormsJsonStr);
    }
    return locallyStoredFormJson;
  }
}
