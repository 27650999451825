import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DocumentManagementService {
  constructor(private http: HttpClient) {}

  createDocument(document: FormData) {
    const options = {
      reportProgress: true
    };
    return this.http.post<any>(
      `${environment.coreBaseApi}/documents/upload`,
      document,
      options
    );
  }

  getDownloadFile(
    downloadurl: string,
    id: string,
    options?: any
  ): Observable<any> {
    return this.http
      .get<any>(`${downloadurl}/` + id, options)
      .pipe(map((res) => res));
  }
}
