import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ApplicationFormsRoutingModule } from './application-forms-routing.module';
import { SharedModule } from '../../shared/shared.module';
import { AllFormsRendererComponent } from './all-forms-renderer/all-forms-renderer.component';

@NgModule({
    declarations: [AllFormsRendererComponent],
    exports: [
        AllFormsRendererComponent
    ],
    imports: [CommonModule, SharedModule, ApplicationFormsRoutingModule]
})
export class ApplicationFormsModule {}
