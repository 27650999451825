<div
  class="collapsible-side-bar"
  (click)="toggleSidebar()"
  pTooltip="Collapse the Menu"
  positionStyle="top"
  appendTo="self"
>
  <i class="open fa-solid fa-circle-chevron-up fa-rotate-270"></i>
  <i class="collapsed fa-solid fa-circle-chevron-up fa-rotate-90"></i>
</div>

<p-panelMenu [model]="sidebarMenuItems" [multiple]="true" class="panelMenu">
</p-panelMenu>

<!--<div class=" vertical-inline-menu d-flex flex-column width-50">
  <div *ngFor="let l1MenuItem of sidebarMenuItems" class="collapsed-menu-item" >
    <i class="{{l1MenuItem.icon}}" pTooltip="l1MenuItem.label"></i>
  </div>
</div>-->
