import { CustomButtonActionType } from './custom-button-action-handler-registry';
import { ActionProcessor } from '@shared/components/custom-action-button/custom-action-button-model';
import { EventEmitter } from '@angular/core';
import { FormioEvent } from '@evi-ui/angular/elements.common';

export interface CustomButtonActionHandler {
  performAction(context: CustomButtonActionHandlerContext): Promise<any>;
  isApplicable(actionType: CustomButtonActionType): boolean;
}

export class CustomButtonActionHandlerContext {
  actionProcessor: ActionProcessor;
  formInstance: any;
  formData: any;
  buttonName: string;
  formioEvent: EventEmitter<FormioEvent>;
}
