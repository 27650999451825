import { Injector } from '@angular/core';
import {
  FormioCustomComponentInfo,
  registerCustomFormioComponent
} from '@evi-ui/angular';
import { ScreenConfigurationComponent } from './screen-configuration.component';
import { ActionType } from '@shared/components/screen-configuration/screen-configuration.model';
import { CustomButtonActionType } from '@shared/components/custom-action-button/handlers/custom-button-action-handler-registry';

const COMPONENT_OPTIONS: FormioCustomComponentInfo = {
  type: 'screen-configuration',
  selector: 'screen-configuration',
  title: 'Screen Configuration',
  group: 'basic',
  icon: 'fa-regular fa-wrench',
  emptyValue: {},
  editForm: editForm
};

function editForm() {
  return {
    components: [
      {
        label: 'Tabs',
        components: [
          componentEditDisplay(),
          dataTab(),
          screenInitializationTab(),
          actionHandlers()
        ],
        key: 'tabs',
        type: 'tabs',
        input: false,
        tableView: false
      }
    ]
  };
}

function componentEditDisplay() {
  return {
    label: 'Display',
    key: 'baseDisplay',
    components: [
      {
        weight: 0,
        type: 'textfield',
        input: true,
        key: 'label',
        label: 'Label',
        placeholder: 'Field Label',
        tooltip: 'The label for this field that will appear next to it.',
        autofocus: true,
        hideLabel: true
      },
      {
        type: 'select',
        input: true,
        key: 'screenType',
        label: 'Screen Type',
        tooltip: 'Type of the screen',
        weight: 20,
        validate: {
          required: true
        },
        dataSrc: 'values',
        data: {
          values: [
            { label: 'Create Screen', value: 'create' },
            { label: 'Data Display Screen', value: 'view' },
            { label: 'Action Screen', value: 'action' }
          ]
        }
      }
    ]
  };
}

function dataTab() {
  return {
    key: 'dataApi',
    label: 'API',
    components: [
      {
        key: 'key',
        weight: 0,
        type: 'textfield',
        input: true,
        label: 'Property Name',
        tooltip: 'The name of this field in the API endpoint.',
        validate: {
          pattern: '(\\w|\\w[\\w-.]*\\w)',
          patternMessage:
            'The property name must only contain alphanumeric characters, underscores, dots and dashes and should not be ended by dash or dot.',
          required: true
        }
      },
      {
        key: 'submissionUrl',
        weight: 200,
        type: 'textfield',
        input: true,
        label: 'Submission URL',
        placeholder: 'URL of the Submission API.',
        tooltip: 'URL of the Submission API.',
        conditional: {
          json: {
            or: [
              { '===': [{ var: 'data.screenType' }, 'create'] },
              { '===': [{ var: 'data.screenType' }, 'action'] }
            ]
          }
        }
      },
      {
        key: 'redirectUrlAfterSubmission',
        weight: 200,
        type: 'textfield',
        input: true,
        label: 'Redirect URL after Submission',
        placeholder: 'URL to redirect after successful submission.',
        tooltip: 'URL of the Submission API.',
        conditional: {
          json: {
            or: [
              { '===': [{ var: 'data.screenType' }, 'create'] },
              { '===': [{ var: 'data.screenType' }, 'action'] }
            ]
          }
        }
      },
      {
        key: 'navigateViaBrowser',
        weight: 200,
        type: 'checkbox',
        input: true,
        label: 'Navigate via Browser URL?',
        tooltip: 'Causes a page refresh with URL in browser',
        conditional: {
          json: {
            or: [
              { '===': [{ var: 'data.screenType' }, 'create'] },
              { '===': [{ var: 'data.screenType' }, 'action'] }
            ]
          }
        }
      },
      {
        key: 'retrievalUrl',
        weight: 200,
        type: 'textfield',
        input: true,
        label: 'Data Retrieval URL',
        placeholder: 'URL of the data retrieval API.',
        tooltip: 'URL to retrieve the data for rendering in this screen.',
        conditional: {
          json: {
            or: [
              { '===': [{ var: 'data.screenType' }, 'view'] },
              { '===': [{ var: 'data.screenType' }, 'action'] }
            ]
          }
        }
      },
      {
        key: 'additionalProperties',
        type: 'datagrid',
        label: 'Additional Data URLs',
        input: true,
        tooltip: '',
        weight: 702,
        addAnother: 'Add Another Property',
        tableView: true,
        reorder: true,
        inlineEdit: true,
        displayAsTable: true,
        components: [
          {
            key: 'propertyName',
            type: 'textfield',
            label: 'Variable Name',
            input: true,
            tooltip: 'Property Name',
            weight: 702
          },
          {
            key: 'propertyValue',
            type: 'textfield',
            label: 'URL To Trigger',
            input: true,
            tooltip: 'Value of the Property',
            weight: 702
          },
          {
            key: 'dataPath',
            type: 'textfield',
            label: 'Data Path',
            input: true,
            tooltip: 'Data Path to extract values',
            weight: 702
          },
          {
            key: 'apiMethod',
            type: 'select',
            input: true,
            label: 'API Method',
            placeholder: 'API Method',
            dataSrc: 'values',
            data: {
              values: [
                { label: 'GET', value: 'GET' },
                { label: 'POST', value: 'POST' },
                { label: 'PUT', value: 'PUT' }
              ]
            },
            defaultValue: 'GET'
          },
          {
            weight: 200,
            type: 'textarea',
            input: true,
            key: 'apiRequest',
            label: 'API Request Body to use',
            rows: 5,
            editor: 'ace',
            as: 'javascript',
            placeholder: '',
            tooltip: '',
            customConditional(context) {
              return (
                context.row.apiMethod === 'POST' ||
                context.row.apiMethod === 'PUT'
              );
            }
          }
        ],
        conditional: {
          json: {
            or: [
              { '===': [{ var: 'data.screenType' }, 'create'] },
              { '===': [{ var: 'data.screenType' }, 'view'] },
              { '===': [{ var: 'data.screenType' }, 'action'] }
            ]
          }
        }
      },
      {
        key: 'popupConfig',
        type: 'fieldset',
        placeholder: 'Popup Config',
        legend: 'Success Message Popup Config',
        weight: 300,
        conditional: {
          json: {
            or: [
              { '===': [{ var: 'data.screenType' }, 'create'] },
              { '===': [{ var: 'data.screenType' }, 'action'] }
            ]
          }
        },
        components: [
          {
            weight: 200,
            type: 'textfield',
            input: true,
            key: 'headerMessage',
            label: 'Header Message',
            placeholder: 'Text to be shown in popup header.',
            tooltip: 'Text to be shown in popup header.',
            conditional: {
              json: {
                or: [
                  { '===': [{ var: 'data.screenType' }, 'create'] },
                  { '===': [{ var: 'data.screenType' }, 'action'] }
                ]
              }
            }
          },
          {
            weight: 200,
            type: 'textfield',
            input: true,
            key: 'popupScreenId',
            label: 'Popup Screen Id',
            placeholder: 'ID of the Screen to be used for popup.',
            tooltip: 'ID of the Screen to be used for popup.',
            conditional: {
              json: {
                or: [
                  { '===': [{ var: 'data.screenType' }, 'create'] },
                  { '===': [{ var: 'data.screenType' }, 'action'] }
                ]
              }
            }
          },
          {
            weight: 200,
            type: 'textfield',
            input: true,
            key: 'message',
            label: 'Message',
            placeholder: 'Message to be displayed in popup.',
            tooltip: 'Message to be displayed in popup.',
            conditional: {
              json: {
                or: [
                  { '===': [{ var: 'data.screenType' }, 'create'] },
                  { '===': [{ var: 'data.screenType' }, 'action'] }
                ]
              }
            }
          }
        ]
      },
      {
        key: 'errorPopupConfig',
        type: 'fieldset',
        placeholder: 'Error Popup Config',
        legend: 'Error Message Popup Config',
        weight: 301,
        conditional: {
          json: {
            or: [
              { '===': [{ var: 'data.screenType' }, 'create'] },
              { '===': [{ var: 'data.screenType' }, 'action'] }
            ]
          }
        },
        components: [
          {
            key: 'errorHeaderMessage',
            weight: 200,
            type: 'textfield',
            input: true,
            label: 'Header Message',
            placeholder: 'Text to be shown in popup header.',
            tooltip: 'Text to be shown in popup header.',
            conditional: {
              json: {
                or: [
                  { '===': [{ var: 'data.screenType' }, 'create'] },
                  { '===': [{ var: 'data.screenType' }, 'action'] }
                ]
              }
            }
          },
          {
            key: 'errorPopupScreenId',
            weight: 200,
            type: 'textfield',
            input: true,
            label: 'Popup Screen Id',
            placeholder: 'ID of the Screen to be used for popup.',
            tooltip: 'ID of the Screen to be used for popup.',
            conditional: {
              json: {
                or: [
                  { '===': [{ var: 'data.screenType' }, 'create'] },
                  { '===': [{ var: 'data.screenType' }, 'action'] }
                ]
              }
            }
          },
          {
            key: 'errorMessage',
            weight: 200,
            type: 'textfield',
            input: true,
            label: 'Message',
            placeholder: 'Message to be displayed in popup.',
            tooltip: 'Message to be displayed in popup.',
            conditional: {
              json: {
                or: [
                  { '===': [{ var: 'data.screenType' }, 'create'] },
                  { '===': [{ var: 'data.screenType' }, 'action'] }
                ]
              }
            }
          }
        ]
      },
      {
        key: 'documentConfig',
        type: 'fieldset',
        placeholder: 'Document Config',
        legend: 'Document Id Transformation',
        weight: 300,
        components: [
          {
            weight: 200,
            type: 'textfield',
            input: true,
            key: 'sourceKey',
            label: 'Source Key',
            placeholder: 'Source Key',
            tooltip: ''
          },
          {
            weight: 200,
            type: 'textfield',
            input: true,
            key: 'targetKey',
            label: 'Target Key',
            placeholder: 'Target Key',
            tooltip: ''
          }
        ]
      }
    ]
  };
}

function screenInitializationTab() {
  return {
    key: 'screenInitialization',
    label: 'Screen Initialization',
    components: [
      {
        key: 'screenInitializationConfig',
        type: 'editgrid',
        label: 'Screen Initialization API URLs',
        input: true,
        tooltip: '',
        weight: 100,
        addAnother: 'Add Another',
        tableView: false,
        reorder: true,
        saveRow: 'Save',
        description:
          'Note: Screen Initialization will be executed after the screen is rendered',
        components: [
          {
            key: 'storageAttribute',
            type: 'textfield',
            label: 'Storage Property Name',
            input: true,
            description:
              'Name of the property/variable in which the result of the API call should be stored. Use * if the response should be stored in the root element `data`',
            weight: 101
          },
          {
            key: 'apiUrl',
            type: 'textfield',
            label: 'URL To Trigger',
            input: true,
            tooltip: 'URL of the API',
            weight: 102
          },
          {
            key: 'apiMethod',
            type: 'select',
            input: true,
            label: 'API Method',
            placeholder: 'API Method',
            dataSrc: 'values',
            data: {
              values: [
                { label: 'GET', value: 'GET' },
                { label: 'POST', value: 'POST' },
                { label: 'PUT', value: 'PUT' }
              ]
            }
          },
          {
            key: 'dataPath',
            type: 'textfield',
            label: 'Data Path',
            input: true,
            description:
              'Path of the attribute in result JSON that needs to be extracted.',
            tooltip: 'Data Path to extract values',
            weight: 103
          },
          {
            type: 'textarea',
            input: true,
            key: 'responseProcessor',
            label: 'Response Processor',
            rows: 5,
            editor: 'ace',
            as: 'javascript',
            placeholder: '',
            weight: 104,
            tooltip: '',
            description:
              'Logic to process the response. Return `response` variable which will be assigned to the `storageProperty`'
          },
          {
            type: 'textarea',
            input: true,
            key: 'requestBody',
            label: 'API Request Body',
            rows: 5,
            editor: 'ace',
            as: 'javascript',
            weight: 105,
            placeholder: '',
            tooltip: '',
            description:
              'Request body to be sent in the API call. Applicable only for POST and PUT API Methods. Return `requestBody` as the attribute.'
          }
        ]
      },
      {
        key: 'refreshAllComponentsModel',
        type: 'checkbox',
        input: true,
        weight: 200,
        label:
          'Refresh All Components After Data Loading (NOT YET IMPLEMENTED)',
        description: 'Refresh All Components After Data Loading'
      }
    ]
  };
}

function actionHandlers() {
  return {
    key: 'actionHandlers',
    label: 'Action Handlers',
    components: [
      {
        key: 'actionListener',
        type: 'editgrid',
        label: 'Action Listeners',
        input: true,
        tooltip: '',
        weight: 100,
        addAnother: 'Add Another Listener',
        tableView: false,
        reorder: true,
        saveRow: 'Save',
        description: '',
        components: [
          {
            key: 'widgetToListen',
            type: 'select',
            label: 'Input Field to Listen',
            input: true,
            multiple: true,
            description:
              'Name of the input field on which the listener needs to be attached',
            weight: 101,
            dataSrc: 'custom',
            valueProperty: 'value',
            data: {
              custom(context) {
                let values = [];
                context.utils.eachComponent(
                  context.instance.options.editForm.components,
                  function (component, path) {
                    if (
                      component &&
                      component.key &&
                      ((component.label &&
                        component.key !== context.data.key &&
                        component.type == 'textfield' &&
                        path) ||
                        component.type == 'smarttable')
                    ) {
                      // @ts-ignore
                      values.push({
                        label: component.label + ' (' + component.key + ')',
                        value: path
                      });
                    }
                  }
                );
                return values;
              }
            }
          },
          {
            key: 'eventName',
            type: 'textfield',
            label: 'Event Name',
            input: true,
            tooltip: 'Name of the Event to listen.',
            description:
              'If multiple fields are selected, and each of them needs to listen on a dynamic event name, then use interpolation syntax.\n' +
              '`key` can be used as a special variable to refer to the key of the field.\n' +
              'For example, if key of the field is `xyz`, and the event name is provided as `keyPressed-{{key}}`, then the field will listen for `keyPressed-xyz` event.',
            weight: 102
          },
          {
            key: 'actionChain',
            type: 'editgrid',
            label: 'Action Chain',
            input: true,
            tooltip: '',
            weight: 100,
            addAnother: 'Add Next Action',
            tableView: false,
            reorder: true,
            saveRow: 'Save',
            description: '',
            components: [
              {
                key: 'actionType',
                type: 'select',
                label: 'Action Type',
                input: true,
                description:
                  'Type of action to perform. Actions will be executed in the order they are defined here.',
                weight: 101,
                data: {
                  values: [
                    { label: 'Call API', value: ActionType.CALL_API },
                    {
                      label: 'Execute Script',
                      value: ActionType.EXECUTE_SCRIPT
                    },
                    { label: 'Show Popup', value: ActionType.SHOW_POPUP },
                    {
                      label: 'Set Field Values',
                      value: ActionType.SET_FIELD_VALUES
                    },
                    {
                      label: 'Refresh Smart Table',
                      value: ActionType.REFRESH_SMART_TABLE
                    }
                  ]
                }
              },
              {
                key: 'apiConfig.url',
                weight: 200,
                type: 'textfield',
                input: true,
                label: 'URL To POST To',
                placeholder: 'URL To POST To',
                validate: {
                  required: true
                },
                customConditional(context) {
                  return context.row.actionType === 'callApi';
                }
              },
              {
                key: 'apiConfig.methodName',
                weight: 200,
                type: 'select',
                input: true,
                label: 'API Method',
                placeholder: 'API Method',
                validate: {
                  required: true
                },
                dataSrc: 'values',
                data: {
                  values: [
                    { label: 'GET', value: 'GET' },
                    { label: 'POST', value: 'POST' },
                    { label: 'PUT', value: 'PUT' }
                  ]
                },
                defaultValue: 'POST',
                customConditional(context) {
                  return context.row.actionType === ActionType.CALL_API;
                }
              },
              {
                key: 'apiConfig.requestBodyParameter',
                weight: 200,
                type: 'textfield',
                input: true,
                label: 'Request Body data parameter',
                placeholder: 'Request Body data parameter',
                customConditional(context) {
                  return context.row.actionType === ActionType.CALL_API;
                }
              },
              {
                key: 'apiConfig.skipValidation',
                weight: 200,
                type: 'checkbox',
                input: true,
                label: 'Skip Validation',
                customConditional(context) {
                  return context.row.actionType === ActionType.CALL_API;
                }
              },
              {
                key: 'apiConfig.responseContextVariable',
                weight: 200,
                type: 'textfield',
                input: true,
                label: 'Response Context Variable',
                placeholder: '',
                customConditional(context) {
                  return context.row.actionType === ActionType.CALL_API;
                }
              },
              {
                key: 'apiConfig.beforePost',
                weight: 200,
                type: 'textarea',
                input: true,
                editor: 'ace',
                as: 'javascript',
                wysiwyg: {
                  minLines: 12
                },
                defaultValue: undefined,
                label: 'Before POST',
                placeholder: 'Before POST',
                customConditional(context) {
                  return context.row.actionType === ActionType.CALL_API;
                }
              },

              {
                key: 'scriptExecutionConfig.scriptToExecute',
                weight: 200,
                type: 'textarea',
                input: true,
                editor: 'ace',
                as: 'javascript',
                wysiwyg: {
                  minLines: 25
                },
                defaultValue: undefined,
                label: 'Script to execute',
                customConditional(context) {
                  return context.row.actionType === ActionType.EXECUTE_SCRIPT;
                }
              },

              {
                key: 'popupConfig.popupScreenId',
                weight: 201,
                type: 'textfield',
                input: true,
                label: 'Popup Screen Id',
                placeholder: 'ID of the Screen to be used for popup.',
                tooltip: 'ID of the Screen to be used for popup.',
                customConditional(context) {
                  return context.row.actionType === ActionType.SHOW_POPUP;
                }
              },

              {
                key: 'smartTableConfig.smartTableId',
                weight: 201,
                type: 'select',
                input: true,
                label: 'Smart Table',
                tooltip: 'Smart Table to refresh',
                dataSrc: 'custom',
                valueProperty: 'value',
                data: {
                  custom(context) {
                    let values: any[] = [];
                    context.utils.eachComponent(
                      context.instance.options.editForm.components,
                      function (component, path) {
                        if (
                          component.key !== context.data.key &&
                          component.type == 'smarttable'
                        ) {
                          values.push({
                            label: component.label + ' (' + component.key + ')',
                            value: path
                          });
                        }
                      }
                    );
                    return values;
                  }
                },
                customConditional(context) {
                  return (
                    context.row.actionType === ActionType.REFRESH_SMART_TABLE
                  );
                }
              },

              {
                key: 'fieldValuesConfig',
                weight: 201,
                type: 'editgrid',
                label: 'Set Value On Fields',
                customConditional(context) {
                  return context.row.actionType === ActionType.SET_FIELD_VALUES;
                },
                components: [
                  {
                    key: 'fieldName',
                    weight: 201,
                    type: 'textfield',
                    label: 'Field Name'
                  },
                  {
                    key: 'fieldValue',
                    weight: 201,
                    type: 'textfield',
                    label: 'Field Value'
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  };
}

class Status {
  static status = new Map();
}

export function registerScreenConfigurationComponent(
  injector: Injector,
  screen: string
) {
  if (!Status.status.has(screen)) {
    try {
      registerCustomFormioComponent(
        COMPONENT_OPTIONS,
        ScreenConfigurationComponent,
        injector
      );
      Status.status.set(screen, true);
    } catch (e) {
      console.log(
        'Error while registering screenConfiguration component: ' + screen
      );
    }
  }
}
