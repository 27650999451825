import {
  CustomButtonActionHandler,
  CustomButtonActionHandlerContext
} from '@shared/components/custom-action-button/handlers/custom-button-action-handler';
import { CustomButtonActionType } from '@shared/components/custom-action-button/handlers/custom-button-action-handler-registry';
import { FormRendererUtils } from '../../../../portal/application-forms/all-forms-renderer/form-renderer-utils';
import {
  PopupAction,
  PopupConfig
} from '@shared/components/custom-action-button/custom-action-button-model';
import { Injectable } from '@angular/core';
import _ from 'lodash';

@Injectable({ providedIn: 'root' })
export class ShowPopupActionProcessor implements CustomButtonActionHandler {
  constructor(private formRenderUtils: FormRendererUtils) {}

  isApplicable(actionType: CustomButtonActionType): boolean {
    return actionType === CustomButtonActionType.showPopup;
  }

  async performAction(context: CustomButtonActionHandlerContext) {
    const actionProcessor = context.actionProcessor;
    const formInstance = _.cloneDeep(context.formInstance);
    const formData = _.cloneDeep(context.formData);

    const popupConfig = new PopupConfig();
    if (actionProcessor.popupAction.isConditionalPopup) {
      const conditionToEvaluate = actionProcessor.popupAction.conditionToEvaluate;
      const shouldShowPopup = FormRendererUtils.evaluate(
        conditionToEvaluate,
        {
          submission: context.formInstance.submission,
          formInstance: formInstance,
          formData: formData
        },
        'show',
        true
      );

      if (!shouldShowPopup) {
        return Promise.resolve();
      }
    }
    popupConfig.headerMessage = actionProcessor.popupAction.headerText;
    let messageToShow = this.formRenderUtils.needsInterpolation(actionProcessor.popupAction.message) ?
      this.formRenderUtils.interpolate(actionProcessor.popupAction.message, formInstance) : actionProcessor.popupAction.message;
    messageToShow = this.formRenderUtils.needsInterpolation(messageToShow)
      ? this.formRenderUtils.interpolate(messageToShow, formInstance) : messageToShow;
    popupConfig.message = messageToShow;
    popupConfig.popupScreenId = this.getPopupScreenId(
      actionProcessor.popupAction
    );

    if (actionProcessor.popupAction.inputContextVariable) {
      popupConfig['data'] = _.get(
        context.formData,
        actionProcessor.popupAction.inputContextVariable
      );
      popupConfig.parentInputData = _.get(
        context.formData,
        actionProcessor.popupAction.inputContextVariable
      );
      if (!popupConfig.parentInputData) {
        popupConfig.parentInputData = _.get(
          context.formInstance,
          actionProcessor.popupAction.inputContextVariable
        );
      }
      if (!popupConfig['data']) {
        popupConfig['data'] = _.get(
          context.formInstance,
          actionProcessor.popupAction.inputContextVariable
        );
      }
    } else {
      popupConfig.parentInputData = context.formInstance;
    }
    popupConfig.width = actionProcessor.popupAction.width;
    popupConfig.closeRefOnDestroy = true;

    const popupResponse = await this.formRenderUtils.showPopupWithPromise(
      popupConfig,
      context.formInstance.submission.data
    );
    return Promise.resolve(popupResponse);
  }

  private getPopupScreenId(popupAction: PopupAction): string {
    switch (popupAction.popupType) {
      case 'confirmation':
        return 'confirmation-popup';
      case 'information':
        return 'generic-pop-up';
      case 'reasoning':
        return 'generic-reasoning-popup';
      case 'custom':
        return popupAction.screenId;
    }
    return 'generic-pop-up';
  }
}
