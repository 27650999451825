// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiBaseUrl: '/api',
  assetBaseUrl: '/assets',
  coreBaseApi: '/api/core',
  iamBaseApi: '/api/iam',
  notificationBaseApi: '/api/notification',
  workflowBaseApi: '/api/workflow',
  enableWorkflow: true,
  workflowDesignerUrl: () => `${domainBasedConfiguration.get("workflowUrl")}/workflow-definitions`,
  formsBaseUrl: 'http://localhost:4200/api/ignite/application',
  baseApiUrl: 'http://localhost:4200/api',
  urlFileProviderBasePath: '/api/core',


  ssoClientId: 'a0642227-42e7-41b6-a6f9-e074c9c0e7d1',
  ssoLoginBaseUrl: 'https://login.microsoftonline.com',
  ssoMsAzureScope: 'https://graph.microsoft.com/User.Read',
  ssoRedirectUri: 'https://ifas-sit-portal.trimindtech.com/oauth-callback',
  ssoTenantId: 'e8da5086-7661-462c-8068-ff79e3fca6ca',


  enableSaml: true,

  samlLoginUrl: "/saml2/authenticate/azure-ad",

  setTokenInCookie: false,

  auditFieldsToSanitise : ['createdById','createdByName','createdDate','lastModifiedById','lastModifiedByName', 'lastModifiedDate', 'deleted','createdOn','createdBy', 'updatedOn', 'updatedBy'],

  reportServerUrl : "/report/run",
  enableBuilderMenu : () => `${domainBasedConfiguration.get("builderMode")}`,

  ignoreRedirectionPathNamesToPersist : ['/login', '/public/users/screen-lock'],
  idleInactiveTime: 30*60,
  idleTimeout: 1*60,

  ignoreAuthGuardUrls : ['/app/saml/inactive-user', '/public/users/screen-lock']
};

export const analytics = {
  dashboardSlug: 'ifas-dashboard',
  analyticsDomainUrl: () => domainBasedConfiguration.get("analyticsDomainUrl"),
  analyticsDashboardUrl: () =>  `${domainBasedConfiguration.get("analyticsDomainUrl")}/superset/dashboard/${analytics.dashboardSlug}?token=` ,
  analyticsSourceApp: 'IFAS_Portal',
  analyticsTargetApp: 'Analytics_Portal'
};


const domainBasedConfiguration = {

  get : (key) => {

    const config = {
      "localhost" : {
        analyticsDomainUrl : "https://ifas-sit-analytics.trimindtech.com",
        workflowUrl : "https://workflow.trimindtech.com",
        builderMode: true
      },
      "ifas-sit-portal.trimindtech.com" : {
        analyticsDomainUrl : "https://ifas-sit-analytics.trimindtech.com",
        workflowUrl : "https://workflow.trimindtech.com",
        builderMode: true
      },
      "ifas-sit-api.trimindtech.com" : {
        analyticsDomainUrl : "https://ifas-sit-analytics.trimindtech.com",
        workflowUrl : "https://workflow.trimindtech.com",
        builderMode: true
      },
      "master.d2ddsdh295uluw.amplifyapp.com" : {
        analyticsDomainUrl : "https://ifas-sit-analytics.trimindtech.com",
        workflowUrl : "https://workflow.trimindtech.com",
        builderMode: false
      },
      "uatifas-internal.judiciary.gov.sg" : {
        analyticsDomainUrl : "https://uatifasanalytics-internal.judiciary.gov.sg",
        workflowUrl : "https://uatifasworkflow-internal.judiciary.gov.sg",
        builderMode: false
      },
      "ifas-internal.judiciary.gov.sg" : {
        analyticsDomainUrl : "https://ifasanalytics-internal.judiciary.gov.sg",
        workflowUrl : "https://ifasworkflow-internal.judiciary.gov.sg",
        builderMode: false
      }
    };

    return config[window.location.hostname][key];
  }

}



