import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  ElementRef,
  EventEmitter,
  inject,
  Inject,
  Input,
  Output,
  Renderer2
} from '@angular/core';
import { FormioCustomComponent } from '@evi-ui/angular';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { FormioEvent } from '@evi-ui/angular/elements.common';
import { HttpClient } from '@angular/common/http';
import { EventType } from '../../model/event.data';
import { EventBusService } from '../../event-bus.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import * as _ from 'lodash';
import { LodashTemplateEvaluator } from '../smart-table/LodashTemplateEvaluator';
import { FormRendererUtils } from '../../../portal/application-forms/all-forms-renderer/form-renderer-utils';

@Component({
  selector: 'vector-checkbox',
  templateUrl: './vector-checkbox.component.html',
  styleUrls: ['./vector-checkbox.component.scss']
})
export class VectorCheckboxComponent
  implements FormioCustomComponent<string>, AfterViewInit
{
  destroyRef = inject(DestroyRef);
  @Input() propertyName: string;
  @Input() imageKey: string;
  @Input() apiKey: string = '';

  private _value: any = {};
  @Input()
  public set value(val: any) {
    if (!val) {
      return;
    }
    this._value = val;
  }

  public get value() {
    return this._value;
  }

  @Input() label: string;

  @Output() formioEvent?: EventEmitter<FormioEvent>;
  @Input() disabled: boolean = false;

  @Input() initialValueEvaluator?: string;
  private _form: any;
  private formioInstance: any;

  @Input() aclResource: string = '';
  @Output() valueChange = new EventEmitter<any>();

  imageToUse: any;

  static currentChecked: string[] = [];

  constructor(
    private router: Router,
    private eventBusService: EventBusService,
    private renderer2: Renderer2,
    @Inject(DOCUMENT) private document: Document,
    private http: HttpClient,
    private elRef: ElementRef,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    this.eventBusService
      .on(EventType.FORM_READY)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((formData) => {
        if (
          _.isUndefined(formData) ||
          formData == null ||
          _.isUndefined(this.propertyName)
        )
          return;
        this._form = formData;
        this.formioInstance = formData;
      });

    this.eventBusService
      .on(EventType.FORM_DATA_LOADED)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((formData) => {
        if (
          _.isUndefined(formData) ||
          formData == null ||
          _.isUndefined(this.propertyName)
        )
          return;
        this._form = formData;

        //Below two lines are very specific to Roles update. Should be redesigned/refactored to keep the component generic.
        /*  VectorCheckboxComponent.currentAcls = [];
        this._form.aclResourceNames?.map(acl => VectorCheckboxComponent.currentAcls.push(acl));*/

        if (
          this.initialValueEvaluator &&
          this.initialValueEvaluator.trim().length
        ) {
          let actionValue = LodashTemplateEvaluator.interpolate(
            this.initialValueEvaluator,
            this._form
          );
          let checkedValue = FormRendererUtils.evaluate(
            actionValue,
            this._form,
            'value'
          );
          if (checkedValue) {
            VectorCheckboxComponent.currentChecked.push(this.aclResource);
          }
        }
        this.changeDetectorRef.detectChanges();
      });
  }

  ngOnInit() {
    this.imageToUse = '/assets/vector-checkbox/' + this.imageKey;
    this.attachActionHandlers();
  }

  ngAfterViewInit() {}

  attachActionHandlers() {}

  ngOnDestroy() {}

  onCheckBoxChange(event: any) {
    this.toggleCheck(event.target.checked);
    if (event.target.checked) {
      VectorCheckboxComponent.currentChecked.push(this.aclResource);
    } else {
      VectorCheckboxComponent.currentChecked.filter((value, index, array) => {
        if (value === this.aclResource) {
          VectorCheckboxComponent.currentChecked.splice(index, 1);
          return true;
        } else {
          return false;
        }
      });
    }
  }

  toggleCheck(shouldCheck = false) {
    _.set(
      this.formioInstance?.submission?.data,
      this.apiKey,
      shouldCheck ? this.aclResource : ''
    );
  }

  get aclArray() {
    return VectorCheckboxComponent.currentChecked;
  }
}
