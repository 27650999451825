export enum EventType {
  EMPTY = 'EMPTY',
  FORM_READY = 'FORM_READY',
  FORM_DATA_LOADED = 'FORM_DATA_LOADED',
  API_TEXT_FIELD_DATA_LOADED = 'API_TEXT_FIELD_DATA_LOADED',
  LOGOUT = 'LOGOUT',
  GRAPE_EDITOR_LOADED = 'GRAPE_EDITOR_LOADED',
  GRAPE_DATA_LOADED = 'GRAPE_DATA_LOADED'
}
export class EventData {
  name: EventType;
  value: any;

  constructor(name: EventType, value: any) {
    this.name = name;
    this.value = value;
  }

  public static of(name: EventType, value: any) {
    return new EventData(name, value);
  }
}
