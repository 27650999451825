<header class="sticky-header">
  <!-- Content for the sticky header (80px height) -->
  <app-header></app-header>
</header>

<div class="main-container">
  <aside id="side-bar" class="sidebar px-2 pt-2">
    <!-- Content for the sidebar (20% width) -->
    <app-sidebar (menuItems)="setMenuItems($event)"></app-sidebar>
  </aside>

  <main id="main-bar" class="content ifas-formbuilder">
    <!-- Content for the main container -->
    <div class="main-content p-4 border-left-1 border-color-supp-1">
      <p-breadcrumb class="max-w-full pb-4" [model]="menuItems" [home]="home">
        <ng-template pTemplate="separator"> / </ng-template>
      </p-breadcrumb>
      <router-outlet></router-outlet>
    </div>
    <!-- <footer class="sticky-footer">
      &lt;!&ndash; Content for the sticky footer (80px height) &ndash;&gt;
      <app-footer></app-footer>
    </footer>-->
  </main>
</div>
