import {
  ChangeDetectorRef,
  Component,
  DestroyRef,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {FormioComponent, FormioCustomComponent} from '@evi-ui/angular';
import {
  CellActionContext,
  CellDataRendererContext,
  CONTEXT_DATA_DRIVEN,
  DisplayFormatter,
  GridColumnDefinition,
  GridOptions,
  mergeGridOptionsWithDefaults
} from './smart-table.model';
import {HttpClient, HttpParams} from '@angular/common/http';
import {PagedData} from '../../model/paged-data';
import {catchError, concatMap, finalize, map, tap} from 'rxjs/operators';
import * as _ from 'lodash';
import {attachDefaultDisplayFormatter} from './smart-table-cell-formatters';
import {Router} from '@angular/router';
import {DocumentManagementService} from '../../services/document-management.service';
import {attachActionHandler} from './smart-table-action-handlers';
import {LodashTemplateEvaluator} from './LodashTemplateEvaluator';
import {Observable, of} from 'rxjs';
import {EventBusService} from '../../event-bus.service';
import {EventType} from '../../model/event.data';
import {trimContent} from '../../utils/format-utils';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {AclUtils} from '../../../iam/iam_utils/acl-utils';
import {FormioEvent} from '@evi-ui/angular/elements.common';
import FileSaver from 'file-saver';
import {FileSelectEvent, FileUpload} from 'primeng/fileupload';
import {Document} from '../../model/Document';
import {Formio} from '@evi-ui/js';
import {FormService} from '../../../portal/forms/form.service';
import {EventEmitter as EventEmitter3} from 'eventemitter3';
import {FormRendererUtils} from '../../../portal/application-forms/all-forms-renderer/form-renderer-utils';
import {isNullOrUndefinedOrEmpty} from '@shared/utils/utils';
import {ObjectUtils} from "primeng/utils";
import {IamAuthUtils} from "@shared/utils/iam-auth-utils";

@Component({
  selector: 'smart-table',
  templateUrl: './smart-table.component.html',
  styleUrls: ['./smart-table.component.scss']
})
export class SmartTableComponent
  implements FormioCustomComponent<any>, OnDestroy, OnInit
{
  destroyRef = inject(DestroyRef);

  smartTableKey: string; // the key of the SmartTable Component in the Form

  @Output()
  valueChange = new EventEmitter<number>();

  eventEmitter = new EventEmitter3();

  @Input()
  disabled: boolean;

  loading: boolean = true;
  paginationPageSizeCurrent = 10;
  isAdvancedFilterVisible = false;

  formattedTitle: any;
  isTitleFormattingRequired = false;
  /*Grid Options defaults*/
  first: number = 0;
  totalRecords: number = 0;
  data: any;
  computedTemplateData: any;
  computedTemplateDataChildTable: any;
  private formioDataSubmission: any;

  private formioInstance: any;
  private formio: any;

  @ViewChild(FormioComponent) formioComponent: FormioComponent;

  globalSearchOperatorOptions = [
    {
      label: 'Contains',
      icon: 'pi pi-refresh',
      key: 'contains'
    },
    {
      label: 'Starts With',
      icon: 'pi pi-times',
      key: 'starts_with'
    }
  ];

  globalSearchFilterOperator: string = 'contains';
  globalSearchText: string;

  private currentPageLoadEvent: any;

  private advanceSearchQuery: string = '';

  private gridLoaded = false;
  private formDataLoaded = false;

  @Output()
  formioEvent = new EventEmitter<FormioEvent>();

  childRowsData = [];

  private _gridOptions!: GridOptions;
  private _qParamFilter!: string;
  private _expansionRowGridOptions!: GridOptions;
  private _value: any = {};

  constructor(
    private http: HttpClient,
    private router: Router,
    private ngZone: NgZone,
    private cdr: ChangeDetectorRef,
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private documentManagementService: DocumentManagementService,
    private formService: FormService,
    private eventBusService: EventBusService
  ) {
    console.log(
      `constructor :: in the Smarttable  Component => ${this.smartTableKey}  \n formioInstance => ${this.formioInstance} \n formioDataSubmission => ${this.formioDataSubmission} on => ${new Date()}`
    );

    this.eventBusService
      .on(EventType.FORM_READY)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((formioInstance) => {
        console.log(
          'Received FORM_READY event in  Smart Table ',
          formioInstance
        );
        if (formioInstance == null) {
          return;
        }
        this.attachFormioEventListeners(formioInstance);
        this.formioInstance = formioInstance;
        this.formioDataSubmission = formioInstance;
        this.isAdvancedFilterVisible = true;
        this.toggleAdvancedFilter();
        //  this.executeAfterGridAndFormDataLoaded();
        this.reloadData();
      });

    this.eventBusService
      .on(EventType.FORM_DATA_LOADED)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((formData) => {
        console.log(
          'Received FORM_DATA_LOADED event in  Smart Table ',
          formData
        );
        if (formData == null) {
          //because we are subscribing a behavior Subject with default value as null, first event emitted by default will be null.
          return;
        }
        this.formioDataSubmission = formData;
        this.formDataLoaded = true;
        // this.executeAfterGridAndFormDataLoaded();
        this.reloadData();
      });

    this.eventBusService
      .on(EventType.API_TEXT_FIELD_DATA_LOADED)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((formData) => {
        if (formData == null) {
          //because we are subscribing a behavior Subject with default value as null, first event emitted by default will be null.
          return;
        }
        if (
          !isNullOrUndefinedOrEmpty(
            this.gridOptions?.ignoreTextWithApiFieldEvents
          ) &&
          !this.gridOptions?.ignoreTextWithApiFieldEvents
        ) {
          // issue fix to data retaining in Court fee table after trying to create subsequent court fee
          this.formioDataSubmission = formData;
          if (this.gridOptions?.triggerDataFetchOnFormDataLoad) {
            this.reloadData();
          }
        }
      });
  }

  ngOnInit() {
    console.log(
      `ngOnInit in the Smarttable  Component => ${this.smartTableKey}  \n formioInstance => ${this.formioInstance} \n formioDataSubmission => ${this.formioDataSubmission} on => ${new Date()}`
    );
  }

  ngOnDestroy() {
    console.log(
      `ngOnDestroy : Smarttable Component => ${this.smartTableKey} on => ${new Date()}`
    );
    try {
      console.log(
        `Destroying the Smarttable => ${this.smartTableKey} , \n formioInstance => ${this.formioInstance} \n formioDataSubmission => ${this.formioDataSubmission}`
      );
      // this.formioInstance.destroy(true);
      console.log(
        `Destroyed the Smarttable successfully => ${this.smartTableKey} ,`
      );

      this.formioDataSubmission = undefined;
      this.formioInstance = undefined;
      this._value = undefined;
      this.data = undefined;
      this.loading = false;
      this.totalRecords = 0;
      this.first = 0;
    } catch (e) {
      console.error(`Cannot destroy  the Smarttable `, e);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    console.log(
      `Smart Table ngOnChanges invoked => ${this.smartTableKey} , \n Changes ${changes}  on => ${new Date()}`
    );
    // this.gridConfigChanged(); //handle logic in respective @Input param
  }

  ngAfterViewInit() {
    if (this.formioComponent) {
      this.formioComponent.formioReady.then((formio) => {
        console.log('formioReady Event in Smart Table');
        this.formio = formio;

        // this.attachFormioEventListeners(formio);
      });
    }
  }

  resetData() {}

  hasAccess(resourceName: string) {
    let hasAccess = true;
    if (resourceName) {
      hasAccess = AclUtils.hasAccess(resourceName);
    }
    return hasAccess;
  }

  public get gridOptions(): any {
    return this._gridOptions;
  }

  @Input()
  public set gridOptions(opts: any) {
    this.updateGridConfiguration(opts);
    if(!this._gridOptions.isListingScreen) { // fix for smart table reloading thrice on Listing screens
        this.triggerGridConfigLoaded(); //TODO: This should be done after the row-expansion config is loaded
    }
  }

  get qParamFilter(): string {
    return this._qParamFilter;
  }

  @Input()
  public set expansionRowGridOptions(expansionRowGridOptions: any) {
    this.updateChildGridConfiguration(expansionRowGridOptions);
    if(!this._gridOptions.isListingScreen) { // fix for smart table reloading thrice on Listing screens
      this.triggerGridConfigLoaded(); //TODO: This should be done after the row-expansion config is loaded
    }
  }

  public get expansionRowGridOptions(): GridOptions {
    return this._expansionRowGridOptions;
  }

  @Input()
  set qParamFilter(value: string) {
    this._qParamFilter = value;
  }

  public get value() {
    return this._value;
  }

  @Input()
  public set value(val: any) {
    console.log(this._gridOptions.title, 'Data: ', val);
    if (!val) {
      return;
    }
    if (val.hasOwnProperty('advanceSearchQuery')) {
      this.globalSearchText = '';
      this.advanceSearchQuery = val.advanceSearchQuery;
      this.currentPageLoadEvent = this.defaultPageLoadEvent();
      this.gridConfigChanged();
      return;
    }
    if (val.hasOwnProperty('action') && val.action == 'refresh') {
      console.log('before refresh', this.data);
      this.currentPageLoadEvent = this.defaultPageLoadEvent();
      this.gridConfigChanged();
      return;
    }
    if (val.hasOwnProperty('action') && val.action == 'clear') {
      console.log('before clearing data in ', this.smartTableKey, this.data);
      this.data = [];
      if (this._gridOptions.contextDataFieldName && this.formioDataSubmission) {
        _.set(
          this.formioDataSubmission,
          this._gridOptions.contextDataFieldName,
          []
        );
        if (this.formioDataSubmission.data) {
          _.set(
            this.formioDataSubmission.data,
            this._gridOptions.contextDataFieldName,
            []
          );
        }
      }
      this.currentPageLoadEvent = this.defaultPageLoadEvent();
      this.reloadData();
      return;
    }
    if (val.hasOwnProperty('data')) {
      this._value = val.data;
      this.data = val.data;
      this.loading = false;
      this.totalRecords = this.data?.length || 0;
      this.first = 0;
      //TODO: Assumption here is that the "data" is being passed on for local-context
      if (this._gridOptions.contextDataFieldName) {
        _.set(
          this.formioDataSubmission,
          this._gridOptions.contextDataFieldName,
          this.data
        );
        //  _.set(this.formioInstance.submission.data, this._gridOptions.contextDataFieldName, this.data);
      }
      this.reloadData();
      return;
    }

    //this._value = val;
  }

  gridConfigChanged() {
    this.reloadData();
  }

  private reloadData() {
    this.loadData(this.getPageLoadEvent());
  }

  localSort($event: any) {
   const field =  $event.sortField;
    const order =  $event.sortOrder;

    field && this.data && this.data.sort((data1, data2) => {
      const value1 = ObjectUtils.resolveFieldData(data1, field);
      const value2 = ObjectUtils.resolveFieldData(data2, field);
      let result = 0; //default value

      if (value1 == null && value2 != null) result = -1;
      else if (value1 != null && value2 == null) result = 1;
      else if (value1 == null && value2 == null) result = 0;
      else if (typeof value1 === 'string' && typeof value2 === 'string') result = value1.localeCompare(value2);
      else result = value1 < value2 ? -1 : value1 > value2 ? 1 : 0;

      return order * result;
    });
  }

  loadData($event: any): void {
    // for fields of $event object pls refer -> https://github.com/primefaces/primeng/blob/54cd69fd6d112a932269bf28951954b2bc7a9063/src/app/components/table/table.ts#L2165
    if (isNullOrUndefinedOrEmpty(this._gridOptions)) {
      // avoid triggering reload data if gridConfig is not loaded
      console.log('Not loading Grid Data as gridConfig is undefined');
      return;
    }
    this.disableCDR($event)
      .pipe(
        concatMap(($event) => this.loadDataSeriously($event)),
        //   concatMap(($event) => this.initializeChildRowsData($event)),
        concatMap((loadedData) =>
          this.compileTemplatesAndUpdateVariables(
            this.gridOptions,
            false,
            loadedData
          )
        ), // Pass the loaded data as an argument
        tap(() => this.enableCDR()),
        catchError((error) => {
          // Handle any errors that occur during the sequence
          console.error(error);
          return of(null); // Return an observable to continue the sequence even after an error
        }),
        finalize(() => {
          // Perform any final cleanup here
          //   console.log("finalizing the method chaining")
        })
      )
      .subscribe(() => {
        //  console.log("Updated the grid!! by chaining the pipes")
        // The sequence is complete
      });
    //  return this.loadDataInternal($event)
  }

  private disableCDR(data: any): Observable<any> {
    //   this.cdr.detach();
    return of(data);
  }

  private compileTemplatesAndUpdateVariables(
    gridOptions: GridOptions,
    isChildTable: boolean,
    data: any[]
  ): Observable<any> {
    // do any formatting based on data here
    if (!isChildTable && this.isTitleFormattingRequired) {
      //performance reasons - title will be hidden until data is loaded if we have to format it blindly only after data loading,
      // which is not looking good in UI. Hence a flag is introduced to check if title required any formatting,
      // if so then we do the formatting after the data is loaded
      let sourceToInterpolate = this.getFormSubmissionContextForInterpolation();
      this.formattedTitle = LodashTemplateEvaluator.interpolate(
        this.gridOptions?.title,
        sourceToInterpolate
      );
    }

    // Use the passed data as an argument
    // Your logic to compile templates and update variables
    data?.forEach((rowData, rowIndex, array) => {
      rowData.defaultRendering = [];
      rowData.advancedRendering = [];
      rowData.dynamicDataRowClass = this.evaluateDynamicValue('dynamicDataRowClass', rowData,
        rowIndex,
        gridOptions);

      rowData.dynamicRowPrefix = this.evaluateDynamicValue('dynamicRowPrefix', rowData,
        rowIndex,
        gridOptions);


      gridOptions.columnDefinitions.forEach((columDef, colIndex) => {
        rowData.defaultRendering[colIndex] = this.defaultRendering(
          rowData,
          rowIndex,
          colIndex,
          columDef,
          gridOptions
        );
        rowData.advancedRendering[colIndex] = this.advancedRendering(
          rowData,
          rowIndex,
          colIndex,
          columDef,
          gridOptions
        );
      });

      if (gridOptions.needsRowExpansion && this._expansionRowGridOptions) {
        const rowDatum =
          rowData[<any>this._expansionRowGridOptions.childRowsAttribute];
        if (rowDatum) {
          // rowDatum.parentIndex = rowIndex;
          this.compileTemplatesAndUpdateVariables(
            <any>this._expansionRowGridOptions,
            true,
            rowDatum
          );
        }
      }
    });

    return of(data);
  }

  private enableCDR(): Observable<any> {
    //  this.cdr.reattach();
    // this.cdr.detectChanges();
    return of(null);
  }

  private updateGridConfiguration(gridOptions: any) {
    if (!gridOptions) return;

    this.smartTableKey = gridOptions.key!;

    this._gridOptions = mergeGridOptionsWithDefaults(gridOptions);

    this._gridOptions.columnDefinitions.map((columnDefinition) =>
      attachDefaultDisplayFormatter(columnDefinition)
    );

    if (gridOptions?.title?.indexOf('{{') > -1) {
      // not formatting string present
      this.isTitleFormattingRequired = true;
    }
  }

  private updateChildGridConfiguration(childGridOptions: any) {
    if (!childGridOptions) return;
    this._expansionRowGridOptions = mergeGridOptionsWithDefaults(
      childGridOptions,
      true
    );
    this._expansionRowGridOptions.columnDefinitions.map((columnDefinition) =>
      attachDefaultDisplayFormatter(columnDefinition)
    );
  }

  private triggerGridConfigLoaded() {
    this.gridLoaded = true;
    //this.executeAfterGridAndFormDataLoaded();
    this.reloadData();
  }

  /**
   * method used as hook that executes only after below events are loaded
   * Events - 1. GridConfiguration Updated
   * Events - 2. FormData Loaded
   * @private
   */
  private executeAfterGridAndFormDataLoaded() {
    if (this.gridLoaded && this.formDataLoaded) {
      if (this.gridOptions?.gridDataContext == CONTEXT_DATA_DRIVEN) {
        if (this.data != undefined) {
          console.log(
            this._gridOptions.title,
            'executeAfterGridAndFormDataLoaded: Loading data locally',
            this.data
          );
          this.loadDataLocally({});
        }
      } else if (this._gridOptions?.apiUrl) {
        this.loadDataRemotely({});
      }
    }
  }

  onSelectionChange(selectedData: any[]) {
    let data = selectedData; // use complete row data to store the selection results

    if (!this.gridOptions?.useCompleteRecordDataAsSelection) {
      // pluck the results from the  given field
      _.set(
        this.formioInstance?.submission?.data,
        this.gridOptions?.selectedRecordStorageField + 'Full',
        data
      );
      data = _.map(
        selectedData,
        this.gridOptions?.selectedRecordIdentifierField
      );
    }

    _.set(
      this.formioInstance?.submission?.data,
      this.gridOptions?.selectedRecordStorageField,
      data
    );
  }

  changeFilter(operator: string) {
    this.globalSearchFilterOperator = operator;
  }

  filterGlobal(searchText: string, operator: string = 'contains') {
    // this.globalSearchFilterOperator = operator;
    this.reloadData();
  }

  /**
   * adjust the gridlayout class  by toggling the advanced filter of the table
   */
  toggleAdvancedFilter() {
    const smartTableWrapperClassName = 'ifas-smart-table-and-filter-wrapper';
    let smartTableWrapper = this.elementRef.nativeElement.closest(
      `.${smartTableWrapperClassName}`
    );
    if (!smartTableWrapper) {
      console.warn(
        `Wrapper not found with class ${smartTableWrapperClassName}`
      );
      return;
    }

    let wrapperId = smartTableWrapper.id;

    //  const advancedFilterDiv = smartTableWrapper.children[1]; // the 1st element is col-md-10 and 2nd element is col-md-2

    this.isAdvancedFilterVisible = !this.isAdvancedFilterVisible;

    let tableClass = 'col-md-12';
    let filterClass = 'col-md-0  smart-table-adv-filter-d-none';

    if (this.isAdvancedFilterVisible) {
      tableClass = 'col-md-10';
      filterClass = 'col-md-2   smart-table-adv-filter-d-block';
    }
    this.renderer.setAttribute(
      smartTableWrapper.children[0],
      'class',
      tableClass
    );
    this.renderer.setAttribute(
      smartTableWrapper.children[1],
      'class',
      filterClass
    );
  }

  handleGridAction(
    rowData: any,
    actionableHTMLFragments: ActionableHTMLFragment
  ) {
    attachActionHandler(actionableHTMLFragments.displayFormatter);

    let cellActionContext = {
      rowData: rowData,
      router: this.router,
      downloadService: this.documentManagementService,
      gridOptions: this._gridOptions,
      actionValue: actionableHTMLFragments.value,
      gridData: this.data,
      formioDataSubmission: this.getFormSubmissionContextForInterpolation(),
      formioInstance: this.formioInstance,
      smartTableInstance: this,
      formioEvent: this.formioEvent
    };
    actionableHTMLFragments.displayFormatter.handler?.(
      <CellActionContext>cellActionContext,
      actionableHTMLFragments.displayFormatter
    );
  }

  tooltipValueGetter(rowData: any, columnDef: GridColumnDefinition) {
    let cellData = columnDef?.field ? _.get(rowData, columnDef.field) : '';
    return cellData;
  }

  private getPageLoadEvent(): any {
    return this.currentPageLoadEvent
      ? this.currentPageLoadEvent
      : this.defaultPageLoadEvent();
  }

  private defaultPageLoadEvent(): any {
    return { first: 0, rows: this.paginationPageSizeCurrent };
  }

  private loadDataSeriously($event: any): Observable<any> {
    if (this.gridOptions?.defaultData && this.gridOptions?.defaultData.length) {
      return this.populateStaticData();
    } else if (this.gridOptions?.gridDataContext == CONTEXT_DATA_DRIVEN) {
      console.log(
        this._gridOptions.title,
        'loadDataSeriously: Loading data locally',
        this.data
      );
      return this.loadDataLocally($event);
    } else {
      // API DRIVEN
      return this.loadDataRemotely($event);
    }
  }

  public getColspan(gridOptions, childGridOptions ): any {

    let columnDefinitionsLength = childGridOptions?.rowSpanCount || gridOptions?.columnDefinitions?.length || 0;

    if (childGridOptions?.checkboxSelection || childGridOptions?.needsRowExpansion) {
      columnDefinitionsLength++;
    }

    if (childGridOptions?.slnoColumnRequired) {
      columnDefinitionsLength++;
    }
    return columnDefinitionsLength;
  }

  private computeValue(
    columnDef: GridColumnDefinition,
    rowData: any,
    gridOptions: GridOptions
  ): string | null | undefined {
    let cellData =
      columnDef?.field && columnDef?.compositeColumn
        ? null
        : _.get(rowData, columnDef?.field);

    if (columnDef?.valueFormatterTemplate) {
      //apply value Formatter
      cellData = LodashTemplateEvaluator.interpolate(
        columnDef?.valueFormatterTemplate,
        {
          rowData: rowData,
          gridOptions: gridOptions
        }
      );
    }
    return cellData;
  }

 private evaluateDynamicValue( attributeName: any, rowData: any,
                           rowIndex: number,
                           gridOptions: GridOptions) {
   let attributeValue: string | undefined | null = '';
   if (gridOptions?.[attributeName]) {
     attributeValue = LodashTemplateEvaluator.interpolate(
       gridOptions?.[attributeName],
       {
         rowData: rowData,
         rowIndex: rowIndex,
         gridOptions: gridOptions
       }
     );
   }

   return attributeValue;
 }

  private defaultRendering(
    rowData: any,
    rowIndex: number,
    colIndex: number,
    columnDef: GridColumnDefinition,
    gridOptions: GridOptions
  ): any {
    let cellData = this.computeValue(columnDef, rowData, gridOptions);

    let cellDataRendererContext: CellDataRendererContext = {
      index: colIndex,
      rowData: rowData,
      columnDef: columnDef,
      rowIndex
    };
    let formattedData = columnDef?.defaultDisplayFormatter?.(
      cellData,
      cellDataRendererContext
    );

    return formattedData;
  }

  /**
   * Renders the data and attach the actions based on the DisplayValue Formatters assigned to the Grid Column
   * @param rowData
   * @param index
   * @param columnDef
   */
  private advancedRendering(
    rowData: any,
    rowIndex: number,
    colIndex: number,
    columnDef: GridColumnDefinition,
    gridOptions: GridOptions
  ): ActionableHTMLFragment[] {
    let actionableHTMLFragmentList: ActionableHTMLFragment[] = [];

    // 1. compute the value of cell. Can be raw value or value from a  Value Formatter
    let cellData = this.computeValue(columnDef, rowData, gridOptions);

    if (columnDef?.displayValueFormatters?.length == 0) {
      return actionableHTMLFragmentList;
    }
    // 2. iterate through the display Value Formatters - remember, advanced rendering is invoked from template only when there is at least one display Formatter defined
    columnDef?.displayValueFormatters?.forEach((displayFormatter, i) => {
      //2.1 get the formatted display Value

      let displayValue: string | undefined | null = '';
      if (displayFormatter?.displayValueFormatterTemplate) {
        displayValue = LodashTemplateEvaluator.interpolate(
          displayFormatter.displayValueFormatterTemplate,
          {
            rowData: rowData,
            data: cellData,
            gridOptions: gridOptions
          }
        );
      }

      // prepare the HTML Fragment responsible for rendering and  passing the action params
      let actionableHTMLFragment: ActionableHTMLFragment = {
        value: cellData,
        displayValue: trimContent(displayValue),
        displayFormatter: displayFormatter,
        disabled: null,
        toolTip: displayFormatter.toolTip
      };

      // Associate the action value
      if (
        displayFormatter?.category &&
        displayFormatter.category !== 'no_action'
      ) {
        let actionValue: string | null | undefined = null;
        if (displayFormatter?.actionValueFormatterTemplate) {
          actionValue = LodashTemplateEvaluator.interpolate(
            displayFormatter?.actionValueFormatterTemplate,
            {
              rowData: rowData,
              data: cellData,
              submissionData:
                this.formioDataSubmission
                  ?.data /*when context data driven is used, there were use cases to refer the complete submission data to deduce the business logic */,
              gridOptions: gridOptions
            }
          );
        } else {
          switch (displayFormatter?.category) {
            case 'navigation':
              actionValue = displayFormatter?.actionUrl;
              break;
            case 'popup':
              actionValue = displayFormatter?.popupScreenId;
              break;
            case 'download':
              actionValue = displayFormatter?.downloadId;
              break;
            case 'delete_row':
            case 'edit_row':
              actionValue = '' + rowIndex; //row index
              break;
          }
        }
        actionableHTMLFragment.value = trimContent(actionValue);
        if (actionableHTMLFragment?.displayFormatter?.conditionallyDisable) {
          let scriptValue = LodashTemplateEvaluator.interpolate(
            actionableHTMLFragment?.displayFormatter?.conditionallyDisable,
            {
              rowData: rowData,
              submissionData: this.formioDataSubmission?.data
            }
          );
          let shouldDisable = FormRendererUtils.evaluate(
            scriptValue,
            {
              rowData: rowData,
              submissionData:
                this.formioDataSubmission &&
                this.formioDataSubmission.hasOwnProperty('data')
                  ? this.formioDataSubmission.data
                  : this.formioDataSubmission
            },
            'disable',
            true
          );
          if (shouldDisable) {
            actionableHTMLFragment.disabled = 'disabled';
            actionableHTMLFragment.toolTip =
              actionableHTMLFragment?.displayFormatter?.toolTipWhenDisabled ||
              '';
          }
        }
      }
      actionableHTMLFragmentList.push(actionableHTMLFragment);
    });

    return actionableHTMLFragmentList;
  }

  refreshGrid() {
    this.globalSearchText = '';
    this.reloadData();
  }

  private loadDataRemotely($event: any): Observable<any> {
    const config = {};
    let httpParamsFromString = '';
    //pagination
    config['page'] = $event.first / $event.rows + 1;
    config['size'] = $event.rows;

    //sorting
    if ($event.sortField) {
      let sort = $event.sortField;
      sort = sort + ',' + ($event.sortOrder > 0 ? 'asc' : 'desc');
      config['sort'] = sort;
    } else if (
      this.gridOptions?.defaultSort &&
      !_.isEmpty(this.gridOptions?.defaultSort)
    ) {
      config['sort'] = this.gridOptions.defaultSort;
    }

    let searchQueryParam = '';
    // q param
    if (
      this.gridOptions?.globalSearchEnabled &&
      !_.isNil(this.globalSearchText) &&
      !_.isEmpty(this.globalSearchText) &&
      !_.isEmpty(this._gridOptions?.globalSearchQueryFormatter)
    ) {
      let searchValue =
        this.globalSearchFilterOperator == 'starts_with'
          ? `[${this.globalSearchText})`
          : `(%${this.globalSearchText}%)`;
      //apply value Formatter
      searchQueryParam = LodashTemplateEvaluator.interpolate(
        this._gridOptions?.globalSearchQueryFormatter,
        {
          searchText: searchValue,
          gridOptions: this.gridOptions
        }
      );
    }
    let urlToUse = <string>this._gridOptions.apiUrl;

    if (!_.isEmpty(searchQueryParam)) {
      let url = this.getURLObject(urlToUse); // url be like => http://localhost:4200/api/core/suspense-account-transactions?q=status:PENDING_ACTION%20|%20PARTIALLY_RESOLVED
      let defaultQparam = '';
      if (!isNullOrUndefinedOrEmpty(url.searchParams.get('q'))) {
        console.debug(`Default q Param => ${url.searchParams.get('q')}`); //return value be like => status:PENDING_ACTION%20|%20PARTIALLY_RESOLVED
        defaultQparam = url.searchParams.get('q') + ';';
        urlToUse = url.pathname; // url.origin + url.pathname => will be something like => http://localhost:4200//api/core/suspense-account-transactions
      }
      config['q'] = defaultQparam + searchQueryParam;
      console.debug(
        `Q Params => default [${defaultQparam}] , SearchParams [${searchQueryParam}] final qParam [${config['q']}]`
      );
    }

    let params = new HttpParams();

    Object.keys(config).forEach((key) => {
      params = params.set(key, config[key]);
    });

    if (!this._gridOptions?.apiUrl) {
      console.error('Grid API Url not populated');
      return of(null);
    }

    if (!_.isEmpty(this.advanceSearchQuery)) {
      //check Advanced filter
      urlToUse += this.advanceSearchQuery;
    }

    let sourceToInterpolate = this.getFormSubmissionContextForInterpolation();

    // sourceToInterpolate = _.isUndefined(this.formioDataSubmission?.data) ? this.formioDataSubmission : this.formioDataSubmission.data;
    urlToUse = LodashTemplateEvaluator.interpolate(
      urlToUse,
      sourceToInterpolate
    );
    //encoding querystring parameter for date filter and like search issue
    // example for date encoding: [2023-10-01 TO 2023-10-13] --> %5B2023-10-01%20TO%202023-10-13%5D
    // example for like search encoding: (%Sri%) --> (%25Sri%25)
    urlToUse = encodeURI(urlToUse);
    return this.http
      .get<PagedData<any>>(urlToUse, {
        params: params
      })
      .pipe(
        map((res) => {
          this.loading = false;

          const responseData = _.isEqual(
            this.gridOptions.pageDataFieldName,
            '*'
          )
            ? res
            : res[this.gridOptions.pageDataFieldName];

          this.data = responseData;
          this.totalRecords = res?.totalElements || 0;
          this.first = res?.first ? 0 : this.first;
          /*  if (this._expansionRowGridOptions && this._expansionRowGridOptions.childRowsAttribute) {
          this.childRowsData = _.get(this.data, this._expansionRowGridOptions?.childRowsAttribute!!);
        }*/
          return responseData;
        })
      );
  }

  private getURLObject(urlToUse: string): URL {
    const url = new URL(urlToUse, window.location.origin);
    return url;
  }

  protected getFormSubmissionContextForInterpolation() {
    //precedence rule kicks in
    // 1. statetoPropogate availability in either formioDataSubmission or formioDataSubmission.data
    // 2. formioDataSubmission.data availability
    // 3. formioDataSubmission
    if (!_.isEmpty(_.get(this.formioDataSubmission, 'propagatedState'))) {
      return this.formioDataSubmission;
    } else if (
      !_.isEmpty(_.get(this.formioDataSubmission, 'data.propagatedState'))
    ) {
      return this.formioDataSubmission.data;
    } else if (!_.isEmpty(_.get(this.formioDataSubmission, 'data'))) {
      return this.formioDataSubmission.data;
    } else if (!_.isEmpty(_.get(this.formioInstance, 'submission.data'))) {
      return this.formioInstance.submission.data;
    } else {
      return this.formioDataSubmission;
    }
  }

  private loadDataLocally($event: any, useDataFromSubmission: boolean = true) {
    console.log(this._gridOptions.title, 'ENTRY: loadDataLocally', this.data);
    if (this.formioDataSubmission) {
      if (useDataFromSubmission) {
        this.data = _.cloneDeep(
          _.get(
            this.formioDataSubmission,
            this.gridOptions.contextDataFieldName
          )
        );
        if (!this.data && this.formioDataSubmission?.data) {
          this.data = _.cloneDeep(
            _.get(
              this.formioDataSubmission.data,
              this.gridOptions.contextDataFieldName
            )
          );
        }
      }
      this.loading = false;
      this.totalRecords = this.data?.length || 0;
      this.first = 0;
      this.localSort($event);
      return of(this.data);
    } else {
      return of(null);
    }
  }

  private populateStaticData() {
    this.loading = false;
    this.data = this.gridOptions?.defaultData;
    this.totalRecords = this.data?.length || 0;
    this.first = 0;
    return of(this.data);
  }

  exportExcel() {
    import('xlsx').then((xlsx) => {
      const xlData = _.cloneDeep(this.data);

      delete xlData['advancedRendering'];
      delete xlData['defaultRendering'];
      let xlsxJsonData = this.transformDataToExcel(xlData);
      const worksheet = xlsx.utils.json_to_sheet(xlsxJsonData);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ['Data'] };
      const excelBuffer: any = xlsx.write(workbook, {
        bookType: 'xlsx',
        type: 'array'
      });
      this.saveAsExcelFile(
        excelBuffer,
        this.formattedTitle || this._gridOptions.title
      );
    });
  }

  saveAsExcelFile(buffer: any, fileName: string | undefined): void {
    let EXCEL_TYPE =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(
      data,
      fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION
    );
  }

  importData() {}

  documentUpload(event: any, fileUpload: FileUpload) {
    for (const file of event.files) {
      const objectURL = URL.createObjectURL(file);
      // @ts-ignore
      this.uploadDocument(file, this.maxFileSize).subscribe(
        (documentData: Document) => {
          console.log('Documents uploaded in smart table-> ', documentData);

          const data = {
            fileName: documentData.fileName,
            fileType: documentData.fileType,
            id: documentData.id
          };
          this.formioInstance.submission.data['importFileId'] = documentData.id;
          this.formioInstance.submission.data['importFileName'] = file.name;

          this.triggerParsing(file);
          /* if(!this.allowMultiple){
             this.inputDocuments = [];
           }
           this.inputDocuments.push(documentData);
           this.changeDetector.detectChanges();
           this.documentsUploaded.emit(this.inputDocuments);*/
        }
      );
    }
    fileUpload.clear();
  }

  emit(eventName, payload) {
    console.log('Emitting event from smart table -> ' + eventName);
    this.formioEvent.emit({
      eventName,
      data: payload
    });
  }

  uploadDocument(file: File, maxFileSize: number) {
    if (file.size > maxFileSize) {
      // this.messageDialogService.warn('File size is too large');
      return;
    }
    const fileToSave: Document = new Document();
    fileToSave.fileName = file.name;
    fileToSave.fileSize = file.size;
    fileToSave.fileType = file.type;
    const formData: FormData = new FormData();
    formData.append(
      'documentJson',
      new Blob([JSON.stringify(fileToSave)], {
        type: 'application/json'
      })
    );
    formData.append('file', file);
    return this.documentManagementService.createDocument(formData);
  }

  onFileSelect($event: FileSelectEvent, fileUpload: FileUpload) {
    this.documentUpload($event, fileUpload);
    debugger;
  }

  private transformDataToExcel(xlData: any) {
    let jsonRowData: any[] = [];
    let jsonColumnData = {};
    let headerNames: any[] = [];
    let childHeaderNames = [];
    // headerNames.push({ fieldName :'index' , fieldValue: 'Sl No'});
    this._gridOptions.columnDefinitions.map(
      (columnDef) =>
        columnDef.field &&
        headerNames.push({
          fieldName: columnDef.field,
          fieldValue: columnDef.headerName
        })
    );

    let entry = {};
    headerNames.forEach((header) => {
      entry[header.fieldName] = header.fieldValue;
    });

    jsonRowData.push(entry);

    xlData.forEach((dataEntry, i) => {
      let entry = {};
      entry['index'] = i;

      headerNames.forEach((header) => {
        entry[header.fieldName] = _.get(dataEntry, header.fieldName);
      });
      jsonRowData.push(entry);
    });

    return jsonRowData;
  }

  private triggerParsing(file: any) {
    const providerFunction = (Formio as any).Providers.getProvider(
      'parser',
      this._gridOptions.parser
    );

    if (providerFunction) {
      const fileParsingContext = {
        file: file,
        fileInfo: undefined,
        component: this.gridOptions,
        submission: this.formioDataSubmission,
        componentContext: this
      };
      providerFunction().parse(fileParsingContext);
    }
  }

  private attachFormioEventListeners(formio: any) {
    formio.on('fileParsingCompleted', () => {
      console.log('fileParsingCompleted event captured in smart table');
      this.onFileParsingCompleted();
    });
  }

  private onFileParsingCompleted() {

    if (this._gridOptions?.parserResultField && !isNullOrUndefinedOrEmpty(this._gridOptions?.parserResultField)
      && this.formioInstance?.submission?.data) {
      const gridData =
        this.formioInstance.submission.data[this._gridOptions.parserResultField];
      this.formioInstance.submission.data['parsedResults'] = gridData;
      this.reloadData();
      this.validateImportedData();
    }
  }

  private validateImportedData() {
    let _self;
    const importFormId = this._gridOptions.importFormId;
    this.formService.getById(importFormId).subscribe((data) => {
      if (data?.components) {
        let form: any = {};
        form.components = JSON.parse(<string>data?.components[0]);

        Formio.Headers(
          new Headers({
            Authorization: 'Bearer ' + IamAuthUtils.getCurrentUserToken()
          })
        );
        //This tokens object will be used by XHR requests from within the JS framework to append the headers on request.
        // @ts-ignore
        Formio.tokens = {
          Authorization: 'Bearer ' + IamAuthUtils.getCurrentUserToken()
        };
        const formElement = document.createElement('div');
        Formio.createForm(formElement, {
          components: form.components
        }).then((form) => {
          const gridData =
            this.formioInstance.submission.data[
              this._gridOptions.parserResultField
            ];
          debugger;
          gridData.map((entry) => {
            debugger;
            const checkValidity = form.checkValidity(entry, true, entry);
            console.log('validity => ' + checkValidity);
          });

          this.formioInstance.submission.data['canImport'] = true;
          this.emit('enable-process-import-event', {
            data: {}
          });
        });
      }
    });
  }
}

export interface ActionableHTMLFragment {
  value?: string | null | undefined;
  displayValue?: string | null | undefined;
  displayFormatter: DisplayFormatter; //This is used only for holding configuration. The values from this cannot be used to render dynamic values.
  disabled: any;
  toolTip: string;
}
