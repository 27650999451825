import { DOCUMENT } from '@angular/common';
import {
  ChangeDetectorRef,
  Inject,
  Injectable,
  Renderer2
} from '@angular/core';
/** source ref from https://medium.com/@piyalidas.it/angular-theme-integration-using-dynamically-load-css-1617147799bf*/
@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  private style: HTMLLinkElement;
  private cssFile: string;
  private themeCSSID: string = 'themeCSS';

  constructor(@Inject(DOCUMENT) private document: Document) {}

  setTheme(theme: Theme, renderer2: Renderer2, cdr: ChangeDetectorRef) {
    cdr.detach();

    this.cssFile = theme.cssRef;
    //  this.removeExistingThemeStyle(renderer2, this.themeCSSID);

    // Create a link element via Angular's renderer to avoid SSR troubles
    // this.style = renderer2.createElement('link') as HTMLLinkElement;
    this.style = document.getElementById(this.themeCSSID) as HTMLLinkElement;

    // Set type of the link item and path to the css file
    //renderer2.setProperty(this.style, 'rel', 'stylesheet');
    //  renderer2.setProperty(this.style, 'id', this.themeCSSID);
    renderer2.setProperty(this.style, 'href', theme.cssRef);
    renderer2.setProperty(this.style, 'data-theme-id', theme.id);
    cdr.reattach();
    // Add the style to the head section
    //  renderer2.appendChild(this.document.head, this.style);
  }

  removeExistingThemeStyle(renderer2: Renderer2, themeCSSID: string) {
    const themeIDHTMlElem = this.document.getElementById(themeCSSID);
    if (themeIDHTMlElem) {
      renderer2.removeChild(this.document.head, themeIDHTMlElem);
    }
  }
}

export function themes(): Theme[] {
  return [
    {
      id: 'jds_red_theme',
      title: 'JDS Theme - RED',
      cssRef: 'jds_red_dark.css',
      isDefault: true
    },
    {
      id: 'jds_blue_theme',
      title: 'JDS Theme - BLUE',
      cssRef: 'jds_blue_dark.css'
    },
    {
      id: 'jds_green_theme',
      title: 'JDS Theme - GREEN',
      cssRef: 'jds_green_light.css'
    },
    {
      id: 'jds_purple_theme',
      title: 'JDS Theme - PURPLE',
      cssRef: 'jds_purple_light.css'
    },
    {
      id: 'jds_pink_theme',
      title: 'JDS Theme - PINK',
      cssRef: 'jds_pink_light.css'
    },
    {
      id: 'jds_orange_dark_theme',
      title: 'JDS Theme - ORANGE DARK',
      cssRef: 'jds_orange_dark.css'
    },
    {
      id: 'jds_orange_light_theme',
      title: 'JDS Theme - ORANGE LIGHT',
      cssRef: 'jds_orange_light.css'
    },
    {
      id: 'jds_indigo_theme',
      title: 'JDS Theme - INDIGO',
      cssRef: 'jds_indigo_dark.css'
    },
    {
      id: 'jds_light_green_theme',
      title: 'JDS Theme - GREEN LIGHT',
      cssRef: 'jds_green_lighter.css'
    }
  ];
}

export interface Theme {
  id: string;
  title: string;
  cssRef: string;
  isDefault?: boolean;
}
