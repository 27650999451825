export enum FormIODataTypes {
  CONTAINER = 'container',
  DATA_GRID = 'datagrid',
  TABS = 'tabs',
  COLUMNS = 'columns',
  COMPONENTS = 'components',
  BUTTON = 'button',
  PERSISTENT = 'persistent',
  VALIDATE = 'validate',
  INPUT = 'input',
  SELECT = 'select',
  ENTITY_NAME = 'entityName',
  PANEL = 'panel',
  CHILD_ITEMS = 'childItems'
}

export enum ComponentLabels {
  PREVIEW = 'Preview'
}

export enum MappingAnnotationType {
  ONE_TO_ONE = 'ONE_TO_ONE',
  ONE_TO_MANY = 'ONE_TO_MANY',
  MANY_TO_ONE = 'MANY_TO_ONE',
  MANY_TO_MANY = 'MANY_TO_MANY'
}

export enum FormIoComponentKeys {
  TEXT_FIELD = 'textfield',
  TEXT_AREA = 'textarea',
  NUMBER = 'number',
  CHECKBOX = 'checkbox',
  RADIO = 'radio',
  URL = 'url',
  SELECT = 'select',
  SELECT_BOXES = 'selectboxes',
  SURVEY = 'survey',
  DAY = 'day',
  TIME = 'time',
  EMAIL = 'email',
  PASSWORD = 'password',
  FILE = 'file',
  CURRENCY = 'currency',
  PHONE = 'phone',
  DATE_TIME = 'datetime',
  ADDRESS = 'address'
}

export enum FormIoComponentValues {
  STRING = 'String',
  INTEGER = 'Integer',
  STRING_LIST = 'List<String>',
  STRING_BOOLEAN_MAP = 'Map<String, Boolean>',
  LOCAL_DATE = 'LocalDate',
  LOCAL_TIME = 'LocalTime',
  FILE = 'File',
  DOUBLE = 'Double',
  LOCAL_DATE_TIME = 'LocalDateTime',
  OFFSET_DATE_TIME = 'OffsetDateTime'
}

export enum Roles {
  USER_ROLE = 'user_role',
  SUPER_ADMIN = 'super_admin'
}
