import { Injector } from '@angular/core';
import {
  FormioCustomComponentInfo,
  registerCustomFormioComponent
} from '@evi-ui/angular';
import { TimelineComponent } from '@shared/components/timeline/timeline.component';
import {
  AfterPostToUrlAction,
  CustomButtonActionType
} from '@shared/components/custom-action-button/handlers/custom-button-action-handler-registry';

const COMPONENT_OPTIONS: FormioCustomComponentInfo = {
  type: 'timeline',
  selector: 'app-timeline',
  title: 'Timeline',
  group: 'basic',
  icon: 'fa-solid fa-check-to-slot',
  emptyValue: {},
  editForm: editForm
};

function editForm() {
  return {
    components: [
      {
        label: 'Display',
        components: [dataTab()],
        key: 'display',
        type: 'tabs',
        input: false,
        tableView: false
      }
    ]
  };
}

function dataTab() {
  return {
    label: 'Display',
    key: 'display',
    components: [
      {
        weight: 0,
        type: 'textfield',
        input: true,
        key: 'propertyName',
        label: 'Property Name',
        tooltip: 'The name of this field in the API endpoint.',
        validate: {
          pattern: '(\\w|\\w[\\w]*\\w)',
          patternMessage:
            'The property name must only contain alphanumeric characters, underscores and should not be ended by dash or dot.',
          required: true
        }
      },
      {
        weight: 10,
        type: 'textfield',
        input: true,
        key: 'dataSource',
        label: 'Values List (Data Source)',
        placeholder: 'A List containing the values to be used on timeline',
        tooltip: 'A List containing the values to be used on timeline',
        validate: {
          required: true
        }
      },
      {
        key: 'eventsFieldMapping',
        weight: 20,
        type: 'editgrid',
        label: 'Event Mapping',
        components: [
          {
            key: 'eventProperty',
            weight: 10,
            type: 'select',
            label: 'Event Property',
            input: true,
            dataSrc: 'values',
            data: {
              values: [
                { label: 'Title', value: 'title' },
                { label: 'Date & Time', value: 'date' }
              ]
            }
          },
          {
            key: 'fieldName',
            weight: 20,
            type: 'textfield',
            label: 'Field Name'
          }
        ]
      }
    ]
  };
}

class Status {
  static status = new Map();
}

export function registerTimelineComponent(injector: Injector, screen: string) {
  if (!Status.status.has(screen)) {
    try {
      registerCustomFormioComponent(
        COMPONENT_OPTIONS,
        TimelineComponent,
        injector
      );
      Status.status.set(screen, true);
    } catch (e) {
      console.log('Error while registering TimelineComponent: ' + screen);
    }
  }
}
