import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CustomActionButtonUtils {
  public extractDataMapVariables(
    inputString: string,
    variableWithIndexInfo: string
  ) {
    let arrayIndexMap = this.getArrayIndexMap(variableWithIndexInfo);
    let resolvedInputString = inputString;
    arrayIndexMap.forEach((value, key) => {
      let toReplace = key + '[currentRowNumber]'.trim();
      if (inputString.includes(toReplace)) {
        resolvedInputString = resolvedInputString.replace(
          toReplace,
          key + '[' + value + ']'
        );
      }
    });
    return resolvedInputString;
  }

  private getArrayIndexMap(inputString: string) {
    const regex = /\[(.*?)\]/g;

    const matches = [...inputString.matchAll(regex)];

    const extractedStrings = matches.map((match) => match[1]);

    const result = new Map();

    for (let i = 0; i < extractedStrings.length - 1; i++) {
      const string = extractedStrings[i];
      const number = extractedStrings[i + 1];
      if (!isNaN(Number(number))) {
        result.set(string, Number(number));
      }
    }

    return result;
  }
}
