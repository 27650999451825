import {Component, DestroyRef, EventEmitter, inject, Inject, Input, Output, ViewChild} from '@angular/core';
import {AuthService} from '@shared/services/auth.service';
import {DOCUMENT} from '@angular/common';
import {ActivatedRoute, Router} from '@angular/router';
import {embedDashboard} from '@superset-ui/embedded-sdk';
import {analytics} from '../../../../environments/environment';
import {EventEmitter as EventEmitter3} from 'eventemitter3';
import {FormioComponent} from '@evi-ui/angular';
import {HttpClient} from '@angular/common/http';
import {EventBusService} from '@shared/event-bus.service';
import {EventType} from '@shared/model/event.data';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {AnalyticsConfig} from '@shared/components/embedded-report/embedded-report.model';
import * as _ from 'lodash';
import {FormRendererUtils} from "../../../portal/application-forms/all-forms-renderer/form-renderer-utils";
import {LodashTemplateEvaluator} from "@shared/components/smart-table/LodashTemplateEvaluator";
import {Utils as FormioUtils} from "@evi-ui/js";

@Component({
  selector: 'app-embedded-report',
  templateUrl: './embedded-report.component.html'
})
export class EmbeddedReportComponent {
  destroyRef = inject(DestroyRef);
  public containerId: string;
  private formData: any;
  @Output()
  valueChange = new EventEmitter<number>();

  eventEmitter = new EventEmitter3();

  @ViewChild(FormioComponent) formioComponent: FormioComponent;

  @Output()
  formioEvent = new EventEmitter3();

  private formioInstance: any;

  constructor(
    private http: HttpClient,
    private router: Router,
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    @Inject(DOCUMENT) private document: Document,
    private eventBusService: EventBusService,
    private formRendererUtils: FormRendererUtils
  ) {
    this.eventBusService
      .on(EventType.FORM_READY)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((formioInstance) => {
        if (formioInstance == null) {
          return;
        }
        console.log(
          'Received FORM_READY event in  Embedded Dashboard Report Component ',
          formioInstance
        );
        this.formioInstance = formioInstance;
      });

    this.eventBusService
      .on(EventType.FORM_DATA_LOADED)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((formData) => {
        console.log(
          'Received FORM_DATA_LOADED event in  Embedded Dashboard Report Component ',
          formData
        );
        if (formData == null) {
          //because we are subscribing a behavior Subject with default value as null, first event emitted by default will be null.
          return;
        }
        this.formData = formData;
      });
  }

  _analyticsConfig: AnalyticsConfig;

  public get analyticsConfig(): any {
    return this._analyticsConfig;
  }

  @Input()
  public key: string;

  @Input()
  public set analyticsConfig(opts: AnalyticsConfig) {
    this._analyticsConfig = opts;
    this.containerId = this._analyticsConfig.containerID + _.random(1, 1000);
    this.displayAnalytics();
  }

  ngOnInit(): void {
    console.log('onInit of Embedded report');
  }

  private displayAnalytics(): any {

    const queryParams = this.activatedRoute.snapshot.queryParams;
    const dashboardSlug = queryParams['dashboardSlug'];
    console.log("loading dashboardSlug : ", dashboardSlug);

    if (dashboardSlug || this.formioInstance) {
      let configuredSlugId = dashboardSlug ? dashboardSlug : this._analyticsConfig.embeddedDashboardId!!;

      let requestBody = LodashTemplateEvaluator.interpolate(configuredSlugId, {
        data: this.formData
      });
      requestBody = FormioUtils.evaluate(
        requestBody,
        {data: this.formData},
        'value'
      );

      configuredSlugId = requestBody ? requestBody : configuredSlugId;
      if (this.formRendererUtils.needsInterpolation(configuredSlugId)) {
        configuredSlugId = this.formRendererUtils.interpolate(configuredSlugId, {data: this.formData})
      }
      this.authService.getGuestToken(configuredSlugId).subscribe(
        data => {
          // this.customActionButtonUtils.extractDataMapVariables(containerId, )
          let element = document.getElementById('analytics-container-' + this.containerId);
          if (element != null) {
            embedDashboard({
              id: data.embeddedDashboardId,
              supersetDomain: `${analytics.analyticsDomainUrl()}`,
              mountPoint: element,
              fetchGuestToken: () => data.guestToken,
              dashboardUiConfig: this._analyticsConfig.dashboardConfigJson ? JSON.parse(this._analyticsConfig.dashboardConfigJson) : '{}',
            }).then(r => {
              console.log("Embedded dashboard loaded -> " + data.embeddedDashboardId, r);
            })
          }
        },
        error => console.log('error: ', error),
      );
    } else {
      console.log('Form/slugs not yet initialized');
    }
  }


}
