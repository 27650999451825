import * as _ from 'lodash';

export class LodashTemplateEvaluator {
  static cache = {};

  static templateSettings = {
    evaluate: /\{%([\s\S]+?)%\}/g,
    interpolate: /\{\{([\s\S]+?)\}\}/g,
    escape: /\{\{\{([\s\S]+?)\}\}\}/g
  };

  public static template(template, hash?): any {
    hash = hash || LodashTemplateEvaluator.stringHash(template);
    if (LodashTemplateEvaluator.cache[hash]) {
      return LodashTemplateEvaluator.cache[hash];
    }
    try {
      // Ensure we handle copied templates from the ejs files.
      //  template = template.replace(/ctx\./g, '');
      const lodashTemplateSourceURL = '/lodash/template/source' + hash;
      return (LodashTemplateEvaluator.cache[hash] = _.template(template, {
        ...LodashTemplateEvaluator.templateSettings,
        ...{ sourceURL: lodashTemplateSourceURL }
      }));
    } catch (err) {
      console.warn('Error while processing template', err, template);
    }
  }

  public static interpolate(rawTemplate, data, hash?) {
    let template = LodashTemplateEvaluator.template(rawTemplate, hash);
    if (typeof template === 'function') {
      try {
        return template(data);
      } catch (err: any) {
        console.warn('Error interpolating template', err, rawTemplate, data);
        return rawTemplate;
      }
    }
    return template;
  }

  static stringHash(str) {
    //source -> https://www.npmjs.com/package/string-hash
    let hash = 5381,
      i = str.length;

    while (i) {
      hash = (hash * 33) ^ str.charCodeAt(--i);
    }

    /* JavaScript does bitwise operations (like XOR, above) on 32-bit signed
     * integers. Since we want the results to be always positive, convert the
     * signed int to an unsigned by doing an unsigned bitshift. */
    return hash >>> 0;
  }
}
