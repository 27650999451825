import {
  CellDataRendererContext,
  GridColumnDefinition
} from './smart-table.model';
import * as _ from 'lodash';
import * as moment_ from 'moment';
import { formatCurrency, formatNumber, formatPercent } from '@angular/common';

const moment = moment_;

export function attachDefaultDisplayFormatter(column: GridColumnDefinition) {
  switch (column.cellDataType) {
    case 'string':
      column.defaultDisplayFormatter = stringFormatter;
      break;
    case 'number':
      column.defaultDisplayFormatter = numberFormatter;
      break;
    case 'currency':
      column.defaultDisplayFormatter = currencyFormatter;
      break;
    case 'date':
      column.defaultDisplayFormatter = dateFormatter;
      break;
    case 'boolean':
      column.defaultDisplayFormatter = booleanFormatter;
      break;
    /*  case 'href':
          column.cellFormatter = hrefCellFormatter;
          break;*/
    // Add more cases for other data types
  }
}

// Custom cell formatters
function stringFormatter(
  data: any,
  cellDataRendererContext: CellDataRendererContext
) {
  if (_.isNil(data)) {
    return cellDataRendererContext.columnDef.defaultValueIfNull;
  }
  const formatOptions = cellDataRendererContext.columnDef.formatOptions;
  let formattedData = changeCase(data, formatOptions?.case);
  formattedData = wrap(
    formattedData,
    formatOptions?.prefix,
    formatOptions?.suffix
  );

  return `<div class="cell-data ${cellDataRendererContext.columnDef.cellDataType}">${formattedData}</div>`;
}

/**
 * js fiddle ref for test cases -> https://jsfiddle.net/dstv5x3u/2/
 * @param data
 * @param cellDataRendererContext
 */
function dateFormatter(
  data: any,
  cellDataRendererContext: CellDataRendererContext
) {
  if (_.isNil(data)) {
    return cellDataRendererContext.columnDef.defaultValueIfNull;
  }
  const formatOptions = cellDataRendererContext.columnDef.formatOptions;
  let formattedData = data;
  if (formatOptions?.enableDateFormatter) {
    const momentDate = moment(<string>data, formatOptions?.sourceFormat);
    formattedData = momentDate.format(formatOptions?.targetFormat); // Format date as using luxon
  }
  return formattedData;
}

function booleanFormatter(
  data: any,
  cellDataRendererContext: CellDataRendererContext
) {
  if (_.isNil(data)) {
    return cellDataRendererContext.columnDef.defaultValueIfNull;
  }

  const formatOptions = cellDataRendererContext.columnDef.formatOptions;

  let formattedData = data;
  switch (formatOptions?.booleanFormat) {
    case 'yes_no':
      formattedData = data ? 'Yes' : 'No';
      break;

    case 'true_false':
      formattedData = data ? 'true' : 'false';
      break;
    case '1_0':
      formattedData = data ? '1' : '0';
      break;
  }
  return `<div class="cell-data ${cellDataRendererContext.columnDef.cellDataType}">${formattedData}</div>`; // Format boolean as "Yes" or "No"
}

/*function hrefCellFormatter(data: any, cellDataRendererContext: CellDataRendererContext) {
  return `<a routerLink="${data}">${data}</a>`; // Render a link // TODO
}*/

function numberFormatter(
  data: any,
  cellDataRendererContext: CellDataRendererContext
) {
  if (_.isNil(data)) {
    return cellDataRendererContext.columnDef.defaultValueIfNull;
  }

  const formatOptions = cellDataRendererContext.columnDef?.formatOptions;

  let formattedData = <string>data;
  switch (cellDataRendererContext.columnDef.formatOptions?.numberFormat) {
    case 'decimal':
      formattedData = formatNumber(
        data,
        <string>formatOptions?.localeId,
        formatOptions?.digitsInfo
      );
      break;

    case 'percentage':
      formattedData = formatPercent(
        data,
        <string>formatOptions?.localeId,
        formatOptions?.digitsInfo
      );
      break;
  }

  return `<div class="cell-data ${cellDataRendererContext.columnDef.cellDataType} ${cellDataRendererContext.columnDef.formatOptions?.numberFormat}">${formattedData}</div>`;
}

function currencyFormatter(
  data: any,
  cellDataRendererContext: CellDataRendererContext
) {
  if (_.isNil(data)) {
    return cellDataRendererContext.columnDef.defaultValueIfNull;
  }

  const formatOptions = cellDataRendererContext.columnDef?.formatOptions;
  let formattedData = formatCurrency(
    <number>data,
    <string>formatOptions?.localeId,
    <string>formatOptions?.currency,
    formatOptions?.currencyCode,
    formatOptions?.digitsInfo
  );
  return `<div class="cell-data ${cellDataRendererContext.columnDef.cellDataType}">${formattedData}</div>`;
}

function changeCase(
  data: any,
  caseType: 'upper_case' | 'lower_case' | 'title_case' | 'no_change' | undefined
) {
  let formattedData = data;
  switch (caseType) {
    case 'upper_case':
      formattedData = _.upperCase(data);
      break;

    case 'lower_case':
      formattedData = _.lowerCase(data);
      break;

    case 'title_case':
      formattedData = _.upperFirst(data);
      break;
    case 'no_change':
      // no change in the formatting
      break;
  }
  return formattedData;
}

function wrap(
  data: string,
  prefix: string | undefined,
  suffix: string | undefined
) {
  return prefix + data + suffix;
}
