import * as _ from 'lodash';

export function isNullOrUndefinedOrEmpty(value) {
  return _.isNil(value) || _.isEmpty(value);
}

export function testisNullOrUndefinedOrEmpty() {
  // Example usage:
  const value1 = null;
  const value2 = undefined;
  const value3 = [];
  const value4 = {};
  const value5 = 'Hello';

  console.log(isNullOrUndefinedOrEmpty(value1)); // true
  console.log(isNullOrUndefinedOrEmpty(value2)); // true
  console.log(isNullOrUndefinedOrEmpty(value3)); // true
  console.log(isNullOrUndefinedOrEmpty(value4)); // true
  console.log(isNullOrUndefinedOrEmpty(value5)); // false
}

const keysToIgnore = [
  'parentId',
  'createdById',
  'createdDate',
  'createdByIdHidden',
  'lastModifiedById',
  'lastModifiedDate',
  'createdByName',
  'lastModifiedByName',
  'lockVersion',
  'workflowId',
  'workflowInstanceId',
  'workflowStatus',
  'entityId',
  'workflowInitiatedAt',
  'correlationId',
  'messageType',
  'assignedBy',
  'assignedTo',
  'workflowHistory',
  'stepName',
  'id',
  'isDeleted',
  'customGeneratedId',
  'amendmentHistory'
];

function deleteFromObject(keyPart, obj) {
  for (var k in obj) {
    // Loop through the object
    if (~k.indexOf(keyPart)) {
      // If the current key contains the string we're looking for
      delete obj[k]; // Delete obj[key];
    } else if (typeof k === 'object') {
      deleteFromObject(keyPart, k);
    }
  }
}

const objectsDiff = (newObject, originalObject) => {
  return _.transform(newObject, function (result, value, key) {
    if (!_.isEqual(value, originalObject[key])) {
      // @ts-ignore
      result[key] =
        _.isObject(value) && _.isObject(originalObject[key])
          ? objectsDiff(value, originalObject[key])
          : value;
    }
  });
};
export function _jsonDiff(newObject, originalObject) {
  let res = objectsDiff(newObject, originalObject);
  // @ts-ignore
  keysToIgnore.forEach((key) => deleteFromObject(key, res));
  return res;
};

export function isJson(item) {
  let value = typeof item !== "string" ? JSON.stringify(item) : item;
  try {
    value = JSON.parse(value);
  } catch (e) {
    return false;
  }

  return typeof value === "object" && value !== null;
}
