<div class="checkbox">
  <label class="checkbox-wrapper">
    <input
      type="checkbox"
      class="checkbox-input"
      [checked]="aclArray.includes(aclResource)"
      [value]="value"
      (change)="onCheckBoxChange($event)"
    />
    <span class="checkbox-tile">
      <span class="checkbox-icon">
        <img [src]="imageToUse" />
      </span>
      <span class="checkbox-label">{{ label }}</span>
    </span>
  </label>
</div>
