import { Injector } from '@angular/core';
import {
  FormioCustomComponentInfo,
  registerCustomFormioComponent
} from '@evi-ui/angular';
import { NestedFormComponent } from './nested-form.component';

const COMPONENT_OPTIONS: FormioCustomComponentInfo = {
  type: 'custom-nested-form',
  selector: 'custom-nested-form',
  title: 'Custom Nested Form',
  group: 'basic',
  icon: 'fa-regular fa-wpforms',
  emptyValue: {},
  editForm: editForm
};

function editForm() {
  return {
    components: [
      { key: 'type', type: 'hidden' },
      {
        label: 'Tabs',
        components: [componentEditDisplay()],
        key: 'tabs',
        type: 'tabs',
        input: false,
        tableView: false
      }
    ]
  };
}

function componentEditDisplay() {
  return {
    label: 'Display',
    key: 'baseDisplay',
    components: [
      {
        type: 'select',
        input: true,
        dataSrc: 'url',
        data: {
          url: '/'
        },
        searchField: 'titleRegex',
        template: '<span>{{ item.title }}</span>',
        valueProperty: 'id',
        authenticate: true,
        label: 'Form',
        key: 'formId',
        weight: 10,
        lazyLoad: false,
        tooltip: 'The form to load within this form component.'
      },
      {
        label: 'Context Prefix',
        key: 'contextPrefix',
        type: 'textfield',
        weight: 11
      }
    ]
  };
}

class Status {
  static status = new Map();
}
export function registerNestedFormComponent(
  injector: Injector,
  screen: string
) {
  if (!Status.status.has(screen)) {
    try {
      registerCustomFormioComponent(
        COMPONENT_OPTIONS,
        NestedFormComponent,
        injector
      );
      Status.status.set(screen, true);
    } catch (e) {
      console.log('Error while registering NestedFormComponent: ' + screen);
    }
  }
}
