import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { MenuService } from '../../shared/services/menu.service';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.css']
})
export class LandingComponent implements OnInit {
  @Input() menuItems!: MenuItem[];

  home!: MenuItem;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private menuService: MenuService
  ) {}

  ngOnInit(): void {
    this.initBreadCrumb();

    this.activatedRoute.data.subscribe((data: any) => {
      // localStorage.setItem(IamAppConstants.current_user_profile_details, JSON.stringify(data.userData));
    });
  }

  private initBreadCrumb() {
    this.home = {
      label: 'Home',
      icon: 'fa-solid fa-house',
      routerLink: '/'
    };
  }

  setMenuItems(sidebarMenuItems: MenuItem[]) {
    console.log(sidebarMenuItems);

    this.menuItems = this.menuService.getBreadCrumbHierarchyFromCurrentUrl(
      sidebarMenuItems,
      this.router.routerState.snapshot.url
    );
  }
}
