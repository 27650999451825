import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  ElementRef,
  EventEmitter,
  inject,
  Inject,
  Input,
  OnChanges,
  Output,
  Renderer2,
  SimpleChanges
} from '@angular/core';
import { FormioCustomComponent } from '@evi-ui/angular';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { FormioEvent } from '@evi-ui/angular/elements.common';
import { HttpClient } from '@angular/common/http';
import { flatten } from 'flat';
import _ from 'lodash';
import { EventData, EventType } from '../../model/event.data';
import { EventBusService } from '../../event-bus.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { LodashTemplateEvaluator } from '../smart-table/LodashTemplateEvaluator';
import { Formio, Utils as FormioUtils } from '@evi-ui/js';

export class DependsOnConfig {
  fieldName: string;
  changeEventType?: string;
  changeEventTypeParams?: string;
}

/**
 * NOTE: This component currently works only with dependency elements that emits a single value.
 *       It taps onto the onChange event of dependency elements to capture the selected values.
 *       This doesn't work with components inside a table either.
 */
@Component({
  selector: 'url-based-textfield',
  templateUrl: './url-based-textfield.component.html',
  styleUrls: ['./url-based-textfield.component.scss']
})
export class UrlBasedTextfieldComponent
  implements FormioCustomComponent<string>, AfterViewInit, OnChanges
{
  destroyRef = inject(DestroyRef);
  @Input() key: string;
  @Input() dataKey: string;

  _domObserver: MutationObserver;
  valueFromApi: any;
  @Input() value: any = '';
  @Output() valueChange = new EventEmitter<any>();
  @Output() formioEvent?: EventEmitter<FormioEvent>;
  @Input() disabled: boolean = false;

  @Input() valueProcessor?: string;

  @Input() prefix?: string;
  private _formInstance: any;

  private _formData: any;
  private dependsOnValueMap = new Map();

  constructor(
    private router: Router,
    private eventBusService: EventBusService,
    private renderer2: Renderer2,
    @Inject(DOCUMENT) private document: Document,
    private http: HttpClient,
    private elRef: ElementRef,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    this.valueFromApi = '';

    this.eventBusService
      .on(EventType.FORM_READY)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((formInstance) => {
        this._formInstance = formInstance;
      });

    this.eventBusService
      .on(EventType.FORM_DATA_LOADED)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((formData) => {
        this._formData = formData;
        this.setValueOfComponentWithoutDetection('');
      });
  }

  /**
   * @Deprecated use _dependsOnConfig instead
   */
  _dependsOn: string[];

  @Input()
  public set dependsOn(val: any) {
    if (!val) return;
    this._dependsOn = val;
    this.dependsOnConfigBackwardCompatibility(val);
  }

  private _dependsOnConfig: DependsOnConfig[];

  @Input() set dependsOnConfig(value: DependsOnConfig[]) {
    this._dependsOnConfig = value;
  }

  _apiUrl: string;

  @Input()
  public set apiUrl(val: any) {
    if (!val) return;
    this._apiUrl = val;
  }

  _apiDataPath: string;

  @Input()
  public set apiDataPath(val: any) {
    if (!val) return;
    this._apiDataPath = val;
  }

  ngOnChanges(changes: SimpleChanges) {}

  ngOnInit() {
    this.dependsOnValueMap.clear();
    this.attachActionHandlers();
  }

  ngAfterViewInit() {
    this._domObserver = new MutationObserver((list) => {
      list.forEach((mutationRecord) => {
        if (mutationRecord.addedNodes && mutationRecord.addedNodes.length > 0) {
          mutationRecord.addedNodes.forEach((node) => {
            if (node.hasChildNodes()) {
              let flattenedNode = flattenNode(node);
              flattenedNode.forEach((currentNode) => {
                let dependsOnComponentConfig =
                  this.getDependsOnComponent(currentNode);
                if (
                  currentNode.name &&
                  currentNode.hasAttribute('idclass') &&
                  !_.isUndefined(dependsOnComponentConfig)
                ) {
                  let eventType = this.getEventType(
                    currentNode,
                    dependsOnComponentConfig
                  ); // currentNode.getAttribute("type") === 'radio' ? "click" : "change";
                  this.renderer2.listen(currentNode, eventType, (event) => {
                    this.dependsOnAttributesListener(
                      event,
                      dependsOnComponentConfig!
                    );
                  });
                }
              });
            }
          });
        }
      });
    });

    this.initializeDependencyMapWithDefaultValues();
    this._domObserver.observe(document.body, {
      childList: true,
      subtree: true
    });
  }

  /**
   * Add dynamic event listeners on components that are marked as _dependsOn
   */
  attachActionHandlers() {
    this.dependsOnValueMap.clear();
    this._dependsOnConfig?.forEach((dependsOnAttributeConfig, index) => {
      let elementKey = dependsOnAttributeConfig.fieldName;
      let domElements = this.document.querySelectorAll(
        "[idClass='" + elementKey + "']"
      );
      domElements.forEach((element) => {
        let eventType = this.getEventType(element, dependsOnAttributeConfig);
        this.renderer2.listen(element, eventType, (event) => {
          this.dependsOnAttributesListener(event, dependsOnAttributeConfig);
        });
      });
    });
    this.initializeDependencyMapWithDefaultValues();
    this.assimilateAndTrigger();
  }

  ngOnDestroy() {
    this._domObserver.disconnect();
  }

  private dependsOnConfigBackwardCompatibility(dependsOn: string[]) {
    const dependsOnConfig: DependsOnConfig[] = [];

    dependsOn?.forEach((val) => {
      dependsOnConfig.push({
        fieldName: val
      });
    });
    this.dependsOnConfig = dependsOnConfig;
  }

  private initializeDependencyMapWithDefaultValues() {
    this._dependsOnConfig?.forEach((dependsOnAttribute) => {
      let domElements = this.document.querySelectorAll(
        "[idClass='" + dependsOnAttribute.fieldName + "']"
      );
      domElements.forEach((element) => {
        // @ts-ignore
        if (element.value) {
          // @ts-ignore
          if (element.type == 'radio' && element.checked) {
            // @ts-ignore
            this.dependsOnValueMap.set(
              dependsOnAttribute.fieldName,
              (<any>element).value
            );
          }
          // @ts-ignore
          if (element.type != 'radio') {
            // @ts-ignore
            this.dependsOnValueMap.set(
              dependsOnAttribute.fieldName,
              (<any>element).value
            );
          }
        }
      });
    });
  }

  private getDependsOnComponent(currentNode) {
    let idClassValue = currentNode.getAttribute('idclass') as string;
    return _.find(this._dependsOnConfig, { fieldName: idClassValue });
  }

  private getEventType(
    dependsOnElement: Element,
    dependsOnAttributeConfig: DependsOnConfig
  ) {
    let changeEventType: string;

    if (dependsOnAttributeConfig && dependsOnAttributeConfig?.changeEventType) {
      changeEventType = dependsOnAttributeConfig?.changeEventType;
    } else {
      //backward compatibility
      console.warn(
        'Event type not mapped to dependsOn Element, using default EventType based on element Type '
      );
      changeEventType =
        dependsOnElement.getAttribute('type') === 'radio' ? 'click' : 'change';
    }
    return changeEventType;
  }

  private dependsOnAttributesListener(
    event: Event,
    dependsOnAttributeConfig: DependsOnConfig
  ) {
    // check event validity
    const isValidEvent =
      _.isEmpty(dependsOnAttributeConfig?.changeEventType) || // 1. changeEventTYpe is not defined (old config), assumption is that the event generated is from event listener added based on config
      (dependsOnAttributeConfig?.changeEventType === event.type && //2.1. changeEventType defined (new configuration) and the event generated is same as event added
        (!(event instanceof KeyboardEvent) || //2.2a not a Keyboard Event
          dependsOnAttributeConfig.changeEventTypeParams == event?.key)); //2.2b  its a Keyboard Event and check the key used to trigger the event

    if (!isValidEvent) {
      return;
    }
    // @ts-ignore
    let dependsOnAttribute = dependsOnAttributeConfig.fieldName; //event.target.getAttribute('idClass');
    // @ts-ignore
    this.dependsOnValueMap.set(dependsOnAttribute, event.target?.value);
    this.assimilateAndTrigger();
  }

  private assimilateAndTrigger() {
    // @ts-ignore
    if (this.dependsOnValueMap.size === this._dependsOnConfig?.length) {
      let apiUrlToUse = this._apiUrl;
      this.dependsOnValueMap.forEach((mapValue, key) => {
        apiUrlToUse = apiUrlToUse.replaceAll('{{' + key + '}}', mapValue);
      });
      if (apiUrlToUse) {
        this.http.get(apiUrlToUse).subscribe(
          (res) => {
            console.log('API Response', res, apiUrlToUse);
            if (this._apiDataPath === '*') {
              // * means, extract complete data
              this.valueFromApi = res;
              if (!_.isObject(this.valueFromApi)) {
                this.value = this.valueFromApi;
              }
            } else {
              this.valueFromApi = [
                _.get(res, this._apiDataPath, flatten(res)[this._apiDataPath]),
                ''
              ].find((x) => x !== undefined && x !== null);
              if (!_.isObject(this.valueFromApi)) {
                this.value = this.valueFromApi;
              }
              this.valueChange.emit(this.valueFromApi);
            }
            console.log('Setting value to', this.value, this.valueFromApi);
            if (this.dataKey === '*') {
              if (this._formData) {
                this._formData.submission = { data: this.valueFromApi };
              }
              Object.assign(
                this._formInstance.submission.data,
                this.valueFromApi
              );
              console.log(
                'Before Processing JS: ',
                JSON.stringify(this._formInstance.submission.data),
                JSON.stringify(this.valueFromApi)
              );
              this._formInstance.submission = {
                data: Object.assign({}, this._formInstance.submission.data)
              };
              //this._form.emit("api_data_loaded_patched",{data: this.valueFromApi});
              this.handleValueProcessor(this.valueFromApi);
              console.log('Key: ', this.key);
              this.eventBusService.emit(
                EventData.of(EventType.API_TEXT_FIELD_DATA_LOADED, {
                  data: this.valueFromApi
                })
              );
            } else {
              this.setValueOfComponent(this.valueFromApi, res);
              this.eventBusService.emit(
                EventData.of(EventType.API_TEXT_FIELD_DATA_LOADED, {
                  data: this.valueFromApi
                })
              );
            }
          },
          (error) => {
            console.log(error);
          }
        );
      }
    }
  }

  private setValueOfComponentWithoutDetection(value: any) {
    console.log('setValueOfComponentWithoutDetection', value);
    if (this.dataKey && this._formInstance && !_.isObject(value)) {
      _.set(this._formInstance.submission.data, this.dataKey, value);
      this._formInstance.submission = {
        data: Object.assign({}, this._formInstance.submission.data)
      };
    }
  }

  private setValueOfComponent(value: any, apiResponse: any) {
    this.setValueOfComponentWithoutDetection(value);
    this.handleValueProcessor(apiResponse);
    this.changeDetectorRef.detectChanges();
  }

  private handleValueProcessor(apiResponse: any) {
    if (this.valueProcessor) {
      let valueProcessor = LodashTemplateEvaluator.interpolate(
        this.valueProcessor,
        this._formData
      );
      let data = FormioUtils.evaluate(
        valueProcessor,
        { screenData: this._formInstance.submission, apiResponse: apiResponse },
        'data'
      );

      console.log(data);
      //Reassign so that the dependent widgets updates themselves
      if (data) {
        this._formInstance.submission = { data: Object.assign({}, data) };
      }
      console.log(
        'After processing valueProcessor',
        JSON.stringify(this._formInstance.submission.data)
      );
      this._formData = this._formInstance.submission.data;
    }
  }
}

const flattenNode = (node) => {
  const arr = Array.from(node.children);
  return arr.reduce(flatReducer, []);
};

const flatReducer = (accumulator, currentValue) => {
  return accumulator.concat(
    currentValue.nodeType === Node.ELEMENT_NODE && !!currentValue.children
      ? [].concat(currentValue).concat(flattenNode(currentValue))
      : currentValue
  );
};
