import { Component, DestroyRef, inject, Inject } from '@angular/core';
import { Subscription } from 'rxjs';
import { StorageService } from './shared/services/storage.service';
import { AuthService } from './shared/services/auth.service';
import { EventBusService } from './shared/event-bus.service';
import { PrimeNGConfig } from 'primeng/api';
import { NavigationEnd, Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { EventType } from './shared/model/event.data';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {DEFAULT_INTERRUPTSOURCES, Idle} from "@ng-idle/core";
import {environment} from "../environments/environment";
import {ToastrService} from "ngx-toastr";
import {IamAppConstants} from "./iam/shared/iam-app-constants";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  destroyRef = inject(DestroyRef);

  isLoggedIn = false;
  username?: string;

  eventBusSub?: Subscription;

  // some fields to store our state, so we can display it in the UI
  idleState = "NOT_STARTED";
  countdown?: number;

  constructor(
    private router: Router,
    private primengConfig: PrimeNGConfig,
    private eventBusService: EventBusService,
    @Inject(DOCUMENT) private document: Document,
    private idle: Idle,
    private toastrService: ToastrService
  ) {
    // set idle parameters
    idle.setIdle(environment.idleInactiveTime); // how long can they be inactive before considered idle, in seconds
    idle.setTimeout(environment.idleTimeout); // how long can they be idle before considered timed out, in seconds
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES); // provide sources that will "interrupt" aka provide events indicating the user is active
    // do something when the user becomes idle
    idle.onIdleStart.subscribe(() => {
      this.idleState = "IDLE";
    });
    // do something when the user is no longer idle
    idle.onIdleEnd.subscribe(() => {
      this.idleState = "NOT_IDLE";
      console.log(`${this.idleState} ${new Date()}`)
    });
    // do something when the user has timed out
    idle.onTimeout.subscribe(() => {
      this.idleState = "TIMED_OUT"
      location.href = IamAppConstants.lockScreenUrl;
    });
    // do something as the timeout countdown does its thing
    idle.onTimeoutWarning.subscribe(seconds => {
      this.countdown = seconds
      if(seconds%5 == 0) { // every 5 secs in timeout mode
        this.toastrService.warning(`You have been idle for a while, session will be terminated in ${seconds} secs.<br> Please save  your work before the session timesout`, 'Idle', {
        });
      }

    });

  }
  reset() {
    // we'll call this method when we want to start/reset the idle process
    // reset any component state and be sure to call idle.watch()
    this.idle.watch();
    this.idleState = "NOT_IDLE";
    this.countdown = undefined;
  }

  ngOnInit(): void {
    this.primengConfig.ripple = true;

    this.primengConfig.zIndex = {
      modal: 1100, // dialog, sidebar
      overlay: 1000, // dropdown, overlaypanel
      menu: 1000, // overlay menus
      tooltip: 1100 // tooltip
    };

    this.router.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        const mainDiv = this.document.getElementById('main-bar');
        mainDiv?.scrollTo(0, 0);
      }
    });

    this.eventBusSub = this.eventBusService
      .on(EventType.LOGOUT)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        // this.logout();
      });

    // idle timer
    this.reset();


  }


}
