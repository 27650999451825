import {NgModule} from '@angular/core';
import {ExtraOptions, Route, RouterModule, Routes, UrlMatchResult, UrlSegment, UrlSegmentGroup} from '@angular/router';
import {LandingComponent} from './home/landing/landing.component';
import {AuthGuard} from './shared/guards/auth.guard';
import {AllFormsRendererComponent} from "./portal/application-forms/all-forms-renderer/all-forms-renderer.component";
import {LoginPageComponent} from "./auth/login-page/login-page.component";

const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule)
  },
  {
    matcher: singleColumnLayoutPages,
    component: AllFormsRendererComponent
  },
  {
    path: '',
    canActivate: [AuthGuard],
    component: LandingComponent,
    loadChildren: () =>
      import('./portal/portal.module').then((m) => m.PortalModule)
  },
  {
    path: 'db-login',
    component: LoginPageComponent
    // loadChildren: () => import('./iam/iam.module').then((m) => m.IamModule)
  },
  {
    path: '**',
    redirectTo: '/not-found',
    pathMatch: 'full'
  }
];

const extraOptions: ExtraOptions = {
  enableTracing: false,
  scrollPositionRestoration: 'enabled'
};

@NgModule({
  imports: [RouterModule.forRoot(routes, extraOptions)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}


export function singleColumnLayoutPages(url: UrlSegment[], group: UrlSegmentGroup, route: Route) {
  let matchedUrl: UrlMatchResult | null = null;
  const allowedPrefixes = ['public', 'guest', 'general'];

  if (url.length >= 3 && allowedPrefixes.includes(`${url[0]}`)) {
    matchedUrl = {
      consumed: url,
      posParams: {
        "slug": url[1],
        "formId": url[2]
      }
    }
    if (url.length > 3) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      matchedUrl.posParams["id"] = url[4];
    }
    route.data = { layout : 'single'};
  }
  return matchedUrl;
}


