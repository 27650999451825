import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import 'rxjs/operators';
import { PagedData } from '../model/paged-data';
import * as _ from 'lodash';
import { map } from 'rxjs/operators';

export class BaseRestQueryService<T> {
  private defaultConfig = {
    pageNumber: 1,
    pageSize: 1000
    // Add more default options as needed
  };

  constructor(
    protected http: HttpClient,
    protected restUrl: string
  ) {
    this.http = http;
  }

  getAll(sortBy: string, customConfig?: any): Observable<PagedData<T>> {
    const config = { ...this.defaultConfig, ...customConfig };
    return this.http
      .get<PagedData<T>>(this.restUrl, {
        params: new HttpParams()
          .set('sort', sortBy)
          .set('pageNumber', config.pageNumber)
          .set('pageSize', config.pageSize)
      })
      .pipe(map((res) => res));
  }

  getPaginatedList(
    sortBy: string,
    pageNumber: number,
    pageSize: number
  ): Observable<PagedData<T>> {
    return this.http
      .get<PagedData<T>>(this.restUrl, {
        params: new HttpParams()
          .set('sort', sortBy)
          .set('pageNumber', pageNumber + 1 + '')
          .set('pageSize', pageSize + '')
      })
      .pipe(map((res) => res));
  }

  search(
    q: string,
    sortBy = 'asc',
    pageNumber = 0,
    pageSize = 1000
  ): Observable<PagedData<T>> {
    return this.http
      .get<PagedData<T>>(this.restUrl, {
        params: new HttpParams()
          .set('q', q)
          .set('sort', sortBy)
          .set('page', pageNumber + 1 + '')
          .set('size', pageSize + '')
      })
      .pipe(map((res) => res));
  }

  getPaginatedListWithQueryCriterion(
    key: string,
    value: any,
    operator: string,
    sortBy: string,
    pageNumber: number,
    pageSize: number
  ): Observable<PagedData<T>> {
    let params = new HttpParams();
    if (!_.isEmpty(key)) {
      params = params.set('q', key.concat(operator, value));
    }
    if (!_.isEmpty(sortBy)) {
      params = params.set('sort', sortBy);
    }
    params = params.set('page', pageNumber + 1 + '');
    params = params.set('size', pageSize + '');
    return this.http
      .get<PagedData<T>>(this.restUrl, {
        params: params
      })
      .pipe(map((res) => res));
  }

  getWithQueryCriterion(
    key: string,
    value: any,
    operator: string,
    sortBy: string
  ): Observable<PagedData<T>> {
    let params = new HttpParams();
    if (!_.isEmpty(key)) {
      params = params.set('q', key.concat(operator, value));
    }
    if (!_.isEmpty(sortBy)) {
      params = params.set('sort', sortBy);
    }
    return this.http
      .get<PagedData<T>>(this.restUrl, {
        params: params
      })
      .pipe(map((res) => res));
  }

  getById(id: any): Observable<T> {
    return this.http
      .get<T>(this.restUrl + '/' + id, {})
      .pipe(map((res) => res));
  }

  getRestUrl(): Observable<T> {
    return this.http.get<T>(this.restUrl, {}).pipe(map((res) => res));
  }

  getByMultipleIds(
    key1: string,
    value1: any,
    operator: string,
    sortBy: string,
    pageNumber: number,
    pageSize: number
  ): Observable<PagedData<T>> {
    return this.http
      .get<PagedData<T>>(this.restUrl, {
        params: new HttpParams()
          .set('id', value1)
          .set('sort', sortBy)
          .set('page', pageNumber + 1 + '')
          .set('size', pageSize + '')
      })
      .pipe(map((res) => res));
  }

  getPaginatedListWithMultipleQueryCriterion(
    query: string,
    sortBy: string,
    pageNumber: number,
    pageSize: number
  ): Observable<PagedData<T>> {
    return this.http
      .get<PagedData<T>>(this.restUrl, {
        params: new HttpParams()
          .set('q', query)
          .set('sort', sortBy)
          .set('page', pageNumber + 1 + '')
          .set('size', pageSize + '')
      })
      .pipe(map((res) => res));
  }
}
