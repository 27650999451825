import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseRestService } from './base-rest.service';
import { MenuItem } from 'primeng/api';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class MenuService extends BaseRestService<MenuItem> {
  constructor(private httpClient: HttpClient) {
    super(httpClient, `${environment.iamBaseApi}`);
  }

  loadSidebarMenuItems(): Observable<MenuItem[]> {
    return this.httpClient
      .get<MenuItem[]>(this.restUrl + `/menu`)
      .pipe(map((res) => res));
  }

  public getBreadCrumbHierarchyFromCurrentUrl(
    sidebarMenuItems: MenuItem[],
    urlPath: string
  ): MenuItem[] {
    const menuItemPathFromRoot = this.findMenuItemPath(
      sidebarMenuItems,
      urlPath
    );
    return menuItemPathFromRoot;
  }

  public findMenuItemPath(
    menuItems: MenuItem[],
    targeturl: string,
    currentPath: MenuItem[] = []
  ): MenuItem[] {
    for (const menuItem of menuItems) {
      const path = [...currentPath, menuItem];
      // console.log(`targeturl => ${targeturl} routerLink => ${menuItem.routerLink}`)
      // console.log("targeturl.includes(menuItem.routerLink) ?" + targeturl.includes(menuItem.routerLink))
      if (targeturl.includes(menuItem.routerLink)) {
        return path;
      }

      if (menuItem.items && menuItem.items.length > 0) {
        const foundPath = this.findMenuItemPath(
          menuItem.items,
          targeturl,
          path
        );
        if (foundPath) {
          return [...currentPath, ...foundPath];
        }
      }
    }
    return [];
  }
}
