import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

function showInternal() {
// Cast to HTMLElement
  (document.querySelector(".loader-div") as HTMLElement).style.display = "block";
}

function hideInternal() {
  // Cast to HTMLElement
  (document.querySelector(".loader-div") as HTMLElement).style.display = "none";


}


@Injectable()
export class LoaderService {
  isLoading = new Subject<boolean>();
  show() {
    this.isLoading.next(true);
    showInternal();
  }
  hide() {
    hideInternal();
    this.isLoading.next(false);
  }
}
