import {
  CustomButtonActionHandler,
  CustomButtonActionHandlerContext
} from '@shared/components/custom-action-button/handlers/custom-button-action-handler';
import { CustomButtonActionType } from '@shared/components/custom-action-button/handlers/custom-button-action-handler-registry';
import { Injectable } from '@angular/core';
import {Location} from "@angular/common";

@Injectable({ providedIn: 'root' })
export class GoBackActionProcessor
  implements CustomButtonActionHandler
{

  constructor(private location: Location) { }

  goBack() {
    this.location.back();
  }
  isApplicable(actionType: CustomButtonActionType): boolean {
    return actionType === CustomButtonActionType.goBack;
  }

  async performAction(context: CustomButtonActionHandlerContext) {
    return new Promise((resolve, reject) => {
      this.goBack();
    });
  }



}
