import * as _ from 'lodash';
import {IamAppConstants} from '../../iam/shared/iam-app-constants';
import {UserIdentity} from "../../iam/model/user-identity";

export class IamAuthUtils {
  private static cachedIdentityDetails: UserIdentity;

  public static isLoggedIn(): boolean {
    return (
      !_.isNull(this.getCurrentUserToken()) &&
      !_.isEmpty(this.getCurrentUserToken())
    );
  }

  public static getCurrentUserToken(): string | null {
    return localStorage.getItem(IamAppConstants.token);
  }

  public static doesUserHasRoles(): boolean | null {
    return !_.isEmpty(_.get(IamAuthUtils.getCurrentUserIdentityDetails(), "roles"));
  }

  public static doesUserHasAcls(): boolean | null {
    return  !_.isEmpty(_.get(IamAuthUtils.getCurrentUserIdentityDetails(), "acls"));
  }

  public static hasPrivilegedAcls(): boolean | null {
    return _.get(IamAuthUtils.getCurrentUserIdentityDetails(), "privileged") ? true : false;
  }

  public static getPreference(key: string): any {
    return IamAuthUtils.getCurrentUserIdentityDetails().privileged;
  }

  public static getCurrentUserIdentityDetails(): UserIdentity {
    // performance improvement fix for UI responding slow. IFAS-2518
   // Use cached value if available
    if (this.cachedIdentityDetails) {
      return this.cachedIdentityDetails;
    }

    const identityDetailsJson = localStorage.getItem(IamAppConstants.current_user_identity_details);
    if (!identityDetailsJson) {
      console.error("Current user identity details not found in local storage.");
      return <any>null;
    }

    // Parse and cache the value
    this.cachedIdentityDetails = JSON.parse(identityDetailsJson);
    return this.cachedIdentityDetails;
  }

  static isCurrentUserIdentityDetailsPresent() {
    let isPresent = false;
    try {

      const item = localStorage.getItem(IamAppConstants.current_user_identity_details);
      const data = JSON.parse(
        <string>item
      );
      isPresent = item && data;
    } catch (e) {
      // console.error("issue with identityDetails")
    }
    return isPresent;
  }

  public static getCurrentUserAcls(): any[] {
    return _.get(this.getCurrentUserIdentityDetails(), IamAppConstants.acls);
  }

  public static getCurrentUserRoles(): any[] {
    return _.get(this.getCurrentUserIdentityDetails(), IamAppConstants.roles);
  }

  public static getCurrentUserDetails() {
    return JSON.parse(
      <string>localStorage.getItem(IamAppConstants.current_user_profile_details)
    );
  }

  public static getCurrentLoggedInUserId() {
    return _.get(this.getCurrentUserDetails(), 'id');
  }

  public static getCurrentLoggedUserIamId() {
    return _.get(_.get(this.getCurrentUserIdentityDetails(), 'identity'), 'id');
  }


  public static clearUserCredentials() {
    console.warn('Clearing the Local storage for user Credentials');
    localStorage.removeItem(IamAppConstants.token);
    localStorage.removeItem(IamAppConstants.current_user_identity_details);
    localStorage.removeItem(IamAppConstants.current_user_profile_details);
    this.cachedIdentityDetails = <any>null;
  }

  static addTokenToCookie(token: any) {
    console.log("adding token to cookie")
    window["createCookie"]("token", token, 1);

  }
}
