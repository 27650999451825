import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { LandingComponent } from './landing/landing.component';
import { HomeRoutingModule } from './home-routing.module';
import { SidebarModule } from 'primeng/sidebar';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { PanelMenuModule } from 'primeng/panelmenu';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { SharedModule } from '../shared/shared.module';
import { ToastModule } from 'primeng/toast';
import { MyDashboardComponent } from './my-dashboard/my-dashboard.component';
import {ApplicationFormsModule} from "../portal/application-forms/application-forms.module";

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    LandingComponent,
    MyDashboardComponent
  ],
    imports: [
        CommonModule,
        SharedModule,
        HomeRoutingModule,
        SidebarModule,
        FontAwesomeModule,
        PanelMenuModule,
        BreadcrumbModule,
        ToastModule,
        SharedModule,
        ApplicationFormsModule
    ],
  exports: [HeaderComponent, FooterComponent, SidebarComponent]
})
export class HomeModule {}
