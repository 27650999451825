import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AllFormsRendererComponent } from './all-forms-renderer/all-forms-renderer.component';

const routes: Routes = [
  { path: ':slug/:formId/:id', component: AllFormsRendererComponent },
  { path: ':slug/:formId', component: AllFormsRendererComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ApplicationFormsRoutingModule {}
