import {ChangeDetectorRef, Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import {MenuItem, PrimeNGConfig} from "primeng/api";
import {Router} from '@angular/router';
import * as _ from 'lodash'
import {DOCUMENT} from "@angular/common";
import {MenuService} from "../../shared/services/menu.service";
import {MenuItemCommandEvent} from "primeng/api/menuitem";
import {environment} from "../../../environments/environment";
import {UIUtils} from "@shared/utils/ui-utils";

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  sidebarMenuItems: MenuItem[] = [];
  isSideBarVisible = true;

  sidebar: HTMLElement;

  @Output() menuItems: EventEmitter<MenuItem[]> = new EventEmitter<MenuItem[]>();

  constructor(private primengConfig: PrimeNGConfig,
              private router: Router,
              private cd: ChangeDetectorRef,
              private menuService: MenuService,
              @Inject(DOCUMENT) private document: Document) {


    this.sidebarMenuItems = [];
    /*    this.subscriptions = this.router.events.subscribe((event: Event) => {
          if (event instanceof NavigationStart) {
            // Show loading indicator
          }

          if (event instanceof NavigationEnd) {
            // Hide loading indicator
            this.updateMenu(event);
            this.toggleNavigation();
          }

          if (event instanceof NavigationError) {
            // Hide loading indicator

            // Present error to user
          }
        });*/

  }

  ngOnInit(): void {
    // this.navStart.subscribe(evt => this.updateMenu(evt));
    this.primengConfig.ripple = true;
    this.loadMenuItems();
  }

  // Recursive function to set "expanded" property
  configureMenu(menuItems: MenuItem[], parentMenuItem: MenuItem | null): void {
    menuItems.forEach(item => {
      if (item.items && item.items.length > 0) {
        this.configureMenuItem(item);
        //   item.expanded = this.doesAnyChildRouteMatch(item.items, this.router.routerState.snapshot.url); // Set the "expanded" property based on your logic
        this.configureMenu(item.items, item); // Recurse for sub-items
        // return !item.expanded; // stop the foreach look, as itemExpanded found
      } else {
        item['command'] = this.getMenuActionCommand();
      }
    });
  }

  private loadMenuItems() {
    this.menuService.loadSidebarMenuItems().subscribe(data => {

      let menuItems = _.clone(data);

      this.configureMenu(menuItems, null);

      this.sidebarMenuItems = menuItems;
      this.menuItems.emit(this.sidebarMenuItems);

    });
  }

  protected configureMenuItem(menuItem: MenuItem) {

    if (_.get(menuItem, "state.isBuilderMenuItem") && !environment.enableBuilderMenu()) {
      menuItem.visible = false;
    }

  }

  private getMenuActionCommand() {
    return (event: MenuItemCommandEvent) => {
      console.log("Menu Item Command => ", event);
      this.menuItems.emit(this.sidebarMenuItems);
    };
  }

  private doesAnyChildRouteMatch(menuItems: MenuItem[], url: string) {
    menuItems.forEach(item => {
      if (item.items && item.items.length > 0) {
        item.expanded = this.doesAnyChildRouteMatch(item.items, url); // Set the "expanded" property based on your logic
        // this.setExpanded(item.items); // Recurse for sub-items
      }
    });
    return false;
  }

  public toggleSidebar() {
    UIUtils.toggleSidebar();
  }
}



