import {
  CustomButtonActionHandler,
  CustomButtonActionHandlerContext
} from '@shared/components/custom-action-button/handlers/custom-button-action-handler';
import { CustomButtonActionType } from '@shared/components/custom-action-button/handlers/custom-button-action-handler-registry';
import { Injectable } from '@angular/core';
import { LodashTemplateEvaluator } from '@shared/components/smart-table/LodashTemplateEvaluator';
import { Utils as FormioUtils } from '@evi-ui/js';

@Injectable({ providedIn: 'root' })
export class ExecuteCustomLogicActionProcessor
  implements CustomButtonActionHandler
{
  isApplicable(actionType: CustomButtonActionType): boolean {
    return actionType === CustomButtonActionType.executeCustomLogic;
  }

  async performAction(context: CustomButtonActionHandlerContext) {
    let actionProcessor = context.actionProcessor;
    const actionValue = LodashTemplateEvaluator.interpolate(
      actionProcessor.customLogicConfig.scriptToExecute,
      context.formInstance.submission
    );
    const evaluationResult = FormioUtils.evaluate(
      actionValue,
      {
        submission: context.formInstance.submission,
        data: context.formInstance.submission.data,
        formInstance: context.formInstance
      },
      'value',
      true
    );

    return new Promise((resolve, reject) => {
      resolve(evaluationResult);
    });
  }
}
