import { Component, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../../portal/user-management/user/user.service';
import { StorageService } from '../../shared/services/storage.service';
import { AuthService } from '../../shared/services/auth.service';
import { DOCUMENT } from '@angular/common';
import { analytics } from '../../../environments/environment';
import { embedDashboard } from '@superset-ui/embedded-sdk';

@Component({
  selector: 'app-my-dashboard',
  templateUrl: './my-dashboard.component.html'
})
export class MyDashboardComponent {
  origin = '';
  private hostname: string;
  private userId: any;
  redirectUrl = '';
  code: string;

  constructor(
    private authService: AuthService,
    @Inject(DOCUMENT) private document: Document,
    private storageService: StorageService,
    private activatedRoute: ActivatedRoute,
    public router: Router,
    private userService: UserService
  ) {
    this.hostname = document.location.hostname;
  }

  ngOnInit(): void {
    const queryParams = this.activatedRoute.snapshot.queryParams;
    this.code = queryParams['code'];
    this.displayAnalytics();
  }

  private displayAnalytics(): any {
    this.authService.getGuestToken().subscribe(
      (data) => {
        const element = document.getElementById('analytics-container');
        if (element != null) {
          embedDashboard({
            id: data.embeddedDashboardId,
            supersetDomain: `${analytics.analyticsDomainUrl()}`,
            mountPoint: element,
            fetchGuestToken: () => data.guestToken,
            dashboardUiConfig: { hideTitle: true }
          });
        }
      },
      (error) => console.log('error: ', error)
    );
  }

  redirectToAnalytics() {
    this.authService
      .getAppRedirectToken(
        `${analytics.analyticsSourceApp}`,
        `${analytics.analyticsTargetApp}`
      )
      .subscribe((data) => {
        if (data) {
          const analyticsUrl = `${analytics.analyticsDashboardUrl()}`;
          window.location.href = analyticsUrl + data.token;
        }
      });
  }

  goToAnalytics() {
    this.redirectToAnalytics();
  }
}
