<!-- Start of Footer Block -->

<footer class="jds-footer">
  <div class="container">
    <div class="top-section sitemap">
      <h2 class="jds-footer-sitemap-header text-start">
        <img src="/assets/images/sg-courts-logo-white.svg " alt=" " />
      </h2>
      <!-- <div class=" footer-links ">
         <div class=" row ">
           <div class=" col-md-3 ">
             <h4>Main link one</h4>
             <ul>
               <li><a href=" # ">Link 1</a></li>
               <li><a href=" # ">Link 2</a></li>
               <li><a href=" # ">Link 3</a></li>
               <li><a href=" # ">Link 4</a></li>
               <li><a href=" # ">Link 5</a></li>
             </ul>
           </div>
           <div class=" col-md-6 ">
             <h4>Main link two</h4>
             <ul class=" columns ">
               <li><a href=" # ">Link 1</a></li>
               <li><a href=" # ">Link 2</a></li>
               <li><a href=" # ">Link 3</a></li>
               <li><a href=" # ">Link 4</a></li>
               <li><a href=" # ">Link 5</a></li>
               <li><a href=" # ">Link 6</a></li>
               <li><a href=" # ">Link 7</a></li>
               <li><a href=" # ">Link 8</a></li>
               <li><a href=" # ">Link 9</a></li>
               <li><a href=" # ">Link 10</a></li>
             </ul>
           </div>
           <div class=" col-md-3 ">
             <h4>Main link three</h4>
             <ul>
               <li><a href=" # ">Link 1</a></li>
               <li><a href=" # ">Link 2</a></li>
               <li><a href=" # ">Link 3</a></li>
               <li><a href=" # ">Link 4</a></li>
               <li><a href=" # ">Link 5</a></li>
             </ul>
           </div>
         </div>
       </div>-->
    </div>
    <hr class="divider" />
    <div class="bottom-section">
      <div class="row">
        <div class="col-lg-9">
          <div class="footer-links">
            <a
              href=" https://tech.gov.sg/report_vulnerability "
              target=" _blank "
              >Report Vulnerability <i class="fal fa-external-link ms-1"></i
            ></a>
            <a [routerLink]="['/app/pages/privacy-statement']"
              >Privacy Statement</a
            >
            <a [routerLink]="['/app/pages/terms-and-conditions']"
              >Terms of Use
            </a>
            <a href=" https://www.reach.gov.sg/ " target=" _blank "
              >Reach.gov.sg <i class="fal fa-external-link ms-1"></i
            ></a>
          </div>
          <!-- <div class=" powered-by mt-3 ">Powered by <img alt=" " src="/assets/images/cl-logo.png "></div>-->
        </div>
        <div class="col-lg-3 mobile-mt-3 copyright text-right">
          <div>© 2023 Government of Singapore</div>
        </div>
      </div>
    </div>
  </div>
</footer>
<!-- End of Footer Block -->
