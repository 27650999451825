import { Injector } from '@angular/core';
import {
  FormioCustomComponentInfo,
  registerCustomFormioComponent
} from '@evi-ui/angular';
import { SmartTableComponent } from './smart-table.component';
import {
  API_DATA_DRIVEN,
  CONTEXT_DATA_DRIVEN,
  GridColumnDefinition,
  mergeGridColumnDefinitionsWithDefaults
} from './smart-table.model';
import * as _ from 'lodash';
import { Formio } from '@evi-ui/js';
import { IamAuthUtils } from '../../utils/iam-auth-utils';
import { decodeData } from '../../../portal/forms/form.service';

const COMPONENT_OPTIONS: FormioCustomComponentInfo = {
  type: 'smarttable', // custom type. Formio will identify the field with this type.
  selector: 'smart-table', // custom selector. Angular Elements will create a custom html tag with this selector
  title: 'Smart Table', // Title of the component
  group: 'basic', // Build Group
  icon: 'fa fa-table', // Icon
  editForm: editForm,
  weight: 0
};

export const ignoreComponentTypesInImport = ['button', 'checkbox'];

function getColumnDefinitionComponents() {
  return {
    components: [
      {
        key: 'headerName',
        type: 'textfield',
        label: 'Header Text',
        input: true,
        tooltip: 'Column Header Text',
        weight: 702
      },
      {
        key: 'field',
        type: 'textfield',
        label: 'API Field',
        input: true,
        tooltip: 'Field from API to be used for this column',
        weight: 702
      },
      {
        key: 'headerToolTip',
        type: 'textfield',
        label: 'Header Tool Tip',
        input: true,
        tooltip: 'Field from API to be used for this column',
        weight: 702
      },
      {
        key: 'headerClass',
        type: 'textfield',
        label: 'Header Class',
        input: true,
        tooltip: '',
        weight: 702
      },
      {
        key: 'thStyle',
        type: 'textfield',
        label: 'Cell Header Style',
        input: true,
        tooltip: '',
        weight: 702
      },
      {
        key: 'tdStyle',
        type: 'textfield',
        label: 'Cell Data Style',
        input: true,
        tooltip: '',
        weight: 702
      },
      {
        key: 'cellDataType',
        type: 'select',
        label: 'Data Type',
        dataSrc: 'values',
        data: {
          values: [
            {
              label: 'String',
              value: 'string'
            },
            {
              label: 'Numeric',
              value: 'numeric'
            },
            {
              label: 'Decimal',
              value: 'decimal'
            },
            {
              label: 'Boolean',
              value: 'boolean'
            },
            {
              label: 'Date',
              value: 'date'
            },
            {
              label: 'Currency',
              value: 'currency'
            }
          ]
        },
        defaultValue: 'string',
        input: true,
        tooltip: 'Date supports Date, DateTime, Time component',
        description: 'Date supports Date, DateTime, Time component',
        weight: 702
      },
      {
        key: 'formatOptions.currency',
        type: 'textfield',
        label: 'Currency Symbol',
        input: true,
        tooltip:
          'Will be used as currency symbol, if specified. If not specified, then default from locale will be used.',
        weight: 702,
        customConditional(context) {
          return context.row.cellDataType === 'currency';
        }
      },
      {
        key: 'formatOptions.enableDateFormatter',
        type: 'checkbox',
        label: 'Enable Date Formatting',
        input: true,
        defaultValue: false,
        weight: 702,
        customConditional(context) {
          return context.row.cellDataType === 'date';
        }
      },
      {
        key: 'formatOptions.sourceFormat',
        type: 'textfield',
        label: 'Source(API Data) Date Format',
        input: true,
        defaultValue: 'YYYY-MM-DD HH:mm:ss',
        description: 'moment js formatting options',
        weight: 702,
        customConditional(context) {
          return context.row.formatOptions.enableDateFormatter === true;
        }
      },
      {
        key: 'formatOptions.targetFormat',
        type: 'textfield',
        label: 'Display Date Format',
        defaultValue: 'DD-MM-YYYY',
        description: 'moment js formatting options',
        input: true,
        weight: 702,
        customConditional(context) {
          return context.row.formatOptions.enableDateFormatter === true;
        }
      },
      {
        key: 'hide',
        type: 'checkbox',
        label: 'Is Hidden??',
        input: true,
        tooltip: '',
        weight: 702
      },
      {
        key: 'sortable',
        type: 'checkbox',
        label: 'Sortable?',
        input: true,
        tooltip: '',
        weight: 702
      },
      {
        key: 'sortField',
        type: 'textfield',
        label: 'Sort Field',
        input: true,
        description:
          'Field from API to be used for this column for Sorting, It is mandatory in case of Composite Column when sorting is enabled',
        weight: 703,
        defaultValue: undefined,
        customConditional(context) {
          return context.row.sortable === true;
        }
      },
      {
        key: 'filterable',
        type: 'checkbox',
        label: 'Filterable?',
        input: true,
        tooltip: '',
        weight: 702
      },
      {
        key: 'resizable',
        type: 'checkbox',
        label: 'Resizable?',
        description: `<b style='color:red'>Deprecated</b>: Column level resizing is not supported anymore, Instead use Table level (all columns) settings`,
        input: true,
        tooltip: '',
        weight: 702
      },
      {
        key: 'compositeColumn',
        type: 'checkbox',
        label: 'Composite Column?',
        input: true,
        tooltip: '',
        weight: 702,
        defaultValue: false
      },
      {
        weight: 703,
        type: 'textarea',
        input: true,
        key: 'valueFormatterTemplate',
        label: 'Value Formatter',
        placeholder: 'lodash template code',
        tooltip: `write lodash template code which is evaluated to compute the cell value. The Value Formatter is mandatory for Composite Column
                        Context variables are
                        rowData -  complete row data json object
                        gridOptions - the Grid Configuration`,
        editor: 'ace',
        as: 'handlebars',
        wysiwyg: {
          minLines: 8
        },
        defaultValue: undefined
      },
      {
        key: 'displayValueFormatters',
        type: 'editgrid',
        label: 'Display Value Formatters',
        input: true,
        tooltip: '',
        weight: 703,
        addAnother: 'Add Formatter',
        saveRow: 'Save',
        tableView: false,
        components: [
          {
            key: 'displayType',
            type: 'select',
            label: 'Display As',
            dataSrc: 'values',
            data: {
              values: [
                {
                  label: 'Button',
                  value: 'button'
                },
                {
                  label: 'Href',
                  value: 'href'
                },
                {
                  label: 'HTML',
                  value: 'html'
                }
              ]
            },
            defaultValue: undefined,
            input: true,
            tooltip:
              'Button and Href are considered as action elements and associated with actions. To display just formatting with markup without any actions, use HTML',
            weight: 702
          },
          {
            key: 'styleClass',
            type: 'textfield',
            label: 'Style Class',
            input: true,
            tooltip: 'Styles applied on Element',
            description: `Styles applied on Element<br>
                          Button - <b>pButton</b> tag - Refer https://primeng.org/button
                          href - <b>a</b> tag <br>
                          html - <b>div</b> tag`,
            weight: 1
          },
          {
            key: 'iconClass',
            type: 'textfield',
            label: 'Icon Class',
            input: true,
            tooltip:
              'The FontAwesome icons to use. can be used only with button or href display types',
            weight: 1
          },
          {
            key: 'conditionallyDisable',
            weight: 200,
            type: 'textarea',
            input: true,
            editor: 'ace',
            as: 'javascript',
            wysiwyg: {
              minLines: 4
            },
            defaultValue: undefined,
            label: 'Conditionally Disable',
            tooltip:
              'Javascript to conditionally disable the button. Return value as true to disable the button.',
            customConditional(context) {
              return context.row.displayType === 'button';
            }
          },
          {
            key: 'toolTipWhenDisabled',
            type: 'textfield',
            label: 'Disabled Icon Tool Tip',
            input: true,
            tooltip:
              'Tooltip Text to be displayed on action elements when they are in disabled mode. Will be used only if configured.',
            weight: 2
          },
          {
            key: 'toolTip',
            type: 'textfield',
            label: 'Icon Tool Tip',
            input: true,
            tooltip: 'Tooltip Text to be displayed on action elements',
            weight: 2
          },
          {
            weight: 703,
            type: 'textarea',
            input: true,
            key: 'displayValueFormatterTemplate',
            label: 'Display Value Formatter',
            placeholder: 'lodash template code',
            tooltip: `write lodash template code which is evaluated to display the cell content.
                                    Context variables are
                                    rowData -  complete row data json object
                                    data - cell data raw value. In case of composite columns its the computed value from value Formatter
                                    gridOptions - the Grid Configuration`,
            editor: 'ace',
            as: 'handlebars',
            wysiwyg: {
              minLines: 8
            },
            defaultValue: undefined
          },
          {
            key: 'category',
            type: 'select',
            label: 'Action Category',
            dataSrc: 'values',
            data: {
              values: [
                {
                  label: 'Show Popup',
                  value: 'popup'
                },
                {
                  label: 'Navigation',
                  value: 'navigation'
                },
                {
                  label: 'Download File',
                  value: 'download'
                },
                {
                  label: 'No Action',
                  value: 'no_action'
                },
                {
                  label: 'Edit Row',
                  value: 'edit_row'
                },
                {
                  label: 'Delete Row',
                  value: 'delete_row'
                }
              ]
            },
            defaultValue: 'no_action',
            input: true,
            tooltip: '',
            weight: 3
          },
          {
            key: 'stateToPropagateEvaluator',
            weight: 200,
            type: 'textarea',
            input: true,
            editor: 'ace',
            as: 'javascript',
            wysiwyg: {
              minLines: 12
            },
            defaultValue: undefined,
            label: 'State To Propagate Evaluator',
            customConditional(context) {
              return context.row.category === 'navigation';
            }
          },

          {
            key: 'aclResource',
            type: 'textfield',
            label: 'ACL Key',
            weight: 5,
            placeholder: 'ACL Key',
            input: true
          },
          {
            weight: 703,
            type: 'textarea',
            input: true,
            key: 'actionValueFormatterTemplate',
            label: 'Action Value Formatter',
            description: `This Action value serves as input to the Action handler, for the actions selected in the Action Category.<br>
                          Navigation - the Action url including the context path. Ex : /app/court-fines-create/64e4677a714d64688bea42f1/{{rowData.id}}<br>
                          Download - the download Id / URL (TBD) Ex : /app/documents/download/{{rowData.id}}<br>
                          Popup - the Popup refId (TBD)<br>
                          `,
            placeholder: 'lodash template code',
            tooltip: `write lodash template code which is evaluated to give the input for action.
                                    Context variables are
                                    rowData -  complete row data json object
                                    submissionData - the whole form submission data in current context (experimental)
                                    data - cell data raw value. In case of composite columns its the computed value from Value Formatter
                                    gridOptions - the Grid Configuration`,
            editor: 'ace',
            as: 'handlebars',
            wysiwyg: {
              minLines: 8
            },
            defaultValue: undefined
          }
        ]
      }
    ]
  };
}

function getColumnDefinitions() {
  return {
    label: 'Columns Definitions',
    key: 'header',
    weight: 70,
    components: [
      {
        key: 'customOptions.gridOptions.columnDefinitions',
        type: 'editgrid',
        label: 'Column Metadata',
        input: true,
        tooltip: '',
        weight: 702,
        addAnother: 'Add Column',
        saveRow: 'Save',
        tableView: false,
        reorder: true,
        inlineEdit: false,
        modal: true,
        displayAsTable: true,
        ...getColumnDefinitionComponents()
      },

      {
        key: 'customOptions.gridOptions.needsRowExpansion',
        type: 'checkbox',
        label: 'Needs Row Expansion',
        input: true,
        tooltip: '',
        weight: 702
      },
      {
        type: 'panel',
        key: 'Expansion Row Columns',
        label: 'Expansion Row Columns',
        title: 'Expansion Row Columns',
        collapsible: true,
        clearOnHide: false,
        customConditional(context) {
          return (
            context.data.customOptions.gridOptions.needsRowExpansion === true
          );
        },
        components: [
          {
            key: 'customOptions.expansionRowGridOptions.childRowsAttribute',
            type: 'textfield',
            label: 'Attribute name for child rows',
            input: true,
            tooltip: '',
            defaultValue: 'content',
            clearOnHide: false,
            weight: 702
          },
          {
            key: 'customOptions.expansionRowGridOptions.selectedRecordIdentifierField',
            type: 'textfield',
            label: 'Record Identifier Field',
            input: true,
            placeholder: '',
            description:
              'field name that uniquely identifies the selected record',
            defaultValue: 'id',
            clearOnHide: false,
            weight: 702
          },
          {
            key: 'customOptions.expansionRowGridOptions.rowHover',
            type: 'checkbox',
            label: 'Hover',
            input: true,
            tooltip: 'Highlight a row on hover.',
            defaultValue: false,
            clearOnHide: false,
            weight: 703
          },
          {
            key: 'customOptions.expansionRowGridOptions.styleClass',
            weight: 0,
            type: 'textfield',
            input: true,
            label: 'Style Class',
            placeholder: 'Style class for gridlines, striped etc',
            defaultValue: 'p-datatable-striped p-datatable-sm',
            description: `few style classes <b>p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-lg</b>`,
            tooltip: 'The label used as heading of the table.',
            clearOnHide: false
          },
          {
            key: 'customOptions.expansionRowGridOptions.rowSpanCount',
            weight: 0,
            type: 'textfield',
            input: true,
            label: 'Row Span',
            description: `No of rows the Child Table width to span, if a value is given then the Span value will not be auto calculated. Default value is null `,
            clearOnHide: false
          },
          {
            key: 'customOptions.expansionRowGridOptions.slnoColumnRequired',
            type: 'checkbox',
            label: 'Sl No Column required?',
            input: true,
            tooltip: 'Will be the First Column',
            defaultValue: true,
            weight: 702
          },

          {
            key: 'customOptions.expansionRowGridOptions.slnoLabel',
            type: 'textfield',
            label: 'S No - Column Label',
            input: true,
            tooltip: '',
            defaultValue: 'S No',
            weight: 702,
            customConditional(context) {
              return (
                context.data.customOptions.gridOptions.slnoColumnRequired ===
                true
              );
            }
          },
          {
            key: 'customOptions.expansionRowGridOptions.columnDefinitions',
            type: 'editgrid',
            label: 'Column Metadata',
            input: true,
            tooltip: '',
            weight: 702,
            addAnother: 'Add Column',
            saveRow: 'Save',
            tableView: false,
            reorder: true,
            inlineEdit: false,
            modal: true,
            displayAsTable: true,
            clearOnHide: false,
            ...getColumnDefinitionComponents()
          }
        ]
      },

      {
        type: 'panel',
        key: 'Default Sort',
        label: 'Default Sort Details',
        title: 'Default Sort Details',
        collapsible: true,
        components: [
          {
            key: 'customOptions.gridOptions.defaultSort',
            type: 'textfield',
            label: 'Default Sort Options',
            description: `Sort API Field, Sort Direction <br>
                                Example :  <br>lastModifiedDate,desc <br>
                                            <br>person.name,asc <br>`,

            input: true,
            tooltip: '',
            weight: 702
          },
          {
            key: 'customOptions.gridOptions.noDataLabelStyleClass',
            type: 'textfield',
            label: 'StyleClass to apply for No data Label',
            input: true,
            tooltip: '',
            weight: 702
          }
        ]
      }
    ]
  };
}

export function componentTypeToGridColumnDatatypeMapper(componentType) {
  let gridColumnType;
  switch (componentType) {
    case 'currency':
      gridColumnType = 'currency';
      break;

    case 'textfield':
    case 'textarea':
    case 'select':
      gridColumnType = 'string';
      break;

    case 'number':
      gridColumnType = 'number';
      break;

    case 'datetime':
      gridColumnType = 'date';
      break;

    default:
      gridColumnType = 'string';
  }
  return gridColumnType;
}

function loadFormDefinition(context) {
  const selectedFormId = _.get(
    context.data,
    'customOptions.gridOptions.importFormId'
  );
  console.log(`selectedFormId ${selectedFormId}`);
  const urlToFetch = `/api/ignite/application/${selectedFormId}`;

  Formio.fetch(urlToFetch, {
    headers: {
      'content-type': 'application/json',
      Authorization: 'Bearer ' + IamAuthUtils.getCurrentUserToken()
    }
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      return response.json(); // Parse the JSON response
    })
    .then((data) => {
      // Handle the data returned from the API
      const form = decodeData(data);
      const componentSchemaDataJson =
        form &&
        form.components &&
        form.components.length > 0 &&
        form.components[0];
      const componentSchemaData = JSON.parse(componentSchemaDataJson as string);

      const columnDefinitions: GridColumnDefinition[] = [];
      const fieldMapping: any[] = [];
      componentSchemaData.map((comp) => {
        columnDefinitions.push({
          headerName: comp.label,
          field: comp.key,
          cellDataType: componentTypeToGridColumnDatatypeMapper(comp.type)
          //TODO should include the formatter options if required
        } as GridColumnDefinition);

        fieldMapping.push({
          headerName: comp.label,
          fieldName: comp.key
        });
      });
      //  console.log(componentSchemaData);
      context.data.customOptions.gridOptions.columnDefinitions =
        mergeGridColumnDefinitionsWithDefaults(columnDefinitions);
      context.data.customOptions.gridOptions.fieldMapping = fieldMapping;

      context.self.root.everyComponent(function (component) {
        console.log('comp.component.key -> ' + component.component.key);
        if (
          component.component.key ===
            'customOptions.gridOptions.fieldMapping' ||
          component.component.key ===
            'customOptions.gridOptions.columnDefinitions'
        ) {
          console.log('found => ' + component.component.key);
          component.rebuild(); //redraw, updateOnChange, triggerRedraw didnt work
          // fileParsingContext.componentContext.root.redraw()
          console.log(component);
        }
      });
    })
    .catch((error) => {
      // Handle errors during the Fetch call
      console.error('Fetch error:', error);
    });
}

function getImportConfiguration() {
  return {
    label: 'Import Configuration',
    key: 'importConfig',
    weight: 30,
    components: [
      {
        key: 'customOptions.gridOptions.importDataEnabled',
        type: 'checkbox',
        label: 'Import Data',
        input: true,
        tooltip: 'Import Data from Excel or CSV',
        defaultValue: false,
        weight: 703
      },

      {
        key: 'customOptions.gridOptions.importButtonDataLabel',
        type: 'textfield',
        label: 'Button label on Data Import',
        input: true,
        defaultValue: '',
        weight: 703
      },

      {
        key: 'customOptions.gridOptions.importFormId',
        type: 'select',
        label: 'Import Configuration FormId',
        input: true,
        multiple: false,
        dataSrc: 'values',
        data: {
          values: [
            {
              label: 'Deposits - Data ImportConfig',
              value: 'deposit-import-configuration'
            },
            {
              label: 'Fund Disbursements - Data ImportConfig',
              value: 'fund-disbursements-import-configuration'
            }
          ]
        },
        weight: 704,
        disabled: false,
        clearOnHide: false,
        onChange: (context) => {
          if (context.flags && context.flags.modified) {
            // run only if the configuration is changed if the
            loadFormDefinition(context);
          }
        },
        customConditional(context) {
          return (
            context.data.customOptions.gridOptions.importDataEnabled === true
          );
        }
      },
      /*     {
          type: 'checkbox',
          input: true,
          key: 'rebuildColumnDefinitionsFromForm',
          label: 'Rebuild the columnDefinitions ',
          description: `Select this option, and unselect and select the Import Configuration Value in dropdown to rebuild the column Definitions again.<br>
                <b style="color:red">All the manual configurations (new column additions or formatters updates) performed after last import will be lost</b `,
          defaultValue: false,
          tooltip: 'Parses the File Data',
          weight: 32
        },*/
      {
        type: 'checkbox',
        input: true,
        key: 'customOptions.gridOptions.parseFileData',
        label: 'Parse FileData',
        defaultValue: false,
        tooltip: 'Parses the File Data',
        weight: 704
      },
      {
        type: 'textfield',
        input: true,
        key: 'customOptions.gridOptions.parserResultField',
        label: 'Field name to Store Results',
        placeholder: '',
        defaultValue: 'parsedResults',
        description:
          "Field name to Store the Results in the Context. If field name is 'parsedResults' then the results can be access at <b>data.parsedResults</b>",
        weight: 704,
        conditional: {
          json: {
            '==': [
              { var: 'data.customOptions.gridOptions.parseFileData' },
              true
            ]
          }
        }
      },
      {
        type: 'checkbox',
        input: true,
        key: 'customOptions.gridOptions.parserSlNoRequired',
        label: 'Sl No Required',
        defaultValue: true,
        tooltip: 'Add auto incremented Sl No value to the Parsed Results',
        weight: 704
      },
      {
        type: 'textfield',
        input: true,
        key: 'customOptions.gridOptions.parserSlNoField',
        label: 'Sl No Field Name',
        description:
          'Sl No Field Name, where the auto incremented value is added to',
        weight: 704,
        defaultValue: 'slNo',
        validate: {
          required: true
        },
        conditional: {
          json: {
            '==': [
              { var: 'data.customOptions.gridOptions.parserSlNoRequired' },
              true
            ]
          }
        }
      },
      {
        type: 'select',
        input: true,
        key: 'customOptions.gridOptions.parser',
        label: 'Parser',
        placeholder: 'Select your file parser',
        weight: 704,
        tooltip: 'Which Parser to parse the file',
        valueProperty: 'value',
        dataSrc: 'custom',
        data: {
          custom() {
            debugger;
            return _.map(
              (Formio as any).Providers.getProviders('parser'),
              (parser, key) => ({
                label: parser.title,
                value: key
              })
            );
          }
        },
        conditional: {
          json: {
            '==': [
              { var: 'data.customOptions.gridOptions.parseFileData' },
              true
            ]
          }
        }
      },
      {
        key: 'customOptions.gridOptions.fieldMapping',
        weight: 704,
        label: 'Field Mapping',
        tooltip: '',
        type: 'datagrid',
        input: true,
        reorder: true,
        components: [
          {
            key: 'headerName',
            label: 'File Header Name',
            type: 'textfield',
            input: true,
            validate: {
              required: false
            }
          },
          {
            key: 'fieldName',
            label: 'Field Name',
            type: 'textfield',
            input: true,
            allowCalculateOverride: true,
            calculateValue: { _camelCase: [{ var: 'row.label' }] },
            validate: {
              required: false
            }
          }
        ]
      },
      {
        key: 'customOptions.gridOptions.refreshComponent',
        label: 'Refresh Component on Data Parsing',
        type: 'select',
        input: true,
        validate: {
          required: false
        },
        dataSrc: 'custom',
        valueProperty: 'value',
        data: {
          custom(context) {
            const values = [{}];
            context.utils.eachComponent(
              context.instance.options.editForm.components,
              function (component, path) {
                if (
                  component.key !== context.data.key &&
                  component.key !== undefined &&
                  component.type !== 'button'
                ) {
                  values.push({
                    label: `${component.label} (${component.key})`,
                    value: path
                  });
                }
              }
            );
            return values;
          }
        }
      }
    ]
  };
}

function editForm() {
  return {
    components: [
      {
        type: 'tabs',
        key: 'tabs',
        components: [
          {
            label: 'Display',
            key: 'display',
            weight: 0,
            components: [
              {
                key: 'customOptions.gridOptions.title',
                weight: 0,
                type: 'textfield',
                input: true,
                label: 'Table Title',
                placeholder: 'Table Title',
                tooltip: 'The label used as heading of the table.',
                validate: {
                  required: false
                }
              },
              {
                key: 'aclResource',
                type: 'textfield',
                label: 'ACL Key',
                weight: 5,
                placeholder: 'ACL Key',
                input: true
              },
              {
                key: 'customOptions.gridOptions.isListingScreen',
                type: 'checkbox',
                label: 'Is Listing Screen?',
                weight: 5,
                placeholder: 'is Listing Screen ?',
                description: "select this option if the smart table is being displayed in listing screen, need this config to avoid multiple reloads of data",
                input: true,
                defaultValue: false,
              },

              {
                type: 'panel',
                key: 'styling',
                label: 'Styling',
                title: 'Styling',
                collapsible: true,
                components: [ {
                  key: 'customOptions.gridOptions.styleClass',
                  weight: 0,
                  type: 'textfield',
                  input: true,
                  label: 'Style Class',
                  placeholder: 'Style class for gridlines, striped etc',
                  description: `few style classes <b>p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-lg</b>`,
                  tooltip: 'The label used as heading of the table.',
                  validate: {
                    required: false
                  }
                },{
                  key: 'customOptions.gridOptions.gridHeaderClass',
                  weight: 0,
                  type: 'textfield',
                  input: true,
                  label: 'Grid Header Class',
                  placeholder: 'Style class for Grid Header (Title, Simple Search panel)',
                  description: `can use to add custom class`,
                  defaultValue: 'mt-4',
                  validate: {
                    required: false
                  },
                },{
                  key: 'customOptions.gridOptions.gridTitleClass',
                  weight: 0,
                  type: 'textfield',
                  input: true,
                  label: 'Grid Title Class',
                  placeholder: 'Style class for Grid Title (Title, Simple Search panel)',
                  description: `can use to add custom class`,
                  defaultValue: 'mb-3',
                  validate: {
                    required: false
                  },
                },,{
                  key: 'customOptions.gridOptions.gridSearchControlClass',
                  weight: 0,
                  type: 'textfield',
                  input: true,
                  label: 'Grid Search Controls Class',
                  placeholder: 'Style class for Grid Search Controls (Title, Simple Search panel)',
                  description: `can use to add custom class`,
                  defaultValue: 'mb-3',
                  validate: {
                    required: false
                  },
                },

                  {
                    key: 'customOptions.gridOptions.resizableColumns',
                    type: 'checkbox',
                    label: 'Resizable?',
                    description: ` All columns can be resized`,
                    input: true,
                    defaultValue: true,
                    tooltip: '',
                    weight: 702
                  },
                  {
                  key: 'customOptions.gridOptions.rowHover',
                  type: 'checkbox',
                  label: 'Hover',
                  input: true,
                  description: 'Highlight a row on hover.',
                  defaultValue: true,
                  weight: 703
                },
                  {
                    key: 'customOptions.gridOptions.headerRowClass',
                    weight: 0,
                    type: 'textfield',
                    input: true,
                    label: 'Header Row Class',
                    tooltip: 'class names added to thead > tr',
                    validate: {
                      required: false
                    }
                  },
                  {
                    key: 'customOptions.gridOptions.dataRowClass',
                    weight: 0,
                    type: 'textfield',
                    input: true,
                    label: 'Data Row Class',
                    tooltip: 'class names added to tbody > tr',
                    validate: {
                      required: false
                    }
                  },
                  {
                    weight: 703,
                    type: 'textarea',
                    input: true,
                    key: 'customOptions.gridOptions.dynamicDataRowClass',
                    label: 'Dynamic Data Row Class',
                    placeholder: 'lodash template code',
                    tooltip: `write lodash template code which is evaluated to display the cell content.
                                    Context variables are
                                    rowData -  complete row data json object
                                    data - cell data raw value. In case of composite columns its the computed value from value Formatter
                                    gridOptions - the Grid Configuration`,
                    description: "The class Name is evaluated dynamically based on logic and then assigned to row",
                    editor: 'ace',
                    as: 'handlebars',
                    wysiwyg: {
                      minLines: 8
                    },
                    defaultValue: undefined
                  },
                  {
                    weight: 703,
                    type: 'textarea',
                    input: true,
                    key: 'customOptions.gridOptions.dynamicRowPrefix',
                    label: 'Dynamic Row Prefix Content',
                    placeholder: 'lodash template code to prefix the row data (1st column with dynamic html)',
                    tooltip: `write lodash template code which is evaluated to display the cell content.
                                    Context variables are
                                    rowData -  complete row data json object
                                    data - cell data raw value. In case of composite columns its the computed value from value Formatter
                                    gridOptions - the Grid Configuration`,
                    description: "The content is evaluated dynamically based on logic and then assigned to 1st column of the row",
                    editor: 'ace',
                    as: 'handlebars',
                    wysiwyg: {
                      minLines: 8
                    },
                    defaultValue: undefined
                  }]
              },

              {
                type: 'panel',
                key: 'pagination',
                label: 'Pagination',
                title: 'Pagination',
                collapsible: true,
                components: [
                  {
                    key: 'customOptions.gridOptions.pagination',
                    type: 'checkbox',
                    label: 'Needs Pagination?',
                    input: true,
                    tooltip: '',
                    weight: 702
                  },
                  {
                    key: 'customOptions.gridOptions.paginationRowsPerPageOptions',
                    type: 'select',
                    label: 'Rows Per Page Options',
                    input: true,
                    multiple: true,
                    tooltip: '',
                    dataSrc: 'values',
                    data: {
                      values: [
                        {
                          label: '10',
                          value: 10
                        },
                        {
                          label: '20',
                          value: 20
                        },
                        {
                          label: '30',
                          value: 30
                        },
                        {
                          label: '40',
                          value: 40
                        },
                        {
                          label: '50',
                          value: 50
                        },
                        {
                          label: '60',
                          value: 60
                        },
                        {
                          label: '70',
                          value: 70
                        },
                        {
                          label: '80',
                          value: 80
                        },
                        {
                          label: '90',
                          value: 90
                        },
                        {
                          label: '100',
                          value: 100
                        }
                      ]
                    },
                    defaultValue: [10, 20, 50, 100],
                    weight: 710
                  },
                  {
                    key: 'customOptions.gridOptions.paginationPageSize',
                    type: 'textfield',
                    label: 'Rows per Page?',
                    input: true,
                    tooltip:
                      'should be a possible value from Rows Per Page Options',
                    defaultValue: 10,
                    weight: 702
                  },
                  {
                    key: 'customOptions.gridOptions.pageDataFieldName',
                    type: 'textfield',
                    label: 'Page Data Field Name',
                    input: true,
                    description: `The field name to pick the results (array) from the loaded data. the underlying smart grid still expect the api response to be in a schema when pagination is enabled. The only exception is <b>content</b>
                                    <br> use '*' if remote API is returning Array as a result, rather than json<br>
                                    <pre>content:
                                    empty:
                                    first:
                                    last:
                                    number:
                                    numberOfElements:
                                    size:
                                    total:
                                    totalElements:
                                    totalPages:</pre>`,
                    defaultValue: 'content',
                    weight: 702
                  }
                ]
              },
              {
                type: 'panel',
                key: 'sl_no',
                label: 'Sl No',
                title: 'First Column Configuration',
                collapsible: true,
                components: [
                  {
                    key: 'customOptions.gridOptions.slnoColumnRequired',
                    type: 'checkbox',
                    label: 'Sl No Column required?',
                    input: true,
                    tooltip: 'Will be the First Column',
                    defaultValue: true,
                    weight: 702
                  },

                  {
                    key: 'customOptions.gridOptions.slnoLabel',
                    type: 'textfield',
                    label: 'S No - Column Label',
                    input: true,
                    tooltip: '',
                    defaultValue: 'S No',
                    weight: 702,
                    customConditional(context) {
                      return (
                        context.data.customOptions.gridOptions
                          .slnoColumnRequired === true
                      );
                    }
                  },
                  {
                    key: 'customOptions.gridOptions.slnofield',
                    type: 'textfield',
                    label: 'S No - API Field',
                    input: true,
                    tooltip:
                      'API Field used to lookup the value in data to render the Sl No. Leave it empty if S No should be the Row Number',
                    defaultValue: 'S No',
                    weight: 702,
                    customConditional(context) {
                      return (
                        context.data.customOptions.gridOptions
                          .slnoColumnRequired === true
                      );
                    }
                  },
                  {
                    key: 'customOptions.gridOptions.slnoColumnStyle',
                    type: 'textfield',
                    label: 'S No - Styles',
                    input: true,
                    placeholder: 'width: 4rem',

                    tooltip:
                      'inline css style that is applicable in style="" of S No column ',
                    defaultValue: 'width: 4rem',
                    weight: 702,
                    customConditional(context) {
                      return (
                        context.data.customOptions.gridOptions
                          .slnoColumnRequired === true
                      );
                    }
                  },
                  {
                    key: 'customOptions.gridOptions.checkboxSelection',
                    type: 'checkbox',
                    label: 'Needs Checkbox Selection in First Column?',
                    input: true,
                    description:
                      'select any one of the value from Checkbox or Radio Button, not both, in case both are selected, both will be displayed',
                    tooltip: '',
                    weight: 702
                  },
                  {
                    key: 'customOptions.gridOptions.radioSelection',
                    type: 'checkbox',
                    label: 'Needs Radio Button Selection in First Column?',
                    input: true,
                    tooltip: '',
                    weight: 702
                  },
                  {
                    key: 'customOptions.gridOptions.selectedRecordIdentifierField',
                    type: 'textfield',
                    label: 'Selected Record Identifier Field',
                    input: true,
                    placeholder: '',
                    description:
                      'field name that uniquely identifies the selected record',
                    defaultValue: 'id',
                    weight: 702
                    /* customConditional(context) {
                        return context.data.customOptions.gridOptions.checkboxSelection === true;
                       }*/
                  },
                  {
                    key: 'customOptions.gridOptions.selectedRecordStorageField',
                    type: 'textfield',
                    label: 'Selected Record Storage Field',
                    input: true,
                    placeholder: '',
                    description: `field name used to store the array values of Selected records <br>
if the field name is pacRefSelectedIds then the data will be available at :  root.submission.data.pacRefSelectedIds=['PAC001', 'PAC002','PAC-CF-009']`,
                    defaultValue: undefined,
                    weight: 702,
                    customConditional(context) {
                      return (
                        context.data.customOptions.gridOptions
                          .checkboxSelection === true ||
                        context.data.customOptions.gridOptions
                          .radioSelection === true
                      );
                    }
                  },
                  {
                    key: 'customOptions.gridOptions.useCompleteRecordDataAsSelection',
                    type: 'checkbox',
                    label: 'Store Complete Row Data Data as Selection Result',
                    input: true,
                    placeholder: '',
                    description: `Complete row data will be stored as selected result, rather than plucking data from  Selected Record Storage Field<br>
if the field name is pacRefSelectedIds then the data will be available at :  root.submission.data.pacRefSelectedIds=[ {id: 129, pacRefId="PAC001".......},{id: 456, pacRefId="PAC009".......}...]`,
                    defaultValue: false,
                    weight: 702,
                    customConditional(context) {
                      return (
                        context.data.customOptions.gridOptions
                          .checkboxSelection === true ||
                        context.data.customOptions.gridOptions
                          .radioSelection === true
                      );
                    }
                  }
                ]
              },
              {
                type: 'panel',
                key: 'others',
                label: 'Other Configuration',
                title: 'Other Configuration',
                collapsible: true,
                components: [
                  {
                    key: 'customOptions.gridOptions.refreshEnabled',
                    type: 'checkbox',
                    label: 'Display Data Refresh Button',
                    input: true,
                    tooltip: 'Display Data Refresh Button',
                    defaultValue: true,
                    weight: 703
                  },
                  {
                    key: 'customOptions.gridOptions.exportToExcel',
                    type: 'checkbox',
                    label: 'Export To Excel',
                    input: true,
                    tooltip: 'Export Data to Excel',
                    defaultValue: false,
                    weight: 703
                  },
                  {
                    key: 'customOptions.gridOptions.noDataLabel',
                    type: 'textfield',
                    label: 'Text to display when there is no data to show',
                    input: true,
                    tooltip: '',
                    weight: 702
                  },
                  {
                    key: 'customOptions.gridOptions.noDataLabelStyleClass',
                    type: 'textfield',
                    label: 'StyleClass to apply for No data Label',
                    input: true,
                    tooltip: '',
                    weight: 702
                  },
                  {
                    key: 'customOptions.gridOptions.ignoreTextWithApiFieldEvents',
                    type: 'checkbox',
                    label: 'Ignore Data Load Events from  Text With API Field',
                    input: true,
                    tooltip:
                      'Ignore Data Load Events from  Text With API Field',
                    defaultValue: false,
                    weight: 703
                  }
                ]
              }
            ]
          },
          {
            label: 'Search',
            key: 'search',
            weight: 35,
            components: [
              {
                type: 'panel',
                key: 'globalsearch',
                label: 'Simple Text Search',
                title: 'Simple Text Search',
                collapsible: true,
                components: [
                  {
                    key: 'customOptions.gridOptions.globalSearchEnabled',
                    type: 'checkbox',
                    label: 'Needs Simple Text Search?',
                    input: true,
                    tooltip: '',
                    weight: 702
                  },
                  {
                    weight: 0,
                    type: 'textfield',
                    input: true,
                    key: 'customOptions.gridOptions.globalSearchPlaceholder',
                    label: 'Search Text box placeholder',
                    tooltip: `The placeholder text for the Simple Search Text box, that gives visual cue to end user. you can use the Search fields the Search is carried on<br>
                                Example : Search PAC, Payor, Remarks ....`,
                    placeholder: '',
                    validate: {
                      required: false
                    }
                  },
                  {
                    key: 'customOptions.gridOptions.globalSearchFilterOperator',
                    type: 'select',
                    label: 'Simple Text search Search field behavior',
                    description:
                      'This setting is disabled to be controlled by Form Admin, End user has the option to use Starts with or Like while searching',
                    input: true,
                    multiple: false,
                    tooltip:
                      'should the Simple Text search use contains or starts_with for each field',
                    dataSrc: 'values',
                    data: {
                      values: [
                        {
                          label: 'Starts With',
                          value: 'starts_with'
                        },
                        {
                          label: 'Contains',
                          value: 'contains'
                        }
                      ]
                    },
                    defaultValue: ['starts_with'],
                    weight: 702,
                    disabled: true,
                    clearOnHide: false,
                    customConditional(context): boolean {
                      return (
                        context.data.customOptions.gridOptions
                          .globalSearchEnabled === true
                      );
                    }
                  },
                  {
                    weight: 703,
                    type: 'textarea',
                    input: true,
                    key: 'customOptions.gridOptions.globalSearchQueryFormatter',
                    label: 'Search Query Formatter for Starts with Search',
                    placeholder: 'lodash template code',
                    description: `write lodash template code which is evaluated to compute the Search Params. The formatted value will be appended as is to the api/search url. <br>
                      Context variables are <br>
                      <b>searchText</b> -  user entered search text value <br>
                      <b>gridOptions</b>  - the complete Grid Configuration`,
                    editor: 'ace',
                    as: 'handlebars',
                    wysiwyg: {
                      minLines: 8
                    },
                    defaultValue: undefined,
                    clearOnHide: false,
                    customConditional(context): boolean {
                      return (
                        context.data.customOptions.gridOptions
                          .globalSearchEnabled === true
                      );
                    }
                  }
                ]
              },
              {
                type: 'panel',
                key: 'advancedsearch',
                label: 'Advanced Search',
                title: 'Advanced Search',
                collapsible: true,
                components: [
                  {
                    key: 'customOptions.gridOptions.advancedSearchEnabled',
                    type: 'checkbox',
                    label: 'Needs Advanced Search?',
                    input: true,
                    tooltip: '',
                    defaultValue: true
                  },
                  {
                    key: 'customOptions.gridOptions.advancedFilterPosition',
                    type: 'select',
                    label: 'Advanced Filter Position',
                    input: true,
                    tooltip:
                      'Horizontal alignment for cells of the table. Currently only Right is supported',
                    dataSrc: 'values',
                    data: {
                      values: [
                        {
                          label: 'Left',
                          value: 'left'
                        },
                        {
                          label: 'Top',
                          value: 'top'
                        },
                        {
                          label: 'Right',
                          value: 'right'
                        }
                      ]
                    },
                    defaultValue: 'left',
                    weight: 3
                  }
                ]
              }
            ]
          },
          {
            label: 'API',
            key: 'api',
            weight: 30,
            components: [
              {
                weight: 0,
                type: 'textfield',
                input: true,
                key: 'key',
                label: 'Key',
                placeholder: '',
                validate: {
                  required: true
                }
              },
              {
                weight: 0,
                type: 'textfield',
                disabled: true,
                description: ` <b>Internal purpose</b>  this autopopulated key  is used internally to fire events Ex. <code>smart-table-\${Computed Key}-delete_row</code> `,
                input: true,
                key: 'customOptions.gridOptions.key',
                label: 'Computed Key',
                placeholder: '',
                defaultValue: undefined,
                calculateValue(context) {
                  return context.data.key;
                }
              },
              {
                label: 'Grid Data Context',
                key: 'customOptions.gridOptions.gridDataContext',
                type: 'radio',
                inputType: 'radio',
                input: true,
                inline: true,
                defaultValue: API_DATA_DRIVEN,
                values: [
                  {
                    label: 'Context Data Driven',
                    value: CONTEXT_DATA_DRIVEN
                  },
                  {
                    label: 'API Data Driven',
                    value: API_DATA_DRIVEN
                  }
                ],
                description: `How the data in the grid should be loaded?? <br>
                    <b>Context Data Driven</b> - Loads the data from the field available in the Submission Context<br>
                    <b>API Data Driven</b> - Loads the data from the API Url <br>
                    <span class="bg-warning"><b>Note - </b><u>care should be taken by user to disable Advanced search, sort, pagination etc configuration, which isn't applicable in local context data mode</u></span>`
              },

              {
                type: 'textfield',
                input: true,
                key: 'customOptions.gridOptions.apiUrl',
                label: 'API URL',
                placeholder: 'API URL to load data in this table/grid',
                description: 'API URL to load data in this table/grid',
                validate: {
                  required: true
                },
                customConditional(context): boolean {
                  return (
                    context.data.customOptions.gridOptions.gridDataContext ===
                    'apiDataDriven'
                  );
                }
              },
              {
                type: 'checkbox',
                input: true,
                key: 'customOptions.gridOptions.triggerDataFetchOnFormDataLoad',
                label: 'Fetch Table Data on Form Data Load',
                description:
                  'Trigger API URL to load data in this table/grid, once the Form Data is loaded',
                validate: {
                  required: false
                },
                defaultValue: false,
                customConditional(context): boolean {
                  return (
                    context.data.customOptions.gridOptions.gridDataContext ===
                    'apiDataDriven'
                  );
                }
              },

              {
                type: 'textfield',
                input: true,
                key: 'customOptions.gridOptions.contextDataFieldName',
                label: 'Context Data Field name',
                placeholder: 'Context Data field name',
                description:
                  'Field name to lookup the data in submission context, that can be used to render the data in the grid',
                validate: {
                  required: true
                },
                customConditional(context): boolean {
                  return (
                    context.data.customOptions.gridOptions.gridDataContext ===
                    'contextDataDriven'
                  );
                }
              },
              {
                type: 'textarea',
                key: 'customOptions.gridOptions.defaultData',
                label: 'Default Data',
                placeholder: 'Default Data',
                as: 'json',
                editor: 'ace',
                defaultValue: {}
              }
            ]
          },
          getColumnDefinitions(),
          getImportConfiguration()
        ]
      }
    ]
  };
}

class Status {
  static status = new Map();
}

export function registerSmartTableComponent(
  injector: Injector,
  screen: string
) {
  if (!Status.status.has(screen)) {
    try {
      registerCustomFormioComponent(
        COMPONENT_OPTIONS,
        SmartTableComponent,
        injector
      );
      Status.status.set(screen, true);
    } catch (e) {
      console.log('Error while registering SmartTableComponent: ' + screen);
    }
  }
}
