import {
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
  Renderer2
} from '@angular/core';
import { MenuItem } from 'primeng/api';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { StorageService } from '../../shared/services/storage.service';
import { UserInfo } from '../../shared/model/user-info';
import { AuthService } from '../../shared/services/auth.service';
import * as _ from 'lodash';
import {
  Theme,
  themes,
  ThemeService
} from '../../shared/services/theme.service';
import { UserUtils } from '../../shared/utils/user-utils';
import {IamAppConstants} from "../../iam/shared/iam-app-constants";
import {IamAuthUtils} from "@shared/utils/iam-auth-utils";
import {environment} from "../../../environments/environment";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  items: MenuItem[] = [];
  themeList: Theme[] = themes();
  isLoggedIn: any;

  userInfo!: UserInfo;
  imageUrl: string;
  privileged: boolean;
  minZoom = 0.5;
  maxZoom = 1.5;
  currentZoom = 1.0;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    @Inject(Renderer2) private renderer: Renderer2,
    private cd: ChangeDetectorRef,
    private router: Router,
    private storageService: StorageService,
    private authService: AuthService,
    private themeService: ThemeService
  ) {}

  ngOnInit(): void {

    const currentUserIdentityDetails = IamAuthUtils.getCurrentUserIdentityDetails();
     this.privileged = currentUserIdentityDetails?.privileged;
    this.authService
      .notifyUserDetails(IamAppConstants.current_user_profile_details)
      .subscribe((userDetails: UserInfo | null) => {
        if (userDetails) {
          this.userInfo = _.clone(userDetails);
          this.imageUrl = UserUtils.generateAvatar({
            name: userDetails.firstName + '+' + userDetails.lastName
          });
        }
      });
  }

  logout() {
    this.isLoggedIn = false;
  }

  changeTheme(theme: Theme) {
    this.themeService.setTheme(theme, this.renderer, this.cd);
  }

  zoom(s: string) {
    if (s == '-') {
      this.currentZoom = Math.max(this.minZoom, this.currentZoom - 0.1);
    }
    if (s == '+') {
      this.currentZoom = Math.min(this.maxZoom, this.currentZoom + 0.1);
    }
    try {
      const element = document
        .getElementsByClassName('main-container')
        .item(0) as HTMLLinkElement;

      element.style['zoom'] = this.currentZoom;
    } catch (e) {
      console.log('error while zooming', e);
    }
  }

  refreshProfile() {
    location.href= environment.samlLoginUrl
  }
}
