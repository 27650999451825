import {
  ChangeDetectorRef,
  Component,
  DestroyRef,
  Inject,
  inject,
  Injector,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import {ActivatedRoute, Data, NavigationEnd, ParamMap, Router} from '@angular/router';
import {FormService} from '../../forms/form.service';
import * as _ from 'lodash';
import {HttpClient} from '@angular/common/http';
import {SubmissionService} from '../../forms/submission.service';
import {AdditionalProperties, ScreenConfig, ScreenInitializationConfig, SubmitSuccessPopup} from './screen-config';
import {registerCustomActionButtonComponent} from '@shared/components/custom-action-button/custom-action-button.formio';
import {Formio, Utils as FormioUtils} from '@evi-ui/js';
import {EventEmitter as EventEmitter} from 'eventemitter3';
import {registerUrlBasedTextFieldComponent} from '@shared/components/url-based-textfield/url-based-textfield.formio';
import {registerSmartTableComponent} from '@shared/components/smart-table/smart-table.formio';
import {LodashTemplateEvaluator} from '@shared/components/smart-table/LodashTemplateEvaluator';
import {
  registerScreenConfigurationComponent
} from '@shared/components/screen-configuration/screen-configuration.formio';
import {EventBusService} from '@shared/event-bus.service';
import {EventData, EventType} from '@shared/model/event.data';
import {combineLatest, forkJoin, Observable, Observer, Subject} from 'rxjs';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {ScreenConfigurationUtil} from '@shared/components/screen-configuration/screen-configuration-util';
import {FormRendererUtils} from './form-renderer-utils';
import {IamAuthUtils} from '@shared/utils/iam-auth-utils';
import {registerVectorCheckboxComponent} from '@shared/components/vector-checkbox/vector-checkbox.formio';
import {environment} from '../../../../environments/environment';
import {IamUserService} from '../../../iam/iam-user/iam-user.service';
import {registerNestedFormComponent} from '@shared/components/nested-form/nested-form.formio';
import {filter} from 'rxjs/operators';
import {registerEmbeddedReportComponent} from '@shared/components/embedded-report/embedded-report.formio';
import {registerTimelineComponent} from '@shared/components/timeline/timeline.formio';
import {RedirectionService} from '@shared/services/redirection.service';
import {IamAppConstants} from "../../../iam/shared/iam-app-constants";
import {DOCUMENT} from "@angular/common";

@Component({
  selector: 'app-all-forms-renderer',
  templateUrl: './all-forms-renderer.component.html',
  styleUrls: ['./all-forms-renderer.component.scss']
})
export class AllFormsRendererComponent implements OnDestroy, OnInit {
  destroyRef = inject(DestroyRef);

  formId: any;
  id: any;

  public form: any = {title: '', components: []};

  routeData: Data;

  @Output()
  formioEvent = new EventEmitter();

  public formInstance: any;
  routerSubscription$: any;

  constructor(
    private http: HttpClient,
    private injector: Injector,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private route: ActivatedRoute,
    private submissionService: SubmissionService,
    private formService: FormService,
    private formRenderUtils: FormRendererUtils,
    private iamUserService: IamUserService,
    private eventBusService: EventBusService,
    private redirectionService: RedirectionService,
    @Inject(DOCUMENT) private document: Document
  ) {
    registerCustomActionButtonComponent(injector, 'all-forms-renderer');
    registerSmartTableComponent(injector, 'all-forms-renderer');
    registerUrlBasedTextFieldComponent(injector, 'all-forms-renderer');
    registerScreenConfigurationComponent(injector, 'all-forms-renderer');
    registerVectorCheckboxComponent(injector, 'all-forms-renderer');
    registerTimelineComponent(injector, 'all-forms-renderer');
    registerNestedFormComponent(injector, 'all-forms-renderer');
    registerEmbeddedReportComponent(injector, 'all-forms-renderer');

    console.log(
      'constructor the AllFormsRendererComponent Component => ',
      new Date()
    );

    this.route.data.subscribe(data => this.routeData = data);

    console.log('Get paramMap and queryParamMap on ActivatedRoute');
    combineLatest([this.route.paramMap, this.route.queryParamMap]).subscribe(
      ([paramMap, queryParams]) => {
        console.log('Params Subscription => ', new Date());
        console.log(
          `QueryPrams : ${JSON.stringify(queryParams)} \n PathParams : ${JSON.stringify(paramMap)}`
        );
        this.formId = paramMap.get('formId');
        this.id = paramMap.get('id');
        const stateToPropagateFromQueryParams =
          this.getStateToPropagateFromQueryParams(queryParams);
        console.log(
          'stateToPropagateFromQueryParams => ',
          stateToPropagateFromQueryParams
        );

        let {state} = window.history.state;

        if (!_.isEmpty(stateToPropagateFromQueryParams)) {
          console.log(
            'Found stateToPropagateFromQueryParams it takes precedence over the actual internal routing state to honor direct links (permalinks)'
          );
          state = stateToPropagateFromQueryParams;
        }

        if (!this.id && state && Object.prototype.hasOwnProperty.call(state, 'id')) {
          this.id = state.id;
        }

        this.renderForm(state);
      }
    );
    this.routerSubscription$ = this.router.events
      .pipe(filter((e): e is NavigationEnd => e instanceof NavigationEnd))
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((e) => {
        console.log('Navigation Away ', e);
      });
  }

  ngOnInit() {
    console.log(
      'ngOnInit the AllFormsRendererComponent Component => ',
      new Date()
    );
  }

  ngOnDestroy() {
    console.log(
      'OnDestroying the AllFormsRendererComponent Component => ',
      new Date()
    );
    try {
      console.log('Destroying the Form ', this.formInstance);
      // this.formInstance.destroy(true);
      // this.formInstance = undefined;

      console.log('Destroyed the Formio successfully');
    } catch (e) {
      console.error('Cannot destroy  the Form ', e);
    }
  }

  private getStateToPropagateFromQueryParams(params: ParamMap): any {
    let stateToPropagate: string | null | any = {};
    // Iterate through query parameters
    for (const key of params.keys) {
      if (params.has(key)) {
        // Check if the key starts with 'stateToPropagate.'
        if (key.startsWith('stateToPropagate.')) {
          // If yes, add it to the stateToPropagate object
          const propKey = key.split('stateToPropagate.')[1];
          stateToPropagate[propKey] = params.get(key);
        } else if (key == 'stateToPropagate') {
          //stateToPropagate has a single value assigned directly
          stateToPropagate = params.get(key);
          break;
        }
      }
    }
    return stateToPropagate;
  }

  private submitData(submission: any, screenConfig: ScreenConfig) {
    const _self = this;
    FormRendererUtils.nullifyRecursively(submission.data);
    const cleanedupRequest = this.formRenderUtils.cleanupRequestData(submission.data, this.formInstance);
    if (screenConfig.documentConfig.sourceKey) {
      let newDocumentsList = this.documentMap(
        cleanedupRequest[screenConfig.documentConfig?.sourceKey]
      );
      const existingDocumentsList =
        cleanedupRequest[
          screenConfig.documentConfig.targetKey
            ? screenConfig.documentConfig.targetKey
            : screenConfig.documentConfig.sourceKey
          ];
      if (existingDocumentsList) {
        newDocumentsList = _.concat(existingDocumentsList, newDocumentsList);
      }
      newDocumentsList = _.uniqBy(newDocumentsList, 'documentId');
      cleanedupRequest[
        screenConfig.documentConfig.targetKey
          ? screenConfig.documentConfig.targetKey
          : screenConfig.documentConfig.sourceKey
        ] = newDocumentsList;
    }
    const submissionUrlAfterInterpolation = LodashTemplateEvaluator.interpolate(
      screenConfig.submissionUrl!,
      cleanedupRequest
    );
    screenConfig.submitSuccessPopup.redirectUrlAfterSubmission =
      LodashTemplateEvaluator.interpolate(
        screenConfig.submitSuccessPopup.redirectUrlAfterSubmission,
        this.formInstance
      );
    this.submissionService
      .saveSubmission(submissionUrlAfterInterpolation, cleanedupRequest)
      .subscribe({
          next:
            (response) => {
              console.log(JSON.stringify(response));
              if (
                environment.enableWorkflow &&
                response &&
                response.workflowRoles
              ) {
                this.iamUserService
                  .getFilteredUsersWithAcl(response['workflowRoles'], [
                    IamAuthUtils.getCurrentLoggedUserIamId()
                  ])
                  .subscribe((users) => {
                    //TODO: Change this snippet to choose the popup formId dynamically
                    const workflowParticipantsPopup = new SubmitSuccessPopup();
                    workflowParticipantsPopup.message = 'Choose';
                    workflowParticipantsPopup.popupScreenId = 'workflow-participant-chooser';
                    workflowParticipantsPopup.headerMessage = 'Choose Approver!!';
                    workflowParticipantsPopup.data = {
                      workflowParticipants: users,
                      submissionData: submission.data
                    };
                    workflowParticipantsPopup.returnValueStorageAttribute =
                      'workflowActor';
                    const uiConfig = {
                      width: '45%',
                      closable: true,
                      modal: true,
                      showHeader: false,
                      contentStyle: {
                        padding: 0,
                        overflow: 'visible'
                      },
                      baseZIndex: 10000
                    };

                    console.log(
                      'ACLs: ',
                      response['workflowRoles'],
                      'Self: ',
                      IamAuthUtils.getCurrentLoggedUserIamId()
                    );
                    this.formRenderUtils
                      .openPopupWithUIConfig(
                        workflowParticipantsPopup,
                        uiConfig,
                        submission
                      )
                      .then((popupResponse) => {
                        console.log(
                          'Workflow Participant Chooser Popup Response: ',
                          popupResponse
                        );
                        console.log(submission.data);
                        setTimeout(() => {
                          _self.submitData(submission, screenConfig);
                        }, 1010);
                      });
                  });
              } else {
                screenConfig.submitSuccessPopup.message =
                  LodashTemplateEvaluator.interpolate(
                    screenConfig.submitSuccessPopup.message,
                    response
                  );
                screenConfig.submitSuccessPopup.redirectUrlAfterSubmission =
                  LodashTemplateEvaluator.interpolate(
                    screenConfig.submitSuccessPopup.redirectUrlAfterSubmission,
                    response
                  );
                this.formRenderUtils.openPopup(screenConfig.submitSuccessPopup);
              }
            },
          error: (errorResponse) => {
            // TODO - error popup should be driven through configuration to select the icons, messages etc
            screenConfig.errorPopup.message = LodashTemplateEvaluator.interpolate(
              screenConfig.errorPopup.message,
              errorResponse
            );
            this.formRenderUtils.openPopup(screenConfig.errorPopup);
          },
          complete: () => {
            console.log("Subscription Completed")
          }
        }
      );
  }

  //TODO: This method is very specific to document service structure. Needs to be re-written to make it generic.
  private documentMap(documents: any) {
    let list: any = [];
    for (const key in documents) {
      if (_.size(documents[key]) > 0) {
        list = _.concat(
          list,
          documents[key].map((doc) => {
            return {categoryType: key, documentId: doc.data.id};
          })
        );
      }
    }
    list = _.uniqBy(list, 'documentId');
    return list;
  }

  private renderForm(propagatedState: any) {
    this.formService.getById(this.formId).subscribe((data) => {
      if (data?.components) {
        this.form.title = data.name;
        this.form.components = JSON.parse(<string>data?.components[0]);
        this.renderSubFormIfApplicable(
          this.form.components,
          propagatedState
        )?.then((response) => {
          console.log('Processed Subforms', response);
          this.form.components = response;
          this.renderFormSeriously(propagatedState);
        });
        console.log('After rendering subform: ', this.form.components);
      }
    });
  }

  private handleApiError(response, result) {
    console.error(
      `Response : ${response.status} ; Error Code : ${result?.errorCode}`
    );

    if (
      response.status > 400 &&
      response.status < 500 &&
      (result?.errorCode === 'TOKEN_EXPIRED' ||
        result?.errorCode === 'TOKEN_NOT_FOUND' ||
        result?.errorCode === 'TOKEN_INVALID')
    ) {
      this.redirectionService.storeRedirectUrl(location);
      location.href = IamAppConstants.auth_login;
    }
  }

  private addSubmitHandler(screenConfig: ScreenConfig, form: any) {
    const _self = this;
    if (screenConfig.submissionUrl) {
      form.on('submit', function (submission) {
        _self.submitData(submission, screenConfig);
      });
    }
  }

  private performBeforeSubmit(submission: any) {
    const clickedButton = submission?.component;
    if (clickedButton?.beforeSubmitHook) {
      const actionValue = LodashTemplateEvaluator.interpolate(
        clickedButton.beforeSubmitHook,
        submission
      );
      FormioUtils.evaluate(actionValue, submission, 'data');
    }
  }

  private fetchAdditionalData(
    additionalUrlsToFetch: AdditionalProperties[],
    currentIndex: number,
    dataAccumulator: any,
    formDataLoaded$: Observer<any>,
    propagatedState: any,
    shouldShowPendingChanges = false
  ) {
    const _self = this;
    let urlToFetch = additionalUrlsToFetch[currentIndex].propertyValue;
    const apiMethod = additionalUrlsToFetch[currentIndex].apiMethod;
    urlToFetch = FormRendererUtils.resolveRepeatingParameters(
      urlToFetch,
      dataAccumulator
    );

    urlToFetch = LodashTemplateEvaluator.interpolate(
      urlToFetch,
      dataAccumulator
    );

    /**
     * lambda to handle the api result in the API call chain
     * @param result
     */
    const handleAPICallResult = (result) => {
      if (result) {
        // if results are valid accumulate  the results
        const propertyName = additionalUrlsToFetch[currentIndex].propertyName;
        const dataPath = additionalUrlsToFetch[currentIndex].dataPath;
        dataAccumulator[propertyName] =
          _.isUndefined(dataPath) || _.isEmpty(dataPath)
            ? result
            : _.get(result, dataPath);
      }
      if (currentIndex + 1 == additionalUrlsToFetch.length) {
        // final api call in the chain ??

        if (shouldShowPendingChanges && dataAccumulator.pendingChanges) {
          //preparation to show pending changes rather than current
          const dataCopy = _.cloneDeep(dataAccumulator);
          const pendingChanges = _.cloneDeep(dataAccumulator.pendingChanges);
          pendingChanges.stepName = dataCopy.stepName;
          pendingChanges.workflowHistory = _.cloneDeep(
            dataCopy.workflowHistory
          );
          pendingChanges.workflowStatus = dataCopy.workflowStatus;
          pendingChanges.showComparison = true;

          dataCopy.pendingChanges = [];
          pendingChanges.currentVersion = dataCopy;
          const modifiedData = pendingChanges;
          modifiedData.stateToPropagate = propagatedState;
          _self.formInstance.submission = {data: modifiedData};

          formDataLoaded$.next(pendingChanges);
        } else {
          _self.formInstance.submission = {data: dataAccumulator};
          formDataLoaded$.next(dataAccumulator);
        }
        const spinner = document.getElementById("spinner");
        if (spinner) {
          spinner.setAttribute('hidden', '');
        }
        return;
      } else {
        // still have chain of calls to process, hence proceed with next API call in the chain
        return _self.fetchAdditionalData(
          additionalUrlsToFetch,
          currentIndex + 1,
          dataAccumulator,
          formDataLoaded$,
          propagatedState,
          shouldShowPendingChanges
        );
      }
    };

    let requestBody = additionalUrlsToFetch[currentIndex].apiRequest;
    if (!_.isEmpty(requestBody)) {
      requestBody = LodashTemplateEvaluator.interpolate(requestBody, {
        propagatedState: propagatedState
      });
      requestBody = LodashTemplateEvaluator.interpolate(
        requestBody,
        _self.form.submission
      );
      requestBody = FormioUtils.evaluate(
        requestBody,
        {
          form: _self.form,
          formInstance: _self.formInstance,
          dataAccumulator: dataAccumulator
        },
        'requestBody'
      );
    }

    if (urlToFetch) {
      // ensure its a valid api, after interpolation
      Formio.fetch(urlToFetch, {
        headers: {
          'content-type': 'application/json',
          Authorization: 'Bearer ' + IamAuthUtils.getCurrentUserToken()
        },
        method: apiMethod,
        body: requestBody,
        cache: 'no-store'
      }).then(function (response) {
        if (response.status && ('' + response.status).startsWith('2')) {
          response.json().then(function (result) {
            return handleAPICallResult(result); // handle the result after the api is returned
          });
        } else {
          console.log('Received non 2xx for ', urlToFetch, '. Response is ', response);
          return handleAPICallResult(null);
        }
      }).catch((error) => {
        console.log(urlToFetch, 'failed', error);
        return handleAPICallResult(null);
      });
    } else {
      // invalid api in the call chain, so proceed further in the API call chain, thinking as if the api results are null
      return handleAPICallResult(null);
    }
  }

  editForm() {
    this.router.navigate(['/forms/form-builder', this.formId]);
  }

  /**
   * Renders subforms by aggregating the components of subform in current form.
   * Recursive subforms are not supported, yet.
   * @param components Parent components
   * @param propagatedState Parameters inherited from parent screen
   */
  private renderSubFormIfApplicable(components: any[], propagatedState?: any) {
    console.log('Propagated State in rendering Subform', propagatedState);
    const nestedForms = FormRendererUtils.getComponentByType(
      'custom-nested-form',
      components
    );
    console.log('Nested Forms: ', nestedForms);

    if (nestedForms.length) {
      console.log('Forms contains nested forms');
      const apiCalls: Observable<any>[] = [];
      const nestedComponentsMap = new Map();
      nestedForms.forEach((subForm) => {
        console.log('nested subform: ', subForm);
        const contextPrefix = subForm.component.contextPrefix;
        if (
          subForm.component.formId === '{{formId}}' &&
          propagatedState &&
          Object.prototype.hasOwnProperty.call(propagatedState, 'formId')
        ) {
          subForm.component.formId = propagatedState.formId;
        }
        let existingMapping: any[] = nestedComponentsMap.get(
          subForm.component.formId
        );
        if (_.isEmpty(existingMapping)) {
          existingMapping = [];
        }
        existingMapping.push({
          parentComponent: subForm.parentComponent,
          contextPrefix: contextPrefix,
          key: subForm.component.key
        });

        nestedComponentsMap.set(subForm.component.formId, existingMapping);
        console.log(
          'Subform: ',
          subForm,
          subForm.component.code,
          subForm.component.formId
        );
        apiCalls.push(this.formService.getById(subForm.component.formId));
      });
      return new Promise<any>((resolve, reject) => {
        forkJoin(apiCalls).subscribe((subForms) => {
          subForms.forEach((subForm) => {
            if (subForm?.components) {
              console.log('Embedding: ', subForm);
              let indexOfSubform = components.findIndex(
                (component) => component.formId === subForm.id
              );
              console.log(indexOfSubform);
              if (indexOfSubform == -1) {
                //index will be -1 when formId is supposed to be injected dynamically.
                indexOfSubform = 0;
              }
              const nestedComponentInfo = nestedComponentsMap.get(subForm.code);

              //Same view might be used multiple times in a screen. Hence, there can be multiple parentComponents.
              if (nestedComponentInfo && nestedComponentInfo.length) {
                const parentComponent = nestedComponentInfo[0].parentComponent;
                const contextPrefix = nestedComponentInfo[0].contextPrefix;
                nestedComponentInfo.shift();
                parentComponent.components[indexOfSubform] = {
                  collapsible: false,
                  hideLabel: true,
                  key: parentComponent.components[indexOfSubform].key,
                  type: 'panel',
                  label: 'Dynamic Panel',
                  input: false,
                  tableView: false,
                  components: []
                };

                // @ts-ignore
                const subFormComponentSchema = JSON.parse(
                  <string>subForm?.components[0]
                );
                subFormComponentSchema.forEach((schema) => {
                  if (contextPrefix && schema) {
                    if (schema.components) {
                      this.formRenderUtils.prefixKeys(
                        contextPrefix,
                        schema.components
                      );
                      this.formRenderUtils.hideButtons(schema.components);
                    } else if (schema.hasOwnProperty('key')) {
                      schema.key = contextPrefix + '.' + schema.key;
                    }
                  } else if (schema && schema.components) {
                    this.formRenderUtils.hideButtons(schema.components);
                  }
                  // @ts-ignore
                  parentComponent.components[indexOfSubform].components.push(
                    schema
                  );
                  // @ts-ignore
                  console.log('Injected!!', schema);
                });
              } else {
                components[indexOfSubform] = {
                  collapsible: false,
                  hideLabel: true,
                  key: components[indexOfSubform].key,
                  type: 'panel',
                  label: 'Dynamic Panel',
                  input: false,
                  tableView: false,
                  components: []
                };
                // @ts-ignore
                const subFormComponentSchema = JSON.parse(
                  <string>subForm?.components[0]
                );
                subFormComponentSchema.forEach((schema) => {
                  this.formRenderUtils.hideButtons(schema.components);
                  // @ts-ignore
                  components[indexOfSubform].components.push(schema);
                });
              }
            }
            console.log('At the end: ', components);
          });

          resolve(components);
        });
      });
    } else {
      //Resolve without the need of processing nested forms.
      return Promise.resolve(components);
    }
  }

  private renderFormSeriously(propagatedState: any) {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const _self = this;
    FormRendererUtils.addAuthHeaderOnSelectComponents(this.form.components);
    this.formRenderUtils.checkAclPermission(this.form.components);

    Formio.Headers(
      new Headers({
        Authorization: 'Bearer ' + IamAuthUtils.getCurrentUserToken()
      })
    );

    //This tokens object will be used by XHR requests from within the JS framework to append the headers on request.
    // @ts-ignore
    Formio.tokens = {
      Authorization: 'Bearer ' + IamAuthUtils.getCurrentUserToken()
    };
    localStorage.setItem('basePathForFileProvider', '/api/core'); //TODO: This path should be moved to configuration

    Formio.createForm(
      document.getElementById('formio'),
      {
        components: this.form.components
      },
      {
        hooks: {
          beforeSubmit: (submission, next) => {
            _self.performBeforeSubmit(submission);
            next();
          }
        }
      }
    ).then((form) => {
      let screenConfig = ScreenConfigurationUtil.getStaticScreenConfig(form);
      const screenInitializationConfig =
        ScreenConfigurationUtil.getScreenInitializationConfig(form);
      const screenActionHandlers =
        ScreenConfigurationUtil.getActionHandlers(form);
      this.formRenderUtils.addActionListeners(
        form,
        form.components,
        screenActionHandlers,
        form.submission
      );

      form['propagatedState'] = propagatedState;

      form['parentId'] = this.id;

      _self.formInstance = form;
      console.log(
        'Triggering EventType.FORM_READY on eventBusService {} form: ',
        form,
        ' propagatedState: ',
        propagatedState
      );
      this.eventBusService.emit(EventData.of(EventType.FORM_READY, form));
      form.nosubmit = true; //Disabled default submit action
      if (screenConfig.isEmpty()) {
        console.warn(
          'Deprecated usage of Screen Config method. Use ScreenConfig component instead.'
        );
        screenConfig = ScreenConfigurationUtil.getScreenConfig(form);
      }
      screenConfig['persistent'] = 'client-only';

      const formDataLoaded$ = new Subject();
      formDataLoaded$
        .asObservable()
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe((formData) => {
          if (formData != null) {
            formData['propagatedState'] = propagatedState;
            formData['parentId'] = this.id;
            console.log(
              'formData: ',
              formData,
              ' propagatedState: ',
              propagatedState
            );
            form.submission.data = formData;
            form.submission.propagatedState = propagatedState;
            form.submission.data.propagatedState = propagatedState;
            console.log(
              'Triggering EventType.FORM_DATA_LOADED on eventBusService {}',
              new Date()
            );
            _self.eventBusService.emit(
              EventData.of(EventType.FORM_DATA_LOADED, formData)
            );
            this.formRenderUtils.resolveDynamicHtmlContent(form.components, {data: formData});
            this.formRenderUtils.resolveDynamicLabels(
              form.components,
              formData
            );
            //this.formRenderUtils.resolveDataGridComponentAttributes(form.components, { data: formData });
            //this.renderEmbeddedReportsIfEnclosedInDataGrid(form.components, { data: formData });

            try {
              //this.formRenderUtils.showNestedFormsDiff(['proposedChangesNestedForm', 'currentVersionNestedForm'], this.formInstance);
              console.log(
                'all-forms-render-components :: Redrawing formInstance'
              );
              _self.formInstance.redraw(); //Redrawing so that the assigned values get reflected
            } catch (e) {
              console.log('exception caught while redraw', e);
            }
          }
        });
      if (screenInitializationConfig && screenInitializationConfig.length) {
        this.initializeScreenSeriously(
          screenInitializationConfig,
          0,
          form,
          propagatedState,
          formDataLoaded$
        );
      } else {
        this.processScreenConfig(
          screenConfig,
          form,
          propagatedState,
          formDataLoaded$
        );
      }

      this.addSubmitHandler(screenConfig, form);
    });
  }

  private processScreenConfig(
    screenConfig,
    form,
    propagatedState,
    formDataLoaded$
  ) {
    const _self = this;

    if (screenConfig.retrievalUrl) {
      let retrievalUrl = screenConfig.retrievalUrl;
      if (this.formRenderUtils.needsInterpolation(screenConfig.retrievalUrl)) {
        retrievalUrl = LodashTemplateEvaluator.interpolate(
          screenConfig.retrievalUrl,
          {propagatedState: propagatedState}
        );
      } else if (_self.id) {
        retrievalUrl = screenConfig.retrievalUrl + '/' + _self.id;
      }

      const spinner = document.getElementById("spinner");
      if (spinner) {
        spinner.removeAttribute('hidden');
      }
      Formio.fetch(retrievalUrl, {
        headers: {
          'content-type': 'application/json',
          Authorization: 'Bearer ' + IamAuthUtils.getCurrentUserToken()
        }
      }).then(function (response) {
        response.json().then(function (result) {
          if (result && !result.errorCode) {
            const shouldShowPendingChanges =
              propagatedState &&
              propagatedState.hasOwnProperty('showPendingChanges') &&
              propagatedState.showPendingChanges &&
              result.pendingChanges;
            if (screenConfig.hasAdditionalConfig()) {
              _self.fetchAdditionalData(
                screenConfig.additionalConfig,
                0,
                result,
                formDataLoaded$,
                propagatedState,
                shouldShowPendingChanges
              );
            } else {
              if (shouldShowPendingChanges) {
                const modifiedData = result.pendingChanges;
                modifiedData.propagatedState = propagatedState;
                form.submission = {data: modifiedData};
                formDataLoaded$.next(modifiedData);
              } else {
                form.submission = {data: result};
                formDataLoaded$.next(result);
              }
              if (spinner) {
                spinner.setAttribute('hidden', '');
              }
            }
          } else if (result?.errorCode) {
            const spinner = document.getElementById("spinner");
            if (spinner) {
              spinner.setAttribute('hidden', '');
            }
            _self.handleApiError(response, result);
          }
        });
      });
    } else if (screenConfig.hasAdditionalConfig()) {
      //added Additional API config for create screen config, currently its configured only for Action and Data Display
      _self.fetchAdditionalData(
        screenConfig.additionalConfig,
        0,
        {},
        formDataLoaded$,
        propagatedState,
        false
      );
    }
  }

  private initializeScreenSeriously(
    screenInitializationConfig: ScreenInitializationConfig[],
    currentIndex: number,
    form: any,
    propagatedState: any,
    formDataLoaded$: Subject<any>
  ) {
    const _self = this;
    const config = screenInitializationConfig[currentIndex];
    let apiUrl = config.apiUrl;
    if (this.formRenderUtils.needsInterpolation(apiUrl)) {
      //To use any attribute from propagatedState, use {{propagatedState.attributeName}} as the reference
      apiUrl = LodashTemplateEvaluator.interpolate(apiUrl, {
        propagatedState: propagatedState
      });
      //To use any attribute from the accumulated API response so far in the chain, use {{data.attributeName}} as the reference
      apiUrl = LodashTemplateEvaluator.interpolate(apiUrl, {
        data: form.submission.data
      });
    }

    let requestBody = config.requestBody;
    if (!_.isEmpty(requestBody)) {
      requestBody = LodashTemplateEvaluator.interpolate(requestBody, {
        propagatedState: propagatedState
      });
      requestBody = LodashTemplateEvaluator.interpolate(requestBody, {
        data: form.submission.data
      });
      requestBody = FormioUtils.evaluate(
        requestBody,
        form.submission,
        'requestBody'
      );
    }

    Formio.fetch(apiUrl, {
      body: requestBody,
      headers: {
        'content-type': 'application/json',
        Authorization: 'Bearer ' + IamAuthUtils.getCurrentUserToken()
      },
      method: config.apiMethod
    }).then(function (response) {
      response.json().then(function (result) {
        console.log('Processed ', config.apiUrl);
        if (result && !result.errorCode) {
          const apiResponse = config.dataPath
            ? _.get(result, config.dataPath)
            : result;
          if (config.storageAttribute.trim().charAt(0) === '*') {
            form.submission.data = Object.assign(
              form.submission.data,
              apiResponse
            );
          } else {
            form.submission.data[config.storageAttribute] = apiResponse;
          }
          console.log('API ', config.apiUrl, ' result: ', result);
          if (config.responseProcessor) {
            let responseProcessor = config.responseProcessor;
            responseProcessor = LodashTemplateEvaluator.interpolate(
              responseProcessor,
              {propagatedState: propagatedState}
            );
            responseProcessor = LodashTemplateEvaluator.interpolate(
              responseProcessor,
              {data: form.submission.data}
            );
            FormioUtils.evaluate(
              responseProcessor,
              form.submission,
              'response'
            );
          }
        } else if (result?.errorCode) {
          //_self.handleApiError(response, result);
          console.log('Error while calling API ', config.apiUrl, result);
        }
        if (currentIndex != screenInitializationConfig.length - 1) {
          _self.initializeScreenSeriously(
            screenInitializationConfig,
            currentIndex + 1,
            form,
            propagatedState,
            formDataLoaded$
          );
        } else {
          //All configured APIs are processed
          console.log(
            'All API Data loaded. Triggering formDataLoaded$ Event ',
            config.apiUrl,
            result
          );
          formDataLoaded$.next(form.submission.data);
        }
      });
    });
  }


  private renderEmbeddedReportsIfEnclosedInDataGrid(components: any[], formData: any) {
    const embeddedReports = FormRendererUtils.getComponentByType(
      'embedded-report',
      components
    );

    const dataGridComponents: Map<string, any> = new Map();
    if (embeddedReports) {
      embeddedReports.forEach(embeddedReport => {
        console.log('Parent Component: ', embeddedReport.parentComponent);
        if (!dataGridComponents.has(embeddedReport.parentComponent.id)) {
          dataGridComponents.set(embeddedReport.parentComponent.id, embeddedReport.parentComponent);
        }
      })
    }

    if (dataGridComponents.size) {
      console.log('DataGrids with embedded reports: ', dataGridComponents);
      dataGridComponents.forEach((dataGrid, key) => {
        const embeddedReportsInThisDataGrid = FormRendererUtils.getComponentByType('embedded-report', dataGrid.components);
        const dataGridValue = dataGrid.getValue();
        console.log(dataGridValue);
      })
    }
  }
}
