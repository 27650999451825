import {
  CustomButtonActionHandler,
  CustomButtonActionHandlerContext
} from '@shared/components/custom-action-button/handlers/custom-button-action-handler';
import { CustomButtonActionType } from '@shared/components/custom-action-button/handlers/custom-button-action-handler-registry';
import { FormRendererUtils } from '../../../../portal/application-forms/all-forms-renderer/form-renderer-utils';
import { Injectable } from '@angular/core';
import _ from 'lodash';
import { LodashTemplateEvaluator } from '@shared/components/smart-table/LodashTemplateEvaluator';

@Injectable({ providedIn: 'root' })
export class SetFieldValueActionProcessor implements CustomButtonActionHandler {
  isApplicable(actionType: CustomButtonActionType): boolean {
    return actionType === CustomButtonActionType.setFieldValues;
  }

  async performAction(context: CustomButtonActionHandlerContext) {
    let actionProcessor = context.actionProcessor;
    actionProcessor.fieldValuesConfig
      .filter((fieldValueConfig) => !_.isNil(fieldValueConfig.fieldValue))
      .forEach((fieldValueConfig) => {
        let component = FormRendererUtils.getComponent(
          fieldValueConfig.fieldName,
          context.formInstance.components
        );
        let fieldValue = fieldValueConfig.fieldValue;
        let scriptValue = LodashTemplateEvaluator.interpolate(fieldValue, {
          formInstance: context.formInstance,
          data: context.formData
        });
        let result = FormRendererUtils.evaluate(
          scriptValue,
          {
            submission: context.formInstance.submission,
            formInstance: context.formInstance,
            data: context.formInstance.submission.data
          },
          'value',
          true
        );

        let existingValue = _.get(
          context.formInstance.submission.data,
          fieldValueConfig.fieldName
        );
        let valueToSet = result;
        if (fieldValueConfig.appendIfArray && existingValue) {
          existingValue.push(result);
          valueToSet = existingValue;
        }
        let componentType = undefined;
        if (component) {
          component.setValue(valueToSet);
          componentType = component.type;
        } else {
          console.log(
            'setFieldValueActionProcessor :: no component found with name ',
            fieldValueConfig.fieldName
          );
          _.set(
            context.formInstance.submission.data,
            fieldValueConfig.fieldName,
            result
          );
        }
      });

    return Promise.resolve();
  }
}
