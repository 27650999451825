import { CustomButtonActionHandler } from './custom-button-action-handler';
import { SendDataToApiActionProcessor } from '@shared/components/custom-action-button/action-processors/send-data-to-api-action-processor';
import { ShowPopupActionProcessor } from '@shared/components/custom-action-button/action-processors/show-popup-action-processor';
import { Injectable } from '@angular/core';
import { NavigateToUrlActionProcessor } from '@shared/components/custom-action-button/action-processors/navigate-to-url-action-processor';
import { ExecuteCustomLogicActionProcessor } from '@shared/components/custom-action-button/action-processors/execute-custom-logic-action-processor';
import { CloseAllPopupsActionProcessor } from '@shared/components/custom-action-button/action-processors/close-all-popups-action-processor';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { RedrawComponentsActionProcessor } from '@shared/components/custom-action-button/action-processors/redraw-components-action-processor';
import { SetFieldValueActionProcessor } from '@shared/components/custom-action-button/action-processors/set-field-value-action-processor';
import { ToggleComponentsVisibilityActionProcessor } from '@shared/components/custom-action-button/action-processors/toggle-components-visibility-action-processor';
import { TriggerEventActionProcessor } from '@shared/components/custom-action-button/action-processors/trigger-event-action-processor';
import {
  GoBackActionProcessor
} from "@shared/components/custom-action-button/action-processors/go-back-action-processor";

@Injectable({ providedIn: 'root' })
export class CustomButtonActionHandlerRegistry {
  handlers: CustomButtonActionHandler[] = [];

  constructor(
    private showPopupActionProcessor: ShowPopupActionProcessor,
    private sendDataToApiActionProcessor: SendDataToApiActionProcessor,
    private navigationActionProcessor: NavigateToUrlActionProcessor,
    private customLogicActionProcessor: ExecuteCustomLogicActionProcessor,
    private closePopupsActionProcessor: CloseAllPopupsActionProcessor,
    private redrawComponentsActionProcessor: RedrawComponentsActionProcessor,
    private fieldValuesActionProcessor: SetFieldValueActionProcessor,
    private toggleComponentsVisibilityActionProcessor: ToggleComponentsVisibilityActionProcessor,
    private triggerEventActionProcessor: TriggerEventActionProcessor,
    private goBackActionProcessor: GoBackActionProcessor
  ) {
    //TODO: Make this registration dynamic
    this.handlers.push(showPopupActionProcessor);
    this.handlers.push(sendDataToApiActionProcessor);
    this.handlers.push(navigationActionProcessor);
    this.handlers.push(customLogicActionProcessor);
    this.handlers.push(customLogicActionProcessor);
    this.handlers.push(closePopupsActionProcessor);
    this.handlers.push(redrawComponentsActionProcessor);
    this.handlers.push(fieldValuesActionProcessor);
    this.handlers.push(toggleComponentsVisibilityActionProcessor);
    this.handlers.push(triggerEventActionProcessor);
    this.handlers.push(goBackActionProcessor);
  }

  public setDialogRef(ref: DynamicDialogRef) {
    this.closePopupsActionProcessor.setDialogRef(ref);
  }
  provider(actionType: CustomButtonActionType): CustomButtonActionHandler {
    const customButtonActionHandlers = this.handlers.filter((h) => {
      return h.isApplicable(actionType);
    });
    return customButtonActionHandlers[0];
  }
}

export enum CustomButtonActionType {
  goBack = 'goBack',
  closeAllPopups = 'closeAllPopups',
  downloadFile = 'downloadFile',
  executeCustomLogic = 'executeCustomLogic',
  getDataFromApi = 'getDataFromApi',
  navigateToUrl = 'navigateToUrl',
  redrawComponents = 'redrawComponents',
  resetSmartTableResults = 'resetSmartTableResults',
  sendDataToApi = 'sendDataToApi',
  toggleComponentsVisibility = 'toggleComponentsVisibility',
  triggerEvent = 'triggerEvent',
  setFieldValues = 'setFieldValues',
  showPopup = 'showPopup',
  smartTableAdvanceFilterAction = 'smartTableAdvanceFilterAction'
}

export enum CustomActionType {
  navigate = 'navigate',
  advanceFilterAction = 'applyFilter',
  resetSmartTableResults = 'resetSmartTableResults',
  showPopup = 'showPopup',

  getDataFromApi = 'getDataFromApi',
  executeScriptAndShowPopup = 'executeScriptAndShowPopup',

  downloadFile = 'downloadFile',

  others = 'others',

  postToUrl = 'postToUrl'
}

export enum AfterPostToUrlAction {
  showPopup = 'showPopup',
  navigate = 'navigate',
  showPopupAndNavigate = 'showPopupAndNavigate',
  noAction = 'noAction',
  downloadFile = 'downloadFile',
  processApiResponse = 'processApiResponse'
}
