<div class="tmt-grid-component smart-table">
  <div class="grid-data-display"> <!-- border-supp-1 -->

    <div class="list-view">
      <div class="grid-header d-flex {{gridOptions?.gridHeaderClass}}">
        <div class="child screen-title flex-grow-2  w-50 {{gridOptions?.gridTitleClass}}">
          <span class="text-primary h3">
               {{isTitleFormattingRequired ? formattedTitle : gridOptions?.title}}
          </span>
        </div>
        <div class="child w-50 input-group border-0 justify-content-end {{gridOptions?.gridSearchControlClass}}">
          <button *ngIf="gridOptions?.refreshEnabled" aria-expanded="false" (click)="refreshGrid()" aria-haspopup="true"
                  class="btn btn-outline-primary br-0" type="button" pTooltip="Refresh Grid Data"
                  [tooltipPosition]="'top'">
            <i class="fa-solid fa-rotate"></i>
          </button>

          <ng-container *ngIf="gridOptions?.globalSearchEnabled">
            <button aria-expanded="false" aria-haspopup="true"
                    class="btn btn-outline-primary dropdown-toggle dropdown-toggle-split br-0" data-bs-toggle="dropdown"
                    type="button">
              <span class="mr-2">Filter</span>
            </button>
            <div class="dropdown-menu ">
              <a (click)="changeFilter(filterOption.key)"
                 *ngFor="let filterOption of globalSearchOperatorOptions"
                 [ngClass]="{'active' : globalSearchFilterOperator == filterOption.key}"
                 class="dropdown-item"
                 href="javascript:"> <i class="fa-solid fa-check"
                                        [ngClass]="{'invisible' : globalSearchFilterOperator !== filterOption.key}"></i>  {{filterOption.label}}
              </a>
            </div>
            <input #globalFilterTextInput (keydown.enter)="filterGlobal(globalFilterTextInput.value)"
                   [(ngModel)]="globalSearchText"
                   aria-label="Text input with segmented dropdown button" class="form-control"
                   placeholder="{{gridOptions?.globalSearchPlaceholder}}" type="text">
            <button (click)="filterGlobal(globalFilterTextInput.value)" class="btn btn-primary" type="button"><i
              class="far fa-search"></i></button>

          </ng-container>

          <div *ngIf="gridOptions?.advancedSearchEnabled"> <!-- pTooltip="Advanced Filter" positionStyle="absolute"
                                               tooltipPosition="top" [positionTop]="10"  -->
            <button #filterButton (click)="toggleAdvancedFilter()" class="btn btn-outline-primary ml-2" type="button">
              Advanced Filter <!--<i class="fal fa-sliders-h"></i>-->
              <i class="ml-2"
                 [ngClass]="{' fa-filter' : isAdvancedFilterVisible, ' fa-filter-circle-xmark' : !isAdvancedFilterVisible}"
                 class="fa-solid fa-xl "> </i>
            </button>
          </div>

          <div *ngIf="gridOptions?.importDataEnabled">
          <!--  <button aria-expanded="false" (click)="importData()" aria-haspopup="true"
                    class="btn btn-link  br-0" type="button" pTooltip="Import Data"
                    [tooltipPosition]="'top'">
              <i class="fa-solid fa-file-import fa-flip-horizontal"></i>
            </button>-->

            <p-fileUpload #fileUpload mode="basic" name="importFile[]" styleClass="ifas-file-upload-listing-screen"
                          [chooseLabel]="gridOptions?.importButtonDataLabel"
                          [uploadLabel]="gridOptions?.importButtonDataLabel"
                          [maxFileSize]="1000000"
                          (onSelect)="onFileSelect($event, fileUpload)"
                          (uploadHandler)="documentUpload($event, fileUpload)"
                          [auto]="true"></p-fileUpload>


          </div>
          <div *ngIf="gridOptions?.exportToExcel"> <!-- pTooltip="Advanced Filter" positionStyle="absolute"
                                               tooltipPosition="top" [positionTop]="10"  -->
            <button  aria-expanded="false" (click)="exportExcel()" aria-haspopup="true"
                    class="btn  btn-link br-0" type="button" pTooltip="Export to Excel"
                    [tooltipPosition]="'top'">
              <i class="fa-sharp fa-solid fa-file-excel"></i>
            </button>
          </div>



        </div>


      </div>
      <div class="grid-body border-supp-1">
        <p-table (onLazyLoad)="loadData($event)"

                 (selectionChange)="onSelectionChange($event)"
                 [(first)]="first"
                 [columns]="gridOptions?.columnDefinitions"
                 [lazy]="true"
                 [loading]="loading"
                 [paginator]="gridOptions?.pagination"
                 [resizableColumns]="gridOptions?.resizableColumns"
                 columnResizeMode="expand"
                 [rowHover]="gridOptions?.rowHover"
                 [rowsPerPageOptions]="gridOptions?.paginationRowsPerPageOptions"
                 [rows]="paginationPageSizeCurrent"
                 [scrollable]="false"
                 [showCurrentPageReport]="true"
                 [styleClass]="gridOptions?.styleClass"
                 [totalRecords]="totalRecords"
                 [value]="data"
                 currentPageReportTemplate="Showing {first} to {last} of {totalRecords} records"
                 [dataKey]="gridOptions?.selectedRecordIdentifierField">


          <ng-template let-columnDefinitions pTemplate="header">
            <tr class="{{gridOptions?.headerRowClass}}">
              <th *ngIf="gridOptions?.checkboxSelection || gridOptions?.radioSelection || gridOptions?.needsRowExpansion || gridOptions?.dynamicRowPrefix" style="width:2rem">
                <p-tableHeaderCheckbox *ngIf="gridOptions?.checkboxSelection"></p-tableHeaderCheckbox>
              </th>
              <th *ngIf="gridOptions?.slnoColumnRequired" style="gridOptions?.slnoColumnStyle">
                <span *ngIf="gridOptions?.slnoColumnRequired" class="slno">{{gridOptions?.slnoLabel}}</span>
              </th>

              <ng-container *ngFor="let columnDef of columnDefinitions; index as i">
                <!--[pResizableColumnDisabled]="!columnDef.resizable"-->
                <th [ngClass]="{ 'd-none': columnDef.hide }"
                    [pSortableColumnDisabled]="!columnDef.sortable" [pSortableColumn]="columnDef.field"
                    class="{{columnDef.headerClass}}"
                    pResizableColumn style="{{columnDef.thStyle}}">
                  <div [title]="columnDef.headerTooltip" class="flex justify-content-between align-items-center">
                    <span [innerHTML]="columnDef.headerName"></span>
                    <p-sortIcon *ngIf="columnDef.sortable" [field]="columnDef.field">
                    </p-sortIcon>
                  </div>
                </th>
              </ng-container>

          </ng-template>
          <ng-template let-columnDefinitions="columns" let-data let-k="rowIndex" pTemplate="body"
                       let-expanded="expanded">
            <tr [ngClass]="{'p-selectable-row': gridOptions?.checkboxSelection}" class="{{gridOptions?.dataRowClass + ' ' + data?.dynamicDataRowClass}}">

              <td *ngIf="gridOptions?.checkboxSelection || gridOptions?.radioSelection || gridOptions?.needsRowExpansion || gridOptions?.dynamicRowPrefix">
                <div class="d-flex align-items-center">
                    <span [innerHTML]="data?.dynamicRowPrefix | safeHtml"></span>

                    <p-tableCheckbox *ngIf="gridOptions?.checkboxSelection" [value]="data"></p-tableCheckbox>

                    <p-tableRadioButton  *ngIf="gridOptions?.radioSelection" [value]="data"></p-tableRadioButton>

                    <ng-container *ngIf=" gridOptions?.needsRowExpansion && data[expansionRowGridOptions.childRowsAttribute!]?.length > 0">
                      <button type="button" pButton pRipple [pRowToggler]="data" class="p-button-text p-button-rounded "
                              [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
                    </ng-container>
                </div>
              </td>

              <td *ngIf="gridOptions?.slnoColumnRequired">
                <span class="ml-2" *ngIf="gridOptions?.slnoColumnRequired">{{k + 1}}</span>
              </td>

              <ng-container *ngFor="let columnDef of columnDefinitions; index as i;">

                <td [ngClass]="{'d-none': columnDef.hide}" style="{{columnDef.tdStyle}}">
                  <ng-container
                    *ngIf="!columnDef.displayValueFormatters || columnDef.displayValueFormatters.length == 0">
                    <div *ngIf="data?.defaultRendering && i < data?.defaultRendering?.length" [innerHTML]="data?.defaultRendering[i] | safeHtml" [title]="tooltipValueGetter?.(data, columnDef)"
                         class="p-column-title">
                    </div>
                  </ng-container>

                  <div *ngIf="columnDef.displayValueFormatters && columnDef.displayValueFormatters.length > 0">
                    <div class="p-column-title d-flex custom-formatter-column">
                      <div
                        *ngFor="let actionableHTMLFragment of data?.advancedRendering[i]; index as displayFormatterIndex;">

                        <!-- No Action Templates-->
                        <ng-container *ngIf="actionableHTMLFragment?.displayFormatter?.category == 'no_action' && hasAccess(actionableHTMLFragment?.displayFormatter?.aclResource)">
                          <div [innerHTML]="actionableHTMLFragment?.displayValue | safeHtml"
                               title="{{actionableHTMLFragment?.displayFormatter?.toolTip}}"
                               class="display-{{actionableHTMLFragment?.displayFormatter?.displayType}} {{actionableHTMLFragment?.displayFormatter?.iconClass}} {{actionableHTMLFragment?.displayFormatter?.customClass}}">
                          </div>
                        </ng-container>
                        <!-- Action Templates-->
                        <ng-container
                          *ngIf="actionableHTMLFragment?.displayFormatter?.category !== 'no_action' && actionableHTMLFragment?.value?.length > 0 &&  actionableHTMLFragment?.value !== 'DO_NOT_DISPLAY' &&
                          hasAccess(actionableHTMLFragment?.displayFormatter?.aclResource)">
                          <!-- display actions only when it has some value, epmty value means conditionally hide the action -->
                          <div *ngIf="actionableHTMLFragment?.displayFormatter?.displayType == 'href' "
                               class="action display-{{actionableHTMLFragment?.displayFormatter?.displayType}}">
                            <i *ngIf="actionableHTMLFragment?.displayFormatter?.iconClass"
                               class="{{actionableHTMLFragment?.displayFormatter?.iconClass}}"></i>
                            <a (click)="handleGridAction(data, actionableHTMLFragment)"
                               [innerHTML]="actionableHTMLFragment?.displayValue | safeHtml"
                               class="{{actionableHTMLFragment?.displayFormatter?.styleClass}}"
                               href="javascript:void(0)"></a>
                          </div>
                          <div *ngIf="actionableHTMLFragment?.displayFormatter?.displayType == 'button'"
                               class="action display-{{actionableHTMLFragment?.displayFormatter?.displayType}}">
                            <button pButton (click)="handleGridAction(data, actionableHTMLFragment)"
                                      icon="{{actionableHTMLFragment?.displayFormatter?.iconClass}}"
                                      label="{{actionableHTMLFragment?.displayValue}}"
                                      class="{{actionableHTMLFragment?.displayFormatter?.styleClass}}"
                                    title="{{actionableHTMLFragment?.toolTip}}"
                                    [attr.disabled]="actionableHTMLFragment?.disabled">
                            </button>
                         <!--   {{actionableHTMLFragment?.displayFormatter?.disabled}}-->
                          </div>
                          <div *ngIf="actionableHTMLFragment?.displayFormatter?.displayType == 'html'"
                               class="action display-{{actionableHTMLFragment?.displayFormatter?.displayType}} ">

                            <div (click)="handleGridAction(data, actionableHTMLFragment)"
                                 class="{{actionableHTMLFragment?.displayFormatter?.styleClass}}">
                              <i *ngIf="actionableHTMLFragment?.displayFormatter?.iconClass"
                                 class="{{actionableHTMLFragment?.displayFormatter?.iconClass}}"></i>
                              <span [innerHTML]="actionableHTMLFragment?.displayValue | safeHtml"></span>
                            </div>
                          </div>
                        </ng-container>
                      </div>
                    </div>
                  </div>

                </td>
              </ng-container>

            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage">
            <tr>
              <td
                [attr.colspan]="getColspan(gridOptions, gridOptions)"
                class="{{gridOptions?.noDataLabelStyleClass}}">{{gridOptions?.noDataLabel}}</td>
            </tr>
          </ng-template>
          <ng-template let-sortOrder pTemplate="sorticon">
            <i *ngIf="sortOrder === 0" class="fa-solid fa-sort"></i>
            <i *ngIf="sortOrder === 1" class="fa-solid fa-sort-up"></i>
            <i *ngIf="sortOrder === -1" class="fa-solid fa-sort-down"></i>
          </ng-template>

          <ng-template pTemplate="rowexpansion" let-data>
            <tr *ngIf="data[expansionRowGridOptions.childRowsAttribute!]?.length > 0">
              <!-- *ngIf="data[expansionRowGridOptions.childRowsAttribute!]?.length > 0" -->
              <td  [attr.colspan]="getColspan(gridOptions, expansionRowGridOptions)">
                <div class="p-3">
                  <p-table  [value]="data[expansionRowGridOptions.childRowsAttribute!]"
                            [columns]="expansionRowGridOptions?.columnDefinitions"
                            [styleClass]="expansionRowGridOptions?.styleClass"
                            [rowHover]="expansionRowGridOptions?.rowHover"
                            [dataKey]="expansionRowGridOptions?.selectedRecordIdentifierField" >

                    <ng-template pTemplate="header" let-expandedColumnDefinitions >
                      <tr class="{{expansionRowGridOptions?.headerRowClass}}">
                        <th *ngIf="expansionRowGridOptions?.slnoColumnRequired" style="gridOptions?.slnoColumnStyle">
                          <span *ngIf="expansionRowGridOptions?.slnoColumnRequired" class="slno">{{expansionRowGridOptions?.slnoLabel}}</span>
                        </th>
                        <ng-container *ngFor="let columnDef of expandedColumnDefinitions; index as j">
                            <th>
                              <div class="flex justify-content-between align-items-center">
                                <span [innerHTML]="columnDef.headerName | safeHtml"></span>
                                <p-sortIcon *ngIf="columnDef.sortable" [field]="columnDef.field"></p-sortIcon>
                              </div>
                            </th>
                        </ng-container>
                      </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-data let-k="rowIndex"  let-expandedColumnDefinitions="columns">
                      <tr>
                        <td *ngIf="expansionRowGridOptions?.slnoColumnRequired">
                          <span class="ml-2" *ngIf="expansionRowGridOptions?.slnoColumnRequired">{{k + 1}}</span>
                        </td>
                        <!-- Child Table body START -->
                         <ng-container *ngFor="let columnDef of expandedColumnDefinitions; index as childRowIndex;">

                          <td [ngClass]="{'d-none': columnDef.hide}" style="{{columnDef.tdStyle}}">
                            <ng-container
                              *ngIf="!columnDef.displayValueFormatters || columnDef.displayValueFormatters.length == 0">
                              <div [innerHTML]="data?.defaultRendering[childRowIndex] | safeHtml" [title]="tooltipValueGetter?.(data, columnDef)"
                                   class="p-column-title">
                              </div>
                            </ng-container>

                            <div *ngIf="columnDef.displayValueFormatters && columnDef.displayValueFormatters.length > 0">
                              <div class="p-column-title d-flex custom-formatter-column">
                                <div
                                  *ngFor="let actionableHTMLFragment of data?.advancedRendering[childRowIndex]; index as displayFormatterIndex;">

                                  <!-- No Action Templates-->
                                  <ng-container *ngIf="actionableHTMLFragment?.displayFormatter?.category == 'no_action' && hasAccess(actionableHTMLFragment?.displayFormatter?.aclResource)">
                                    <div [innerHTML]="actionableHTMLFragment?.displayValue | safeHtml"
                                         [title]="actionableHTMLFragment?.displayFormatter?.toolTip"
                                         class="display-{{actionableHTMLFragment?.displayFormatter?.displayType}} {{actionableHTMLFragment?.displayFormatter?.iconClass}} {{actionableHTMLFragment?.displayFormatter?.customClass}}">
                                    </div>
                                  </ng-container>
                                  <!-- Action Templates-->
                                  <ng-container
                                    *ngIf="actionableHTMLFragment?.displayFormatter?.category !== 'no_action' && actionableHTMLFragment?.value?.length > 0 &&  actionableHTMLFragment?.value !== 'DO_NOT_DISPLAY' &&
                          hasAccess(actionableHTMLFragment?.displayFormatter?.aclResource)">
                                    <!-- display actions only when it has some value, epmty value means conditionally hide the action -->
                                    <div *ngIf="actionableHTMLFragment?.displayFormatter?.displayType == 'href'"
                                         class="action display-{{actionableHTMLFragment?.displayFormatter?.displayType}}">
                                      <i *ngIf="actionableHTMLFragment?.displayFormatter?.iconClass"
                                         class="{{actionableHTMLFragment?.displayFormatter?.iconClass}}"></i>
                                      <a (click)="handleGridAction(data, actionableHTMLFragment)"
                                         [innerHTML]="actionableHTMLFragment?.displayValue | safeHtml"
                                         class="{{actionableHTMLFragment?.displayFormatter?.styleClass}}"
                                         href="javascript:void(0)"></a>
                                    </div>
                                    <div *ngIf="actionableHTMLFragment?.displayFormatter?.displayType == 'button'"
                                         class="action display-{{actionableHTMLFragment?.displayFormatter?.displayType}}">
                                      <p-button (onClick)="handleGridAction(data, actionableHTMLFragment)"
                                                icon="{{actionableHTMLFragment?.displayFormatter?.iconClass}}"
                                                label="{{actionableHTMLFragment?.displayValue}}"
                                                styleClass="{{actionableHTMLFragment?.displayFormatter?.styleClass}}"
                                                title="{{actionableHTMLFragment?.toolTip}}"
                                                [attr.disabled]="actionableHTMLFragment?.disabled">
                                      </p-button>
                                    </div>
                                    <div *ngIf="actionableHTMLFragment?.displayFormatter?.displayType == 'html'"
                                         class="action display-{{actionableHTMLFragment?.displayFormatter?.displayType}} ">

                                      <div (click)="handleGridAction(data, actionableHTMLFragment)"
                                           class="{{actionableHTMLFragment?.displayFormatter?.styleClass}}">
                                        <i *ngIf="actionableHTMLFragment?.displayFormatter?.iconClass"
                                           class="{{actionableHTMLFragment?.displayFormatter?.iconClass}}"></i>
                                        <span [innerHTML]="actionableHTMLFragment?.displayValue | safeHtml"></span>
                                      </div>
                                    </div>
                                  </ng-container>
                                </div>
                              </div>
                            </div>

                          </td>
                        </ng-container>
                        <!-- Child Table Body END-->
                      </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage">
                      <tr>
                        <td colspan="6">No records to display</td>
                      </tr>
                    </ng-template>
                  </p-table>

                </div>
              </td>
            </tr>
          </ng-template>

      </p-table>
    </div>

  </div>
</div>
</div>


