import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormioCustomComponent } from '@evi-ui/angular';
import { FormioEvent } from '@evi-ui/angular/elements.common';
import { PrimeIcons } from 'primeng/api';
import _ from 'lodash';
import { TimelineEventModel } from '@shared/components/timeline/timeline-event-model';
import { FieldValues } from '@shared/components/screen-configuration/screen-configuration.model';

@Component({
  selector: 'app-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.scss']
})
export class TimelineComponent implements FormioCustomComponent<any> {
  private _value: any;
  @Input()
  public set value(val: any) {
    if (!val) {
      return;
    }
    this._value = val;
    if (val.hasOwnProperty('events')) {
      this.events = val.events;
      this.events.forEach((e) => {
        e.color = _.shuffle(this.colors)[0];
        e.icon = PrimeIcons.ARROW_CIRCLE_RIGHT;
      });
      this.events.push({
        //Using empty event to circumvent the alignment issue of last marker on timeline.
        icon: '',
        color: '',
        title: '',
        date: '',
        eventId: -1
      });
    }
  }

  public get value() {
    return this._value;
  }

  @Input()
  public eventsFieldMapping: FieldValues[];

  @Input()
  public dataSource: string;

  @Input()
  public disabled: boolean;

  @Output() formioEvent = new EventEmitter<FormioEvent>();
  @Output() valueChange = new EventEmitter<any>();

  events: TimelineEventModel[];

  colors: string[];

  ngOnInit() {
    this.colors = [
      '#9C27B0',
      '#673AB7',
      '#FF9800',
      '#2c3f6f',
      '#99e2ff',
      '#99e200',
      '#82a500',
      '#2C3F6FFF',
      '#cd86b3',
      '#d4c800'
    ];
    this.events = [];
  }

  public eventClickedOnTimeline(event: TimelineEventModel) {
    if (event.eventId < this.events.length - 2) {
      //-2 because the last event is a dummy event.
      //tabSelected-instalmentPlanTabs-0
      const eventName = 'tabSelected-instalmentPlanTabs-0'; //TODO: to be changed to a timeline specific event.
      this.formioEvent.emit({ eventName, data: event });
    }
  }
}
