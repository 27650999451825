import { Injectable } from '@angular/core';
import { StorageService } from '@shared/services/storage.service';
import { IamAppConstants } from '../../iam/shared/iam-app-constants';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RedirectionService {
  constructor(private storageService: StorageService) {}

  private ignoreRedirectionPathNamesToPersist: string[] =
    environment.ignoreRedirectionPathNamesToPersist;

  public storeRedirectUrl(location: Location) {
    console.info('Request to Storing Redirect url', location.href);
    if (this.ignoreRedirectionPathNamesToPersist.includes(location.pathname)) {
      // ignore pathnames
      console.info(
        'The redirect url path is in ignore list , hence not storing',
        location.pathname
      );
      return;
    }
    this.storageService.addEntryToLocalStorage(
      IamAppConstants.redirect_url,
      location.href
    );
  }

  public removeRedirectUrl() {
    this.storageService.removeFromLocalStorage(IamAppConstants.redirect_url);
  }
  public getRedirectUrl(): string {
    return <string>(
      this.storageService.getEntryFromLocalStorage(IamAppConstants.redirect_url)
    );
  }
}
