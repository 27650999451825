import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, delay, Observable, switchMap } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { UserInfo } from '../model/user-info';
import { StorageService } from './storage.service';
import { IamAppConstants } from '../../iam/shared/iam-app-constants';
import { IamAuthUtils } from '@shared/utils/iam-auth-utils';
import {UserService} from "../../portal/user-management/user/user.service";

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  userInfo!: UserInfo;
  private userInfoSubject = new BehaviorSubject<UserInfo | null>(null);

  constructor(
    private http: HttpClient,
    private storageService: StorageService,
    private userService: UserService
  ) {}

  login(authType: string, username: string, password: string): Observable<any> {
    return this.http.post(`${environment.iamBaseApi}/authentication/token`, {
      authType,
      username,
      password
    });
  }

  dbLoginCallback(): Observable<any> {
    return this.samlCallback();
  }

  samlCallback(): Observable<any> {
    // used delay(0) because the token added to localstorage  in above step may not immediately available in Http interceptor to be added as header.
    //delay will add task to the execution stack
    return this.myIdentity().pipe(
      delay(0),
      switchMap((identityDetails) => this.myUserProfile())
    );
  }

  myIdentity(): Observable<any> {
    const myIdentity = `${environment.iamBaseApi}/auth/users/my-identity`;
    return this.http.get(myIdentity).pipe(
      map((res) => {
        this.storeMyIdentityDetails(res);
        return res;
      })
    );
  }

  myUserProfile(): Observable<any> {
    return this.userService.getCurrentUserProfile().pipe(
      map((res) => {
        this.storeUserProfile(res);
        return res;
      })
    );
  }

  private storeMyIdentityDetails(res: any) {
    //logic to store the details using storageService

    this.storageService.addEntryToLocalStorage(
      IamAppConstants.current_user_identity_details,
      JSON.stringify(res)
    );

    this.notifyUserDetails('identity');
  }

  private storeUserProfile(res: any) {
    this.storageService.addEntryToLocalStorage(
      IamAppConstants.current_user_profile_details,
      JSON.stringify(res)
    );
    this.notifyUserDetails('userprofile');
  }

  public notifyUserDetails(key): Observable<UserInfo | null> {
    //Reading User Details from storageService
    this.userInfo = JSON.parse(
      this.storageService.getEntryFromLocalStorage(key) as string
    );
    this.userInfoSubject.next(this.userInfo);
    return this.userInfoSubject.asObservable();
  }

  public getAppRedirectToken(
    requestByAppId: string,
    requestForAppId: string
  ): Observable<any> {
    const appRedirectTokenUrl =
      `${environment.iamBaseApi}/app-redirect-token?requested_by_app_id=` +
      requestByAppId +
      `&requested_for_app_id=` +
      requestForAppId;
    return this.http.get<any>(appRedirectTokenUrl, {}).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  getGuestToken(dashboardSlug?: string | undefined) {
    return this.http.get<any>(`${environment.iamBaseApi}/analytics/guest-token?dashboardSlug=${dashboardSlug || ''}`, { })
      .pipe(
        map((res: any) => {
            return res;
          }
        ));
  }

}
