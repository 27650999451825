import { HttpClient, HttpHeaders } from '@angular/common/http';
import 'rxjs/operators';
import { BaseRestQueryService } from './base-rest-query.service';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

export class BaseRestService<T> extends BaseRestQueryService<T> {
  constructor(http: HttpClient, restUrl: string) {
    super(http, restUrl);
  }

  createEntity(entityData: T, sanitiseAuditFields = false) {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    let payload = entityData;
    if (sanitiseAuditFields) {
      payload = this.sanitise(entityData);
    }
    return this.http.post<T>(this.restUrl, payload, { headers: headers }).pipe(
      map((data) => {
        return data;
      })
    );
  }

  /**
   * @param id
   * @param entityData can be a ModelObject or FormData
   * @param sanitiseAuditFields Fields to be removed from the request
   * @returns
   */
  updateEntity(
    id: string | number,
    entityData: T,
    sanitiseAuditFields = false
  ) {
    let payload = entityData;
    if (sanitiseAuditFields) {
      payload = this.sanitise(entityData);
    }
    return this.http.put<T>(`${this.restUrl}/` + id, payload).pipe(
      map((data) => {
        return data;
      })
    );
  }

  delete(id: string | number) {
    return this.http.delete(this.restUrl + '/' + id);
  }

  private sanitise(entityData: T) {
    let data = { ...entityData };
    environment.auditFieldsToSanitise.map((x) => delete data[x]);
    return data;
  }
}
