import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import { FormioCustomComponent } from '@evi-ui/angular';
import { FormioEvent } from '@evi-ui/angular/elements.common';

@Component({
  selector: 'nested-form',
  templateUrl: './nested-form.component.html',
  styleUrls: ['./nested-form.component.scss']
})
export class NestedFormComponent implements FormioCustomComponent<string> {
  private _form: any;

  constructor() {}

  @Input()
  value: string;

  @Input()
  disabled: boolean;

  @Input()
  key: string;

  @Input()
  formId: string;

  @Output()
  valueChange = new EventEmitter<string>();

  @Output()
  formioEvent?: EventEmitter<FormioEvent>;
  ngOnInit() {}
}
