import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { EventData, EventType } from './model/event.data';

@Injectable({
  providedIn: 'root'
})
export class EventBusService {
  eventTypeSubjectMap: Map<EventType, BehaviorSubject<EventData>> = new Map();

  //private subject$ = new BehaviorSubject<EventData>(EventData.of(EventType.EMPTY, ''));

  emit(event: EventData) {
    let subject$ = this.getSubject(event.name);
    subject$?.next(event);
  }

  on(eventName: EventType): Observable<any> {
    let subject$ = this.getSubject(eventName);
    return subject$.asObservable().pipe(
      filter((e: EventData) => e.name === eventName && e.value),
      map((e: EventData) => e['value'])
    );
  }

  private getSubject(eventName: EventType): any {
    let subject$ = this.eventTypeSubjectMap.get(eventName);
    if (!subject$) {
      subject$ = new BehaviorSubject<any>(EventData.of(eventName, null));
      this.eventTypeSubjectMap.set(eventName, subject$);
    }
    return subject$;
  }

  /* on(eventName: string, action: any): Subscription {
     return this.subject$.pipe(
       filter((e: EventData) => e.name === eventName),
       map((e: EventData) => e["value"])).subscribe(action);
   }*/
}
