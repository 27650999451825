import {Injectable} from '@angular/core';
import {Router} from "@angular/router";
import {IamAuthUtils} from "@shared/utils/iam-auth-utils";
import {IamAppConstants} from "../../iam/shared/iam-app-constants";
import {isNullOrUndefinedOrEmpty} from "@shared/utils/utils";
import {RedirectionService} from "@shared/services/redirection.service";
import {UIUtils} from "@shared/utils/ui-utils";
import * as _ from 'lodash'

@Injectable({
  providedIn: 'root'
})
export class AppReloadService {

  constructor(private redirectionService: RedirectionService,
              private router: Router) {

  }

  /**
   * after saml refresh (refresh profile or auto logout on token expiry action)
   * Note: appReload will be executed automatically before port login for the aov actions
   */
  postLogin() {
    this.handleRedirection();
  }

  /**
   * after app refresh (browser reload button)
   */
  appReload() {
    this.setAppCssRules();
    _.delay(() => this.toggleSidebar(), 500);
  }

  private handleRedirection() {
    const defaultRedirectUrl = '/app/worklist/my-workflow-items'; //'/app';
    const redirectUrl: string = this.redirectionService.getRedirectUrl();

    if (!IamAuthUtils.doesUserHasRoles()) {
      this.router.navigate([IamAppConstants.no_acl_page_private]);
    } else if (!isNullOrUndefinedOrEmpty(redirectUrl)) {
      console.log(`Found a redirectUrl => ${redirectUrl}  :: Hence , redirecting via location.href`);
      console.log(`Clearing the storage Value of redirectUrl`);
      this.redirectionService.removeRedirectUrl();
      location.href = <string>redirectUrl;
    } else {
      console.log(
        `Redirecting after successful Saml login ${defaultRedirectUrl}`
      );
      this.router.navigate([defaultRedirectUrl]);
    }
  }

  private setAppCssRules() {
    const rolesCss = IamAuthUtils.doesUserHasRoles() ? 'has-roles' : 'no-roles';
    const aclCss = IamAuthUtils.doesUserHasAcls() ? 'has-acls' : 'no-acls';
    const privilegeCss = IamAuthUtils.hasPrivilegedAcls() ? 'privileged' : 'un-privileged';
    [rolesCss, aclCss, privilegeCss].map(className => document.getElementsByTagName("body")[0].classList.add(className));
  }

  private toggleSidebar() {
    if (!IamAuthUtils.doesUserHasRoles()) {
      UIUtils.toggleSidebar()
    }
  }
}
