import _ from 'lodash';

export class ScreenConfig {
  submissionUrl?: string;
  retrievalUrl?: string;

  submitSuccessPopup: SubmitSuccessPopup = new SubmitSuccessPopup();

  errorPopup: SubmitSuccessPopup = new SubmitSuccessPopup();
  documentConfig: DocumentConfig = new DocumentConfig();

  additionalConfig: AdditionalProperties[];

  screenInitializationConfig?: ScreenInitializationConfig[];

  public options: any;

  isEmpty(): boolean {
    return (
      _.isEmpty(this.submissionUrl) &&
      _.isEmpty(this.retrievalUrl) &&
      this.submitSuccessPopup.isEmpty() &&
      this.documentConfig.isEmpty() &&
      (!this.additionalConfig ||
        (this.additionalConfig && this.additionalConfig.length > 0)) &&
      this.errorPopup.isEmpty()
    );
  }

  hasAdditionalConfig(): boolean {
    return (
      this.additionalConfig &&
      this.additionalConfig.length > 0 &&
      this.additionalConfig.filter(
        (config) => config.propertyValue && config.propertyName
      ).length > 0
    );
  }

  get builderMode() {
    console.log('ScreenConfig options', this.options);
    return this.options?.attachMode === 'builder';
  }
}

export class ScreenInitializationConfig {
  storageAttribute: string;
  apiUrl: string;
  apiMethod: string | 'GET' | 'PUT' | 'POST';
  dataPath?: string;
  responseProcessor?: string;
  requestBody?: string;
}
export class AdditionalProperties {
  propertyName: string;
  propertyValue: string;

  dataPath?: string;

  apiMethod: string = 'GET';
  apiRequest?: string;

  isEmpty(): boolean {
    return _.isEmpty(this.propertyName) && _.isEmpty(this.propertyValue);
  }
}

export class SubmitSuccessPopup {
  headerMessage?: string;
  message?: string;

  popupScreenId?: string;

  redirectUrlAfterSubmission?: string;

  data?: any;

  returnValueStorageAttribute?: string;

  inAppNavigation?: boolean = true;

  isEmpty(): boolean {
    return (
      _.isEmpty(this.headerMessage) &&
      _.isEmpty(this.message) &&
      _.isEmpty(this.popupScreenId) &&
      _.isEmpty(this.redirectUrlAfterSubmission)
    );
  }
}

export class DocumentConfig {
  sourceKey?: string;
  targetKey?: string;

  isEmpty(): boolean {
    return _.isEmpty(this.sourceKey) && _.isEmpty(this.targetKey);
  }
}
