import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import {AppReloadService} from "@shared/services/app-reload.service";

@Injectable({
  providedIn: 'root'
})
export class BootstrapService {
  constructor(private httpClient: HttpClient, private appReloadService: AppReloadService) {}

  init(): Observable<any> {
    return of(null).pipe(
      tap((data) => {
        console.log('Initializing  Ifas Portal Application ....');
       this.appReloadService.appReload();
      })
    );


  }
}
