import { Injector } from '@angular/core';
import {
  FormioCustomComponentInfo,
  registerCustomFormioComponent
} from '@evi-ui/angular';
import { VectorCheckboxComponent } from './vector-checkbox.component';

const COMPONENT_OPTIONS: FormioCustomComponentInfo = {
  type: 'vector-checkbox',
  selector: 'vector-checkbox',
  title: 'Vector Checkbox',
  group: 'basic',
  icon: 'fa-solid fa-check-to-slot',
  emptyValue: {},
  editForm: editForm
};

function editForm() {
  return {
    components: [
      {
        label: 'Display',
        components: [dataTab()],
        key: 'display',
        type: 'tabs',
        input: false,
        tableView: false
      }
    ]
  };
}
function dataTab() {
  return {
    label: 'Display',
    key: 'display',
    components: [
      {
        weight: 0,
        type: 'textfield',
        input: true,
        key: 'customOptions.propertyName',
        label: 'Property Name',
        tooltip: 'The name of this field in the API endpoint.',
        validate: {
          pattern: '(\\w|\\w[\\w]*\\w)',
          patternMessage:
            'The property name must only contain alphanumeric characters, underscores and should not be ended by dash or dot.',
          required: true
        }
      },
      {
        weight: 0,
        type: 'textfield',
        input: true,
        key: 'customOptions.aclResource',
        label: 'ACL Resource (Will be made generic)',
        tooltip: 'ACL Resource Name.',
        validate: {
          required: true
        }
      },
      {
        weight: 0,
        type: 'textfield',
        input: true,
        key: 'customOptions.label',
        label: 'Label',
        tooltip: 'Label of the Checkbox.',
        validate: {
          required: true
        }
      },
      {
        weight: 0,
        type: 'textfield',
        input: true,
        key: 'customOptions.apiKey',
        label: 'API',

        validate: {
          required: true
        }
      },
      {
        key: 'customOptions.initialValueEvaluator',
        weight: 200,
        type: 'textarea',
        input: true,
        editor: 'ace',
        as: 'javascript',
        wysiwyg: {
          minLines: 12
        },
        defaultValue: undefined,
        label: 'Initial Value Evaluator'
      },
      {
        type: 'select',
        input: true,
        key: 'customOptions.imageKey',
        label: 'Vector/Image',
        tooltip: 'Vector/Image to be used on the checkbox',
        weight: 20,
        validate: {
          required: true
        },
        dataSrc: 'values',
        data: {
          values: [
            { label: 'Approve/Reject', value: 'icon-approve-black.png' },
            { label: 'Create', value: 'icon-create-black.png' },
            { label: 'Edit/Update', value: 'icon-edit-black.png' },
            { label: 'Email', value: 'icon-open-email-black.png' },
            { label: 'PDF', value: 'icon-pdf-black.png' },
            { label: 'Restore', value: 'icon-restore-black.png' },
            { label: 'Revision History', value: 'icon-revision-black.png' },
            { label: 'Roles', value: 'icon-roles-black.png' },
            { label: 'Schedule', value: 'icon-schedule.png' },
            { label: 'Terminate', value: 'icon-terminate-black.png' },
            { label: 'Users', value: 'icon-users-black.png' },
            { label: 'View', value: 'icon-view-black.png' },
            { label: 'Withdraw', value: 'icon-withdraw.png' },
            { label: 'Sign', value: 'icon-sign-black.png' },
            {
              label: 'Approve Document',
              value: 'icon-approve-subscription-black.png'
            },
            {
              label: 'Finance Approve',
              value: 'icon-finance-approve-black.png'
            },
            { label: 'Issue Document', value: 'icon-issue-document-black.png' },
            { label: 'Email Edit', value: 'icon-email-designer-black.png' },
            { label: 'PDF edit', value: 'icon-pdf-designer.png' },
            { label: 'Cashier', value: 'icon-cashier-module-black.png' },
            { label: 'Failure', value: 'icon-failure-black.png' },
            { label: 'Offset', value: 'icon-offset-black.png' },
            { label: 'Outcome', value: 'icon-outcome-black.png' },
            { label: 'Workflow', value: 'icon-workflow-black-new.png' },
            { label: 'Instalment Amend', value: 'icon-instalment-amend.png' }
          ]
        }
      },
      {
        type: 'textfield',
        label: 'ACL Key',
        key: 'aclResource',
        weight: 5,
        placeholder: 'ACL Key',
        input: true
      }
    ]
  };
}

class Status {
  static status = new Map();
}
export function registerVectorCheckboxComponent(
  injector: Injector,
  screen: string
) {
  if (!Status.status.has(screen)) {
    try {
      registerCustomFormioComponent(
        COMPONENT_OPTIONS,
        VectorCheckboxComponent,
        injector
      );
      Status.status.set(screen, true);
    } catch (e) {
      console.log('Error while registering VectorCheckboxComponent: ' + screen);
    }
  }
}
