import {
  CustomButtonActionHandler,
  CustomButtonActionHandlerContext
} from '@shared/components/custom-action-button/handlers/custom-button-action-handler';
import { CustomButtonActionType } from '@shared/components/custom-action-button/handlers/custom-button-action-handler-registry';
import { EventEmitter, Injectable } from '@angular/core';
import { FormioEvent } from '@evi-ui/angular/elements.common';

@Injectable({ providedIn: 'root' })
export class TriggerEventActionProcessor implements CustomButtonActionHandler {
  formioEvent: EventEmitter<FormioEvent>;

  isApplicable(actionType: CustomButtonActionType): boolean {
    return actionType === CustomButtonActionType.triggerEvent;
  }

  async performAction(context: CustomButtonActionHandlerContext) {
    let actionProcessor = context.actionProcessor;
    let eventName = actionProcessor.triggerEventConfig.eventName;
    context.formioEvent.emit({ eventName, data: {} });

    return Promise.resolve();
  }
}
