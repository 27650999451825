import {
  CustomButtonActionHandler,
  CustomButtonActionHandlerContext
} from '@shared/components/custom-action-button/handlers/custom-button-action-handler';
import { CustomButtonActionType } from '@shared/components/custom-action-button/handlers/custom-button-action-handler-registry';
import { EventEmitter, Injectable } from '@angular/core';
import _ from 'lodash';
import { FormioEvent } from '@evi-ui/angular/elements.common';

@Injectable({ providedIn: 'root' })
export class ToggleComponentsVisibilityActionProcessor
  implements CustomButtonActionHandler
{
  formioEvent: EventEmitter<FormioEvent>;

  isApplicable(actionType: CustomButtonActionType): boolean {
    return actionType === CustomButtonActionType.toggleComponentsVisibility;
  }

  async performAction(context: CustomButtonActionHandlerContext) {
    let actionProcessor = context.actionProcessor;
    actionProcessor.toggleComponentsVisibilityConfig.componentsToToggle
      .filter((componentKey) => !_.isNil(componentKey))
      .forEach((componentKey) => {
        const eventName = 'toggle-' + componentKey;
        context.formioEvent.emit({ eventName, data: {} });
        console.log('Emitted: ', eventName);
      });

    return Promise.resolve();
  }
}
