import {HttpHeaders} from '@angular/common/http';

export class IamAppConstants {
  static Mode = {
    CREATE: 'create',
    EDIT: 'edit',
    VIEW: 'view'
  };
  static header = new HttpHeaders().set(
    'Content-Type',
    'application/json; charset=utf-8'
  );
  public static token = 'token';
  public static acls = 'acls';
  public static roles = 'roles';
  public static preferences = 'preferences';
  public static privileged = 'privileged';

  public static current_user_identity_details = 'current_user_identity_details';
  public static current_user_profile_details = 'current_user_profile_details';
  public static redirect_url = 'redirect_url';

  static AuthType = {
    DB_AUTH: 'DB_AUTH',
    SSO: 'SSO',
    MFA: 'MFA'
  };
  public static lockScreenUrl = '/public/users/screen-lock';
  static no_acl_page_private = '/app/access-denied/landing-no-access';
  static access_denied_page = 'auth/access-denied';
  static auth_login = '/auth/login';

}
