import {Component, OnInit} from '@angular/core';
import {StorageService} from '../../shared/services/storage.service';
import {environment} from '../../../environments/environment';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {IamAppConstants} from '../../iam/shared/iam-app-constants';
import {AuthService} from '../../shared/services/auth.service';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss']
})
export class LoginPageComponent implements OnInit {
  showLoginScreen = false;
  submitted = false;
  error = '';
  loginForm!: FormGroup;
  userId!: any;

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private storageService: StorageService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {
  }

  ngOnInit() {
    const queryParams = this.activatedRoute.snapshot.queryParams;
    const dblogin = queryParams.hasOwnProperty('dblogin');

    if (dblogin) {
      this.initLoginForm();
      this.showLoginScreen = true;
    } else if (environment.enableSaml) {
      window.location.href = this.getSamlLoginUrl();
    }
  }

  private getSamlLoginUrl() {
    return environment.samlLoginUrl;
  }


  initLoginForm() {
    this.loginForm = this.fb.group({
      email: [
        '',
        [
          Validators.required,
          Validators.pattern(
            '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$'
          )
        ]
      ],
      password: ['', [Validators.required]]
    });
  }

  public login() {
    this.submitted = true;
    if (this.loginForm.invalid) {
      return;
    }
    this.authService
      .login(
        IamAppConstants.AuthType.DB_AUTH,
        this.loginForm.value.email,
        this.loginForm.value.password
      ).subscribe(tokenDetails => {
      location.href = `/auth/saml/callback?code=${tokenDetails.token}`;
    })

  }
}
