import { Injector } from '@angular/core';
import {
  FormioCustomComponentInfo,
  registerCustomFormioComponent
} from '@evi-ui/angular';
import { EmbeddedReportComponent } from '@shared/components/embedded-report/embedded-report.component';

const COMPONENT_OPTIONS: FormioCustomComponentInfo = {
  type: 'embedded-report',
  selector: 'embedded-report',
  title: 'Embedded Report',
  group: 'basic',
  icon: 'fa-regular fa-report',
  emptyValue: {},
  editForm: editForm
};

function editForm() {
  return {
    components: [
      { key: 'type', type: 'hidden' },
      {
        label: 'Tabs',
        components: [componentEditDisplay()],
        key: 'tabs',
        type: 'tabs',
        input: false,
        tableView: false
      }
    ]
  };
}

function componentEditDisplay() {
  return {
    label: 'Display',
    key: 'baseDisplay',
    components: [
      {
        weight: 0,
        type: 'textfield',
        input: true,
        key: 'customOptions.key',
        label: 'Property Name',
        tooltip: 'The name of this field in the API endpoint.',
        validate: {
          pattern: '(\\w|\\w[\\w-.]*\\w)',
          patternMessage: 'The property name must only contain alphanumeric characters, underscores, dots and dashes and should not be ended by dash or dot.',
          required: true
        }
      },
      {
        type: 'textarea',
        rows: 5,
        editor: 'ace',
        as: 'javascript',
        key: 'customOptions.analyticsConfig.embeddedDashboardId',
        input: true,
        label: 'Embedded Dashboard ID',
        weight: 10,
        description: 'Embedded Dashboard ID'
      },
      {
        type: 'textfield',
        key: 'customOptions.analyticsConfig.containerID',
        input: true,
        label: 'Container ID',
        weight: 10,
        description: 'Unique Dashboard Container ID'
      },
      {
        type: 'textfield',
        key: 'customOptions.analyticsConfig.dashboardConfigJson',
        input: true,
        label: 'Dashboard Config Json',
        weight: 10,
        defaultValue: '{hideTitle: true}',
        description: 'Dashboard Config Json'
      },
      {
        type: 'checkbox',
        key: 'customOptions.analyticsConfig.displayGotoAnalyticsButton',
        input: true,
        label: 'Display GoTo Analytics Button',
        weight: 10,
        defaultValue: false,
        description:
          'Display Go to analytics button, which takes user to Analytics Portal'
      }
    ]
  };
}

class Status {
  static status = new Map();
}
export function registerEmbeddedReportComponent(
  injector: Injector,
  screen: string
) {
  if (!Status.status.has(screen)) {
    try {
      registerCustomFormioComponent(
        COMPONENT_OPTIONS,
        EmbeddedReportComponent,
        injector
      );
      Status.status.set(screen, true);
    } catch (e) {
      console.log('Error while registering EmbeddedReportComponent: ' + screen);
    }
  }
}
