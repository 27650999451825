import { Injector, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToolbarModule } from 'primeng/toolbar';
import { MenubarModule } from 'primeng/menubar';
import { TableModule } from 'primeng/table';
import { ButtonModule } from 'primeng/button';
import { PanelMenuModule } from 'primeng/panelmenu';
import { DataViewModule } from 'primeng/dataview';
import { DialogModule } from 'primeng/dialog';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TabViewModule } from 'primeng/tabview';
import { TagModule } from 'primeng/tag';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { ChipModule } from 'primeng/chip';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';
import { PanelModule } from 'primeng/panel';
import { DropdownModule } from 'primeng/dropdown';
import { TooltipModule } from 'primeng/tooltip';
import { FileUploadModule } from 'primeng/fileupload';
import { PickListModule } from 'primeng/picklist';
import { CardModule } from 'primeng/card';
import { CalendarModule } from 'primeng/calendar';
import { GalleriaModule } from 'primeng/galleria';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { AccordionModule } from 'primeng/accordion';
import {
  FaIconLibrary,
  FontAwesomeModule
} from '@fortawesome/angular-fontawesome';
import { CheckboxModule } from 'primeng/checkbox';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { InputTextModule } from 'primeng/inputtext';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { CarouselModule } from 'primeng/carousel';
import { PaginatorModule } from 'primeng/paginator';
import { RadioButtonModule } from 'primeng/radiobutton';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { DragDropModule } from 'primeng/dragdrop';
import { SelectButtonModule } from 'primeng/selectbutton';
import { SplitterModule } from 'primeng/splitter';
import { ChipsModule } from 'primeng/chips';
import { OrderListModule } from 'primeng/orderlist';
import { FormioAppConfig, FormioModule } from '@evi-ui/angular';
import { AnonymousHeaderComponent } from './components/anonymous-header/anonymous-header.component';
import { CustomActionButtonComponent } from './components/custom-action-button/custom-action-button.component';
import { SmartTableComponent } from './components/smart-table/smart-table.component';
import { SplitButtonModule } from 'primeng/splitbutton';
import { UrlBasedTextfieldComponent } from './components/url-based-textfield/url-based-textfield.component';
import { InputSwitchModule } from 'primeng/inputswitch';
import { ScreenConfigurationComponent } from './components/screen-configuration/screen-configuration.component';
import { environment } from '../../environments/environment';
import { VectorCheckboxComponent } from './components/vector-checkbox/vector-checkbox.component';
import { SafeHtmlPipe } from '@shared/pipes/safe-html.pipe';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { TimelineComponent } from '@shared/components/timeline/timeline.component';
import { TimelineModule } from 'primeng/timeline';

export const formioAppConfig = {
  apiUrl: environment.baseApiUrl,
  appUrl: environment.formsBaseUrl,
  icons: 'fontawesome'
};

@NgModule({
  declarations: [
    AnonymousHeaderComponent,
    CustomActionButtonComponent,
    SmartTableComponent,
    UrlBasedTextfieldComponent,
    ScreenConfigurationComponent,
    VectorCheckboxComponent,
    TimelineComponent,
    SafeHtmlPipe
  ],
  imports: [
    CommonModule,
    ToolbarModule,
    MenubarModule,
    TableModule,
    ButtonModule,
    PanelMenuModule,
    DataViewModule,
    DialogModule,
    FormsModule,
    ReactiveFormsModule,
    TabViewModule,
    TagModule,
    ToastModule,
    ChipModule,
    ConfirmPopupModule,
    MessagesModule,
    MessageModule,
    PanelModule,
    DropdownModule,
    TooltipModule,
    FileUploadModule,
    PickListModule,
    CardModule,
    CalendarModule,
    GalleriaModule,
    AutoCompleteModule,
    AccordionModule,
    FontAwesomeModule,
    CheckboxModule,
    ConfirmDialogModule,
    OverlayPanelModule,
    CarouselModule,
    BreadcrumbModule,
    OrderListModule,
    DragDropModule,
    SelectButtonModule,
    SplitterModule,
    ChipsModule,
    SplitButtonModule,
    InputSwitchModule,
    TimelineModule
  ],
  exports: [
    CommonModule,
    ToolbarModule,
    MenubarModule,
    TableModule,
    ButtonModule,
    PanelMenuModule,
    DataViewModule,
    DialogModule,
    FormsModule,
    ReactiveFormsModule,
    TabViewModule,
    TagModule,
    ToastModule,
    ChipModule,
    ConfirmPopupModule,
    MessagesModule,
    MessageModule,
    PanelModule,
    DropdownModule,
    TooltipModule,
    FileUploadModule,
    PickListModule,
    CardModule,
    CalendarModule,
    GalleriaModule,
    AutoCompleteModule,
    AccordionModule,
    FontAwesomeModule,
    CheckboxModule,
    ConfirmDialogModule,
    InputTextModule,
    InputTextareaModule,
    OverlayPanelModule,
    CarouselModule,
    PaginatorModule,
    RadioButtonModule,
    DragDropModule,
    SelectButtonModule,
    SplitterModule,
    ChipsModule,
    SplitButtonModule,
    InputSwitchModule,
    FormioModule,
    AnonymousHeaderComponent,
    SmartTableComponent
  ],
  providers: [
    MessageService,
    ConfirmationService,
    DynamicDialogRef,
    DynamicDialogConfig,
    {
      provide: FormioAppConfig,
      useValue: formioAppConfig
    }
  ]
})
export class SharedModule {
  constructor(injector: Injector, library: FaIconLibrary) {
    //  library.addIconPacks(fas, far);
  }
}
