<div class="currency-wrap" *ngIf="prefix">
  <span class="currency-code">{{ prefix }}</span>
  <input
    type="text"
    class="form-control text-currency"
    [value]="valueFromApi"
    [disabled]="disabled"
  />
</div>

<div *ngIf="!prefix">
  <input
    type="text"
    class="form-control"
    [value]="valueFromApi"
    [disabled]="disabled"
  />
</div>
