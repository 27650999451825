import { Injectable } from '@angular/core';
import {
  HTTP_INTERCEPTORS,
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { StorageService } from '../services/storage.service';
import { EventBusService } from '../event-bus.service';
import { EventData, EventType } from '../model/event.data';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { RedirectionService } from '@shared/services/redirection.service';

@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {
  constructor(
    private storageService: StorageService,
    private eventBusService: EventBusService,
    private toastrService: ToastrService,
    private router: Router,
    private redirectionService: RedirectionService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const token = this.storageService.getEntryFromLocalStorage('token');

    req = req.clone({
      withCredentials: true,
      setHeaders: {
        Authorization: `Bearer ${token}`
      }
    });

    return next.handle(req).pipe(
      catchError((error) => {
        // logout when token is expired

        if (error.error.errorCode === 'ACCESS_DENIED') {
          this.router.navigate(['auth/access-denied']);
        }

        if (
          error.error.errorCode === 'TOKEN_EXPIRED' ||
          error.error.errorCode === 'TOKEN_NOT_FOUND' ||
          error.error.errorCode === 'TOKEN_INVALID'
        ) {
          this.storageService.removeFromLocalStorage('token'); // clear the token
          this.redirectionService.storeRedirectUrl(location);
          this.router.navigate(['auth/login']);
        }

        if (
          error instanceof HttpErrorResponse &&
          !req.url.includes('auth/signin') &&
          error.status === 401
        ) {
          return this.handle401Error(req, next);
        }
        //  this.toastrService.error(error?.error, 'Error');
        return throwError(() => error);
      })
    );
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    this.eventBusService.emit(new EventData(EventType.LOGOUT, null));

    return next.handle(request);
  }
}

export const httpInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: HttpRequestInterceptor, multi: true }
];
