import { Utils as FormioUtils } from '@evi-ui/js';
import {
  ScreenConfig,
  ScreenInitializationConfig
} from '../../../portal/application-forms/all-forms-renderer/screen-config';

export class ScreenConfigurationUtil {
  /**
   * @deprecated Use getStaticScreenConfig instead of this method after the Screen Configuration HTML component is replaced
   * with ScreenConfiguration custom component.
   * @param form
   * @private
   */
  static getScreenConfig(form) {
    let configurationComponent = FormioUtils.getComponent(
      form.components,
      'configuration',
      true
    );
    if (!configurationComponent) {
      return new ScreenConfig();
    }
    let attrs = configurationComponent.component.attrs;

    let headerMessage = this.getValueByAttribute(
      attrs,
      'popupConfig.headerMessage'
    );

    let popupScreenId = this.getValueByAttribute(
      attrs,
      'popupConfig.popupScreenId'
    );

    let message = this.getValueByAttribute(attrs, 'popupConfig.message');

    let submissionUrl = this.getValueByAttribute(attrs, 'submissionUrl');

    let retrievalUrl = this.getValueByAttribute(attrs, 'retrievalUrl');

    let documentSourceKey = this.getValueByAttribute(
      attrs,
      'documentConfig.sourceKey'
    );

    let documentTargetKey = this.getValueByAttribute(
      attrs,
      'documentConfig.targetKey'
    );

    let redirectUrlAfterSubmission = this.getValueByAttribute(
      attrs,
      'redirectUrlAfterSubmission'
    );

    let screenConfig = new ScreenConfig();
    screenConfig.submitSuccessPopup.popupScreenId = popupScreenId;
    screenConfig.submitSuccessPopup.headerMessage = headerMessage;
    screenConfig.submitSuccessPopup.message = message;
    screenConfig.submissionUrl = submissionUrl;
    screenConfig.retrievalUrl = retrievalUrl;
    screenConfig.submitSuccessPopup.redirectUrlAfterSubmission =
      redirectUrlAfterSubmission;
    screenConfig.documentConfig.sourceKey = documentSourceKey;
    screenConfig.documentConfig.targetKey = documentTargetKey;

    return screenConfig;
  }

  static getStaticScreenConfig(form): ScreenConfig {
    let configurationComponent = FormioUtils.getComponent(
      form.components,
      'screenConfiguration',
      true
    );
    let screenConfig = new ScreenConfig();
    screenConfig.retrievalUrl = configurationComponent?.component?.retrievalUrl;
    screenConfig.additionalConfig =
      configurationComponent?.component?.additionalProperties;
    screenConfig.submissionUrl =
      configurationComponent?.component?.submissionUrl;
    screenConfig.submitSuccessPopup.popupScreenId =
      configurationComponent?.component?.popupScreenId;
    screenConfig.submitSuccessPopup.headerMessage =
      configurationComponent?.component?.headerMessage;
    screenConfig.submitSuccessPopup.message =
      configurationComponent?.component?.message;
    screenConfig.submitSuccessPopup.redirectUrlAfterSubmission =
      configurationComponent?.component?.redirectUrlAfterSubmission;
    screenConfig.submitSuccessPopup.inAppNavigation =
      !configurationComponent?.component?.navigateViaBrowser;
    screenConfig.errorPopup.popupScreenId =
      configurationComponent?.component?.errorPopupScreenId;
    screenConfig.errorPopup.headerMessage =
      configurationComponent?.component?.errorHeaderMessage;
    screenConfig.errorPopup.message =
      configurationComponent?.component?.errorMessage;
    screenConfig.documentConfig.sourceKey =
      configurationComponent?.component?.sourceKey;
    screenConfig.documentConfig.targetKey =
      configurationComponent?.component?.targetKey;

    screenConfig.screenInitializationConfig =
      configurationComponent?.component?.screenInitializationConfig;

    return screenConfig;
  }

  static getActionHandlers(form: any) {
    let configurationComponent = FormioUtils.getComponent(
      form.components,
      'screenConfiguration',
      true
    );
    return configurationComponent?.component?.actionListener;
  }
  static getScreenInitializationConfig(form): ScreenInitializationConfig[] {
    let screenConfigComponent = FormioUtils.getComponent(
      form.components,
      'screenConfiguration',
      true
    );
    return screenConfigComponent?.component?.screenInitializationConfig;
  }

  static getValueByAttribute(attrs: [], attributeKey: string) {
    let valueOfAttr = '';
    attrs.forEach((attr, index) => {
      // @ts-ignore
      if (attr.attr == attributeKey) {
        // @ts-ignore
        valueOfAttr = attr.value;
      }
    });
    return valueOfAttr;
  }
}
