import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { RedirectionService } from '@shared/services/redirection.service';
import {IamAuthUtils} from "@shared/utils/iam-auth-utils";

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    public router: Router,
    private redirectionService: RedirectionService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const token = IamAuthUtils.getCurrentUserToken();
    const isIdentityDetailsAvailable = IamAuthUtils.isCurrentUserIdentityDetailsPresent();



    if ((token  && isIdentityDetailsAvailable ) || (!environment.enableSaml )) {
      return true;
    } else if (this.isRouteIgnorable(route, state)) {
      return true;
    } else {
      const url = '/auth/login';
      /*  if (environment.enableSaml) {
        url = environment.samlLoginUrl;
      }*/
      this.redirectionService.storeRedirectUrl(location); // save last login url
      location.href = url;
      return false;
    }
  }

  isRouteIgnorable(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return environment.ignoreAuthGuardUrls.includes(state.url);
  }


}
