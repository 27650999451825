<nav>
  <div class="row">
    <div class="col-3 float-end">
      <span class="navbar-brand">
        <img alt="Logo" src="/assets/images/logo.png" />
      </span>
    </div>
    <div class="col-6 my-4 flex-md-column">
      <span class="app-title primary-color pl-3 text-3xl">
        Integrated Finance Administration System</span
      >
    </div>
  </div>
</nav>
