<button
  *ngIf="!dropDownButton"
  class="btn btn-action btn-{{ theme }} {{ customClass }} {{ blockButton }}"
  (click)="onClick($event)"
  [disabled]="disabled"
  [attr.hidden]="hidden"
>
  <div>
    <i *ngIf="leftIcon" class="{{ leftIcon }}"></i>
    {{ label }}
    <i *ngIf="rightIcon" class="{{ rightIcon }}"></i>
  </div>
</button>

<div class="dropdown" *ngIf="dropDownButton">
  <button
    class="btn dropdown-toggle btn-action btn-{{ theme }} {{ customClass }} {{
      blockButton
    }}"
    [disabled]="disabled"
    data-bs-toggle="dropdown"
    aria-expanded="false"
  >
    <i *ngIf="leftIcon" class="{{ leftIcon }}"></i>
    {{ label }}
    <i *ngIf="rightIcon" class="{{ rightIcon }}"></i>
  </button>
  <ul class="dropdown-menu">
    <li *ngFor="let buttonOption of dropDownButtonOptions; index as i">
      <button
        class="dropdown-item"
        type="button"
        (click)="dropDownButtonClicked($event, buttonOption?.buttonOptionUrl)"
      >
        {{ buttonOption?.buttonOptionLabel }}
      </button>
    </li>
  </ul>
</div>
