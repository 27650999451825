
export class UIUtils {
  static toggleSidebar() {
    const sidebar =  document.getElementById('side-bar') as HTMLElement;
    sidebar?.classList.toggle("collapsed");

  }

  static closeSidebar() {
    const sidebar =  document.getElementById('side-bar') as HTMLElement;
    sidebar?.classList.add("collapsed");

  }
}

