import {
  CustomButtonActionHandler,
  CustomButtonActionHandlerContext
} from '@shared/components/custom-action-button/handlers/custom-button-action-handler';
import { CustomButtonActionType } from '@shared/components/custom-action-button/handlers/custom-button-action-handler-registry';
import { Injectable } from '@angular/core';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';

@Injectable({ providedIn: 'root' })
export class CloseAllPopupsActionProcessor
  implements CustomButtonActionHandler
{
  private ref: DynamicDialogRef;

  constructor(private dialogService: DialogService) {}

  isApplicable(actionType: CustomButtonActionType): boolean {
    return actionType === CustomButtonActionType.closeAllPopups;
  }

  async performAction(context: CustomButtonActionHandlerContext) {
    return new Promise((resolve, reject) => {
      this.dialogService.dialogComponentRefMap?.forEach((dialog) => {
        dialog?.destroy();
      });
      this.ref.close();

      resolve('{}');
    });
  }

  setDialogRef(ref: DynamicDialogRef) {
    this.ref = ref;
  }
}
