import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { IamUser } from '../model/iam-user';
import { IamAppConstants } from '../shared/iam-app-constants';
import { BaseRestService } from '../../shared/services/base-rest.service';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class IamUserService extends BaseRestService<IamUser> {
  constructor(public http_: HttpClient) {
    super(http_, environment.iamBaseApi + '/auth/users');
  }

  getCurrentUserIdentityDetails() {
    return this.http
      .get(`${this.restUrl}/my-identity`)
      .pipe(map((res: any) => (res = res)));
  }

  updateUserPassword(data: any) {
    return this.http
      .post(`${this.restUrl}/update-password`, JSON.stringify(data), {
        headers: IamAppConstants.header
      })
      .pipe(map((res: any) => (res = res)));
  }

  getFilteredUsersWithAcl(acls: string[], excludeUsers: string[]) {
    const data = {
      aclResourceNames: acls,
      users: excludeUsers
    };
    return this.http
      .post(`${this.restUrl}/filter-acl-users`, JSON.stringify(data), {
        headers: IamAppConstants.header
      })
      .pipe(map((res: any) => (res = res)));
  }
}
