import { Injector } from '@angular/core';
import {
  FormioCustomComponentInfo,
  registerCustomFormioComponent
} from '@evi-ui/angular';
import { UrlBasedTextfieldComponent } from './url-based-textfield.component';
import { Utils } from '@evi-ui/js';
import getContextComponents = Utils.getContextComponents;

const COMPONENT_OPTIONS: FormioCustomComponentInfo = {
  type: 'url-based-textfield',
  selector: 'url-based-textfield',
  title: 'Text With API Data',
  group: 'basic',
  icon: 'fa-regular fa-diagram-project fa-beat',
  emptyValue: {},
  editForm: editForm
};

function editForm() {
  return {
    components: [
      { key: 'type', type: 'hidden' },
      {
        label: 'Tabs',
        components: [componentEditDisplay(), dataTab(), conditionDisplay()],
        key: 'tabs',
        type: 'tabs',
        input: false,
        tableView: false
      }
    ]
  };
}

function conditionDisplay() {
  return {
    label: 'Conditional',
    key: 'conditionalDisplay',
    components: [
      {
        type: 'panel',
        title: 'Simple',
        key: 'simple-conditional',
        theme: 'default',
        weight: 105,
        components: [
          {
            type: 'select',
            input: true,
            label: 'This component should Display:',
            key: 'conditional.show',
            dataSrc: 'values',
            data: {
              values: [
                { label: 'True', value: 'true' },
                { label: 'False', value: 'false' }
              ]
            }
          },
          {
            type: 'select',
            input: true,
            label: 'When the form component:',
            key: 'conditional.when',
            dataSrc: 'custom',
            valueProperty: 'value',
            data: {
              custom(context) {
                return getContextComponents(context);
              }
            }
          },
          {
            type: 'textfield',
            input: true,
            label: 'Has the value:',
            key: 'conditional.eq'
          }
        ]
      }
    ]
  };
}

function componentEditDisplay() {
  return {
    label: 'Display',
    key: 'baseDisplay',
    components: [
      {
        weight: 0,
        type: 'textfield',
        input: true,
        key: 'label',
        label: 'Label',
        placeholder: 'Field Label',
        tooltip: 'The label for this field that will appear next to it.',
        validate: {
          required: true
        },
        autofocus: true
      },
      {
        weight: 0,
        type: 'textfield',
        input: true,
        key: 'customOptions.prefix',
        label: 'Prefix',
        placeholder: 'Prefix',
        tooltip:
          'The prefix for this field that will appear to the left inside it.'
      },
      {
        type: 'select',
        input: true,
        key: 'labelPosition',
        label: 'Label Position',
        tooltip: 'Position for the label for this field.',
        weight: 20,
        defaultValue: 'top',
        dataSrc: 'values',
        data: {
          values: [
            { label: 'Top', value: 'top' },
            { label: 'Left (Left-aligned)', value: 'left-left' },
            { label: 'Left (Right-aligned)', value: 'left-right' },
            { label: 'Right (Left-aligned)', value: 'right-left' },
            { label: 'Right (Right-aligned)', value: 'right-right' },
            { label: 'Bottom', value: 'bottom' }
          ]
        }
      },
      {
        type: 'number',
        input: true,
        key: 'labelWidth',
        label: 'Label Width',
        tooltip: 'The width of label on line in percentages.',
        clearOnHide: false,
        weight: 30,
        placeholder: '30',
        suffix: '%',
        validate: {
          min: 0,
          max: 100
        },
        conditional: {
          json: {
            and: [
              { '!==': [{ var: 'data.labelPosition' }, 'top'] },
              { '!==': [{ var: 'data.labelPosition' }, 'bottom'] }
            ]
          }
        }
      },
      {
        type: 'number',
        input: true,
        key: 'labelMargin',
        label: 'Label Margin',
        tooltip: 'The width of label margin on line in percentages.',
        clearOnHide: false,
        weight: 30,
        placeholder: '3',
        suffix: '%',
        validate: {
          min: 0,
          max: 100
        },
        conditional: {
          json: {
            and: [
              { '!==': [{ var: 'data.labelPosition' }, 'top'] },
              { '!==': [{ var: 'data.labelPosition' }, 'bottom'] }
            ]
          }
        }
      },
      {
        weight: 100,
        type: 'textfield',
        input: true,
        key: 'placeholder',
        label: 'Placeholder',
        placeholder: 'Placeholder',
        tooltip:
          'The placeholder text that will appear when this field is empty.'
      },
      {
        weight: 200,
        type: 'textarea',
        input: true,
        key: 'description',
        label: 'Description',
        placeholder: 'Description for this field.',
        tooltip:
          'The description is text that will appear below the input field.',
        editor: 'ace',
        as: 'html',
        wysiwyg: {
          minLines: 3,
          isUseWorkerDisabled: true
        }
      },
      {
        weight: 300,
        type: 'textarea',
        input: true,
        key: 'tooltip',
        label: 'Tooltip',
        placeholder: 'To add a tooltip to this field, enter text here.',
        tooltip: 'Adds a tooltip to the side of this field.',
        editor: 'ace',
        as: 'html',
        wysiwyg: {
          minLines: 3,
          isUseWorkerDisabled: true
        }
      },
      {
        weight: 500,
        type: 'textfield',
        input: true,
        key: 'customClass',
        label: 'Custom CSS Class',
        placeholder: 'Custom CSS Class',
        tooltip: 'Custom CSS class to add to this component.'
      },
      {
        type: 'textfield',
        label: 'ACL Key',
        key: 'aclResource',
        weight: 5,
        placeholder: 'ACL Key',
        input: true
      },
      {
        weight: 1100,
        type: 'checkbox',
        label: 'Hidden',
        tooltip:
          'A hidden field is still a part of the form, but is hidden from view.',
        key: 'hidden',
        input: true
      },
      {
        weight: 1200,
        type: 'checkbox',
        label: 'Hide Label',
        tooltip:
          'Hide the label (title, if no label) of this component. This allows you to show the label in the form builder, but not when it is rendered.',
        key: 'hideLabel',
        input: true
      },
      {
        weight: 1400,
        type: 'checkbox',
        label: 'Disabled',
        tooltip: 'Disable the form input.',
        key: 'disabled',
        input: true
      }
    ]
  };
}

function dataTab() {
  return {
    label: 'Data & API',
    key: 'data_api',
    components: [
      {
        weight: 0,
        type: 'textfield',
        input: true,
        key: 'key',
        label: 'Property Name',
        tooltip: 'The name of this field in the API endpoint.',
        validate: {
          pattern: '(\\w|\\w[\\w-.]*\\w)',
          patternMessage:
            'The property name must only contain alphanumeric characters, underscores, dots and dashes and should not be ended by dash or dot.',
          required: true
        }
      },
      {
        type: 'select',
        input: true,
        key: 'customOptions.dependsOn',
        label: 'Depends On ',
        weight: 19,
        multiple: true,
        description:
          'Refresh data when another field changes. <b class="has-error">DEPRECATED</b> : use <b>Depends On - Field Metadata</b>',
        dataSrc: 'custom',
        valueProperty: 'value',
        data: {
          custom(context) {
            let values = [{}];
            context.utils.eachComponent(
              context.instance.options.editForm.components,
              function (component, path) {
                // if (component.key !== context.data.key) {
                values.push({
                  label: component.label + ' (' + component.key + ')',
                  value: path
                });
                //  }
              }
            );
            return values;
          }
        }
      },
      {
        key: 'customOptions.dependsOnConfig',
        type: 'editgrid',
        label: 'Depends On - Field Metadata',
        input: true,
        tooltip: '',
        weight: 702,
        addAnother: 'Add Column',
        saveRow: 'Save',
        tableView: false,
        reorder: true,
        inlineEdit: false,
        modal: true,
        displayAsTable: true,
        components: [
          {
            type: 'select',
            input: true,
            key: 'fieldName',
            label: 'Depends On',
            weight: 19,
            multiple: false,
            tooltip: 'Refresh data when another field changes.',
            dataSrc: 'custom',
            valueProperty: 'value',
            data: {
              custom(context) {
                let values: any[] = [];
                context.utils.eachComponent(
                  context.instance.options.editForm.components,
                  function (component, path) {
                    //    if (component.key !== context.data.key) {
                    values.push({
                      label: component.label + ' (' + component.key + ')',
                      value: path
                    });
                    // }
                  }
                );
                return values;
              }
            }
          },
          {
            type: 'select',
            input: true,
            key: 'changeEventType',
            label: 'Depends On change Event Type',
            weight: 19,
            multiple: false,
            tooltip: 'Event Type that triggers the Refresh.',
            dataSrc: 'values',
            data: {
              values: [
                {
                  label: 'Click',
                  value: 'click'
                },
                {
                  label: 'Change',
                  value: 'change'
                },
                {
                  label: 'On Key up',
                  value: 'keyup'
                },
                {
                  label: 'On Key Down',
                  value: 'keydown'
                }
              ]
            }
          },
          {
            weight: 5,
            type: 'textfield',
            input: true,
            key: 'changeEventTypeParams',
            label: 'Event Type Key ',
            description: `In case of Key events, the key triggered the event. Ex. "Enter" `,
            validate: {
              required: false
            }
          }
        ]
      },
      {
        weight: 0,
        type: 'textfield',
        input: true,
        key: 'customOptions.apiUrl',
        label: 'API URL with Query Param',
        tooltip: 'API URL that should be used to retrieve the values.',
        validate: {
          required: true
        }
      },
      {
        weight: 5,
        type: 'textfield',
        input: true,
        key: 'customOptions.apiDataPath',
        label: 'Data Path to be used to extract the required value',
        description: `Data Path to be used to extract the required value<br> Note: use * to extract complete data from the response`,
        validate: {
          required: true
        }
      },
      {
        weight: 100,
        type: 'textfield',
        input: true,
        key: 'customOptions.dataKey',
        label: 'Property Name',
        description: `Data Path to be used to merge required value into submission <br> Note: use * to merge data into root submission`
      },
      {
        key: 'customOptions.valueProcessor',
        weight: 200,
        type: 'textarea',
        input: true,
        editor: 'ace',
        as: 'javascript',
        wysiwyg: {
          minLines: 12
        },
        defaultValue: undefined,
        label: 'Set Value',
        description:
          'Use this processor to set the value of this text field. The response of the API will be passed in as parameter.'
      }
    ]
  };
}

class Status {
  static status = new Map();
}

export function registerUrlBasedTextFieldComponent(
  injector: Injector,
  screen: string
) {
  if (!Status.status.has(screen)) {
    try {
      registerCustomFormioComponent(
        COMPONENT_OPTIONS,
        UrlBasedTextfieldComponent,
        injector
      );
      Status.status.set(screen, true);
    } catch (e) {
      console.log(
        'Error while registering UrlBasedTextFieldComponent: ' + screen
      );
    }
  }
}
