import {
  APP_INITIALIZER,
  CUSTOM_ELEMENTS_SCHEMA,
  NgModule
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import {
  HTTP_INTERCEPTORS,
  HttpClientModule
} from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { SharedModule } from './shared/shared.module';
import { HomeModule } from './home/home.module';
import { httpInterceptorProviders } from './shared/interceptors/http.interceptor';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DialogService } from 'primeng/dynamicdialog';
import { LoaderComponent } from './shared/components/loader/loader.component';
import { LoaderService } from './shared/services/loader.service';
import { LoaderInterceptor } from './shared/interceptors/loader.interceptor';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { BootstrapService } from './shared/services/bootstrap.service';
import { ToastrModule } from 'ngx-toastr';
import { TimelineModule } from 'primeng/timeline';
import {NgIdleModule} from "@ng-idle/core";
@NgModule({
  declarations: [AppComponent, LoaderComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    SharedModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    HomeModule,
    ProgressSpinnerModule,
    TimelineModule,
    ToastrModule.forRoot({
      timeOut: 1000,
      enableHtml: true,
      toastClass: 'ifas-toast ngx-toastr',
      closeButton: true,
      positionClass: 'toast-top-right',
      preventDuplicates: true
    }),
    NgIdleModule.forRoot()
  ],
  providers: [
    httpInterceptorProviders,
    DialogService,
    LoaderService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptor,
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: bootstrapServiceFactory,
      deps: [BootstrapService],
      multi: true
    } /*,
    {
      provide: RouteReuseStrategy,
      useClass: MyCustomRouteReuseStrategy
    }*/
  ],

  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {}

export function bootstrapServiceFactory(config: BootstrapService) {
  return () => config.init();
}

