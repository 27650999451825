<nav class="navbar navbar-expand-lg">
  <div class="container-fluid border-bottom border-color-supp-1">
    <div class="sidebar-header">
      <a class="navbar-brand" href="#">
        <img alt="Logo" src="/assets/images/sg_court_logo.png"/>
      </a>
    </div>
    <div class="content-header d-flex">
      <!--<button class="btn btn-primary" id="sidebarToggle">Toggle Menu</button>-->
      <button
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
        class="navbar-toggler"
        data-bs-target="#navbarSupportedContent"
        data-bs-toggle="collapse"
        type="button"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <div class="app-title primary-color pl-3 text-2xl">
          Integrated Finance Administration System
        </div>
        <div class="banner-text"></div>
        <ul class="navbar-nav ms-auto mt-2 mt-lg-0">
          <!--<li class="nav-item active">
            <a class="nav-link" href="#!">Home</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#!">Link</a>
          </li>-->
          <li class="nav-item dropdown">
            <div class="d-flex">
              <div class="d-flex">
                <a (click)="zoom('-')"
                   class="m-auto text-xxs prevent-select"
                   title="{{ currentZoom | number: '1.1-1' }}"
                >A-</a>
                <a
                  (click)="zoom('+')"
                  class="m-2 text-small-bold prevent-select"
                  title="{{ currentZoom | number: '1.1-1' }}"
                >A+</a
                >
              </div>
              <div>
                <a
                  aria-expanded="false"
                  aria-haspopup="true"
                  class="nav-link dropdown-toggle user-profile"
                  data-bs-toggle="dropdown"
                  href="#"
                  id="navbarDropdown"
                  role="button"
                >
                  <img
                    *ngIf="imageUrl"
                    [src]="imageUrl"
                    class="rounded-circle z-depth-0"
                    alt="avatar image"
                    height="35"
                  />
                  <!--      <span class="d-inline-block user-status"><i class="fas fa-user"></i></span>-->
                  {{ userInfo?.displayName }} - {{ userInfo?.judiciaryId }}
                </a>
                <div
                  aria-labelledby="navbarDropdown"
                  class="dropdown-menu dropdown-menu-end"
                >
                  <a class="dropdown-item" href="app/users/my-profile"><i class="fa-solid fa-user-gear mr-2"></i>My
                    Profile</a>
                  <a class="dropdown-item" href="app/users/otp-validation" *ngIf="privileged"> <i
                    class="fa-sharp fa-solid fa-shield-halved  mr-2"></i>Elevate Permission</a>
                  <a class="dropdown-item" (click)="refreshProfile()"><i
                    class="fa-solid fa-arrows-rotate fa-flip-both mr-2"></i>Refresh Profile</a>
                  <a class="dropdown-item" href=" app/ifas/request-access" > <i class="fa-solid fa-building-lock mr-2"></i>Request Access</a>

                  <div class="dropdown-divider"></div>
                  <a
                    class="dropdown-item"
                    *ngFor="let theme of themeList"
                    href="javascript:"
                    (click)="changeTheme(theme)"
                  >{{ theme.title }}</a
                  >
                  <!--<div class="dropdown-divider"></div>-->
                  <!-- <a class="dropdown-item" href="#!">Something else here</a>-->
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</nav>
