import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import { FormioCustomComponent } from '@evi-ui/angular';
import { FormioEvent } from '@evi-ui/angular/elements.common';
import { ActionHandler } from '@shared/components/screen-configuration/screen-configuration.model';

@Component({
  selector: 'screen-configuration',
  templateUrl: './screen-configuration.component.html',
  styleUrls: ['././screen-configuration.component.scss']
})
export class ScreenConfigurationComponent
  implements FormioCustomComponent<string>, OnChanges
{
  private _form: any;

  constructor() {
    this.valueFromApi = '';
  }

  @Input()
  key: string;

  @Input()
  dataKey: string;

  private dependsOnValueMap = new Map();
  _dependsOn: string[];
  @Input()
  public set dependsOn(val: any) {
    if (!val) return;
    this._dependsOn = val;
  }

  _apiUrl: string;
  @Input()
  public set apiUrl(val: any) {
    if (!val) return;
    this._apiUrl = val;
  }

  _apiDataPath: string;
  @Input()
  public set apiDataPath(val: any) {
    if (!val) return;
    this._apiDataPath = val;
  }

  valueFromApi: string;
  @Input()
  value: string = '';

  @Output()
  valueChange = new EventEmitter<string>();

  @Output()
  formioEvent?: EventEmitter<FormioEvent>;

  @Input()
  disabled: boolean = false;

  @Input()
  actionHandler: ActionHandler;
  private _options = new Map();
  @Input()
  public set options(opts: any) {
    this._options = new Map();
    if (opts && opts.length > 0) {
      opts.forEach((option: { label; value }) => {
        this._options[option.label] = option.value;
      });
    }
  }

  public get options(): any {
    return this._options;
  }

  ngOnChanges(changes: SimpleChanges) {}

  ngOnInit() {
    this.dependsOnValueMap.clear();
    console.log('Action Handler: ', this.actionHandler);
  }
}
