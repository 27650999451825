import * as _ from 'lodash';

export class UserUtils {
  public static generateAvatar(
    text: any,
    foregroundColor = '#6c757d',
    backgroundColor = '#faf7f7'
  ): string {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');

    canvas.width = 250;
    canvas.height = 250;
    const nameList: string[] = _.split(text.name, '+');
    const gravatarName = nameList
      .map((name) => name.substr(0, 1).toUpperCase())
      .join('');

    if (context != null) {
      context.fillStyle = backgroundColor;
      context.fillRect(0, 0, canvas.width, canvas.height);

      context.font = 'bold 150px Tahoma';
      context.fillStyle = foregroundColor;
      context.textAlign = 'center';
      context.textBaseline = 'middle';
      context.fillText(gravatarName, canvas.width / 2, canvas.height / 1.8);
    }
    return canvas.toDataURL('image/png');
  }
}
