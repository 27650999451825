import {
  CustomButtonActionHandler,
  CustomButtonActionHandlerContext
} from '@shared/components/custom-action-button/handlers/custom-button-action-handler';
import { CustomButtonActionType } from '@shared/components/custom-action-button/handlers/custom-button-action-handler-registry';
import { FormRendererUtils } from '../../../../portal/application-forms/all-forms-renderer/form-renderer-utils';
import { Injectable } from '@angular/core';
import _ from 'lodash';

@Injectable({ providedIn: 'root' })
export class RedrawComponentsActionProcessor
  implements CustomButtonActionHandler
{
  constructor(private formRenderUtils: FormRendererUtils) {}

  isApplicable(actionType: CustomButtonActionType): boolean {
    return actionType === CustomButtonActionType.redrawComponents;
  }

  async performAction(context: CustomButtonActionHandlerContext) {
    let actionProcessor = context.actionProcessor;
    this.formRenderUtils.resolveDynamicHtmlContent(context.formInstance.components, context.formInstance.submission);
    actionProcessor.redrawComponentsConfig.componentsToRedraw
      .filter((componentKey) => !_.isNil(componentKey))
      .forEach((componentKey) => {
        let component = FormRendererUtils.getComponent(
          componentKey,
          context.formInstance.components
        );
        component.redraw();
      });

    return Promise.resolve();
  }
}
