import {
  CustomButtonActionHandler,
  CustomButtonActionHandlerContext
} from '@shared/components/custom-action-button/handlers/custom-button-action-handler';
import {
  CustomButtonActionType
} from '@shared/components/custom-action-button/handlers/custom-button-action-handler-registry';
import {Injectable} from '@angular/core';
import {LodashTemplateEvaluator} from '@shared/components/smart-table/LodashTemplateEvaluator';
import * as _ from 'lodash';
import {Formio} from '@evi-ui/angular';
import {IamAuthUtils} from '@shared/utils/iam-auth-utils';
import {FormRendererUtils} from '../../../../portal/application-forms/all-forms-renderer/form-renderer-utils';

@Injectable({providedIn: 'root'})
export class SendDataToApiActionProcessor implements CustomButtonActionHandler {
  isApplicable(actionType: CustomButtonActionType): boolean {
    return actionType === CustomButtonActionType.sendDataToApi;
  }

  async performAction(context: CustomButtonActionHandlerContext) {
    return this.sendToServer(context);
  }

  private sendToServer(context: CustomButtonActionHandlerContext) {
    const apiConfig = context.actionProcessor.apiConfig;
    const formInstance = _.cloneDeep(context.formInstance);
    const formData = _.cloneDeep(context.formData);

    let shouldCallApi = true;
    if (apiConfig.isConditional && apiConfig.conditionToEvaluate) {
      shouldCallApi = FormRendererUtils.evaluate(
        apiConfig.conditionToEvaluate,
        {
          formInstance: formInstance,
          formData: formData
        },
        'value',
        true
      );
    }

    if (!shouldCallApi) {
      return Promise.resolve();
    }

    const url = LodashTemplateEvaluator.interpolate(
      apiConfig.url,
      formInstance.submission
    );
    let requestBody = apiConfig.requestBody;
    if (requestBody && requestBody.trim().length > 0) {
      requestBody = FormRendererUtils.evaluate(
        requestBody,
        {
          formInstance: formInstance,
          formData: formData,
          currentUser: IamAuthUtils.getCurrentLoggedUserIamId()
        },
        'request',
        true
      );
    }

    if (apiConfig.requestBodyParameter) {
      requestBody = _.get(
        formInstance.submission.data,
        apiConfig.requestBodyParameter
      );
      if (!requestBody) {
        //The consumer can pass the data in submission instead of data
        requestBody = _.get(
          formInstance.submission,
          apiConfig.requestBodyParameter
        );
      }
      if (!requestBody) {
        //Look for data in formInstance. An action processor usually keeps the result in here.
        requestBody = _.get(formInstance, apiConfig.requestBodyParameter);
      }
    }

    let apiMethod = apiConfig.methodName;
    if (!apiMethod) {
      apiMethod = 'POST';
    }

    console.log('Request Body for ', apiConfig.url, ' is ', requestBody);
    return Formio.fetch(url, {
      headers: {
        'content-type': 'application/json',
        Authorization: 'Bearer ' + IamAuthUtils.getCurrentUserToken()
      },
      method: apiMethod,
      ...(apiMethod !== 'GET' && { body: JSON.stringify(requestBody) }), // Only add body for non-GET methods
    })
      .then((resp) => {
        if (resp.headers.has('content-disposition')) {
          // file download
          return [resp.blob(), resp.clone()];
        }
        const json = resp.json();
        return [json, json];
      })
      .then(([responseData, rawResponseWithHeaders]) => {
        return Promise.resolve([responseData, rawResponseWithHeaders]);
      });
  }
}
