import {Injector} from '@angular/core';
import {FormioCustomComponentInfo, registerCustomFormioComponent} from '@evi-ui/angular';
import {CustomActionButtonComponent} from './custom-action-button.component';
import {
  AfterPostToUrlAction,
  CustomActionType,
  CustomButtonActionType
} from './handlers/custom-button-action-handler-registry';

const COMPONENT_OPTIONS: FormioCustomComponentInfo = {
  type: 'toggle-button',
  selector: 'custom-action-button',
  title: 'Action Button',
  group: 'basic',
  icon: 'fa fa-solid fa-laptop-code',
  emptyValue: {},
  editForm: editForm
};

function editForm() {
  return {
    components: [
      {key: 'type', type: 'hidden'},
      {
        label: 'Tabs',
        components: [
          displayTab(),
          actionProcessors(),
          dataTab(),
          conditionalTab()
        ],
        key: 'tabs',
        type: 'tabs',
        input: false,
        tableView: false
      }
    ]
  };
}

function displayTab() {
  return {
    key: 'display',
    label: 'Display',
    components: [
      {
        key: 'customOptions.label',
        weight: 1,
        type: 'textfield',
        input: true,
        label: 'Label',
        placeholder: 'Button Label',
        tooltip: 'Button Label.'
      },
      {
        key: 'customOptions.dynamicLabelValue',
        weight: 1,
        type: 'textfield',
        input: true,
        label: 'Dynamic Label',
        placeholder: 'Dynamic Button Label',
        tooltip: 'Dynamic Button Label.'
      },
      {
        type: 'select',
        key: 'customOptions.theme',
        label: 'Theme',
        input: true,
        tooltip: 'The color theme of this button.',
        dataSrc: 'values',
        weight: 140,
        data: {
          values: [
            {label: 'Primary', value: 'primary'},
            {label: 'Secondary', value: 'secondary'},
            {label: 'Info', value: 'info'},
            {label: 'Success', value: 'success'},
            {label: 'Danger', value: 'danger'},
            {label: 'Warning', value: 'warning'}
          ]
        }
      },
      {
        type: 'textfield',
        key: 'customOptions.leftIcon',
        label: 'Left Icon',
        input: true,
        placeholder: 'Enter icon classes',
        tooltip:
          "This is the full icon class string to show the icon. Example: 'bi bi-plus'",
        weight: 160
      },
      {
        type: 'textfield',
        key: 'customOptions.rightIcon',
        label: 'Right Icon',
        input: true,
        placeholder: 'Enter icon classes',
        tooltip:
          "This is the full icon class string to show the icon. Example: 'bi bi-plus'",
        weight: 170
      },
      {
        type: 'checkbox',
        key: 'customOptions.blockButton',
        label: 'Block Button',
        input: true,
        tooltip: 'This will make the button to fill up the container',
        weight: 170
      },
      {
        type: 'checkbox',
        input: true,
        key: 'customOptions.dropDownButton',
        label: 'Dropdown Button',
        defaultValue: false,
        tooltip: 'Drop Down Menu with Multiple Navigation options',
        weight: 704
      },
      {
        type: 'checkbox',
        input: true,
        key: 'customOptions.showInWorkflowItem',
        label: 'Display in Workflow Item Screen',
        defaultValue: false,
        tooltip: 'This will display the button in Workflow Item View Screen',
        weight: 704
      },
      {
        key: 'customOptions.dropDownButtonOptions',
        weight: 704,
        label: 'DropDownButtonOptions',
        tooltip: '',
        type: 'datagrid',
        input: true,
        reorder: true,
        components: [
          {
            key: 'buttonOptionLabel',
            label: 'Buttton Menu Label',
            type: 'textfield',
            input: true
          },
          {
            key: 'buttonOptionUrl',
            label: 'Dropdown Button - Action Processor',
            type: 'textarea',
            input: true,
            editor: 'ace',
            as: 'javascript',
            wysiwyg: {
              minLines: 12
            }
          }
        ]
      },
      {
        key: 'customOptions.customClass',
        weight: 500,
        type: 'textfield',
        input: true,
        label: 'Custom CSS Class',
        placeholder: 'Custom CSS Class',
        tooltip: 'Custom CSS class to add to this component.'
      },
      {
        key: 'hidden',
        weight: 1100,
        type: 'checkbox',
        label: 'Hidden',
        tooltip:
          'A hidden field is still a part of the form, but is hidden from view.',
        input: true,
        defaultValue: false
      },
      {
        key: 'disabled',
        weight: 1400,
        type: 'checkbox',
        label: 'Disabled',
        tooltip: 'Disable the form input.',
        input: true
      }
    ]
  };
}

function actionProcessors() {
  return {
    label: 'Action Processors',
    key: 'actionProcessorsConfiguration',
    weight: 800,
    components: [
      {
        key: 'actionProcessorsChain',
        type: 'fieldset',
        legend: 'Action Processors Chain (NOT YET IMPLEMENTED)',
        components: [
          {
            key: 'customOptions.actionProcessors',
            type: 'editgrid',
            label: 'Action Processor',
            addAnother: 'Add Another',
            tableView: false,
            reorder: true,
            saveRow: 'Save',
            description: 'Action Processor Chaining',
            components: [
              {
                key: 'actionType',
                type: 'select',
                label: 'Action Type',
                input: true,
                tooltip: '',
                dataSrc: 'values',
                data: {
                  values: [
                    {
                      label: 'Go Back (Browser Back button)',
                      value: CustomButtonActionType.goBack
                    },
                    {
                      label: 'Close All Popups',
                      value: CustomButtonActionType.closeAllPopups
                    },
                    {
                      label: 'Download File',
                      value: CustomButtonActionType.downloadFile
                    },
                    {
                      label: 'Get Data From API',
                      value: CustomButtonActionType.getDataFromApi
                    },
                    {
                      label: 'Execute Custom Logic',
                      value: CustomButtonActionType.executeCustomLogic
                    },
                    {
                      label: 'Navigate To URL',
                      value: CustomButtonActionType.navigateToUrl
                    },
                    {
                      label: 'Refresh/Redraw Components',
                      value: CustomButtonActionType.redrawComponents
                    },
                    {
                      label: 'Set Field Values',
                      value: CustomButtonActionType.setFieldValues
                    },
                    {
                      label: 'Smart Table - Advanced Search',
                      value:
                      CustomButtonActionType.smartTableAdvanceFilterAction
                    },
                    {
                      label: 'Smart Table - Reset Search Results',
                      value: CustomButtonActionType.resetSmartTableResults
                    },
                    {
                      label: 'Show Popup',
                      value: CustomButtonActionType.showPopup
                    },
                    {
                      label: 'Send Data to API',
                      value: CustomButtonActionType.sendDataToApi
                    },
                    {
                      label: 'Toggle Component(s) Visibility',
                      value: CustomButtonActionType.toggleComponentsVisibility
                    },
                    {
                      label: 'Trigger Event',
                      value: CustomButtonActionType.triggerEvent
                    }
                  ]
                },
                weight: 1,
                validate: {
                  required: true
                }
              },
              {
                key: 'actionName',
                type: 'textfield',
                label: 'Action Name',
                input: true,
                tooltip:
                  'This will be used as variable name to hold the result of this action',
                weight: 3,
                validate: {
                  required: true
                }
              },
              {
                key: 'isConditional',
                type: 'checkbox',
                label: 'Is Conditional?',
                input: true,
                tooltip:
                  'Check this if the action should be processed based on a condition.',
                weight: 3
              },
              {
                key: 'conditionToEvaluate',
                type: 'textarea',
                label: 'Perform Action On Condition (if true)',
                description: 'Perform action based on condition',
                placeholder: 'canExecute = true',
                tooltip: 'write javascript code which is evaluated to give the input for action. Return `canExecute` variable',
                editor: 'ace',
                as: 'javascript',
                wysiwyg: {
                  minLines: 4
                },
                customConditional(context) {
                  return (
                    context.row.isConditional
                  );
                },
                weight: 7
              },
              ...sendDataToApiModel(),
              ...popupModel(),
              ...navigationConfig(),
              ...executeCustomLogic(),
              ...redrawComponents(),
              ...setFieldValues(),
              ...toggleComponentsVisibility(),
              ...triggerEvent()
            ]
          }
        ]
      },
      {
        key: 'legacyActionProcessor',
        type: 'fieldset',
        legend: 'Legacy Action Processor',
        components: [
          {
            key: 'customOptions.actionType',
            type: 'select',
            label: 'Action Type',
            input: true,
            tooltip: '',
            dataSrc: 'values',
            data: {
              values: [
                {label: 'Navigate To URL', value: CustomActionType.navigate},
                {label: 'Download File', value: 'downloadFile'},
                {
                  label: 'Smart Table - Advanced Search',
                  value: CustomActionType.advanceFilterAction
                },
                {
                  label: 'Smart Table - Reset Search Results',
                  value: CustomActionType.resetSmartTableResults
                },
                {label: 'Show Popup', value: CustomActionType.showPopup},
                {label: 'POST/PUT To URL', value: CustomActionType.postToUrl},
                {
                  label: 'GET Data From API',
                  value: CustomActionType.getDataFromApi
                },
                {
                  label: 'Execute Script & Show Popup',
                  value: CustomActionType.executeScriptAndShowPopup
                }
              ]
            },
            weight: 1
          },
          {
            key: 'customOptions.navigationConfig.navigateToUrl',
            weight: 200,
            type: 'textfield',
            input: true,
            label: 'URL To Navigate To',
            placeholder: 'URL To Navigate To',
            tooltip: 'URL To Navigate To',
            conditional: {
              json: {
                '===': [{var: 'data.customOptions.actionType'}, 'navigate']
              }
            }
          },
          {
            key: 'customOptions.navigationConfig.stateToPropagate',
            weight: 200,
            type: 'textfield',
            input: true,
            label: 'State To Propagate',
            conditional: {
              json: {
                '===': [{var: 'data.customOptions.actionType'}, 'navigate']
              }
            }
          },
          {
            key: 'customOptions.navigationConfig.stateToPropagateEvaluator',
            weight: 200,
            type: 'textarea',
            input: true,
            editor: 'ace',
            as: 'javascript',
            wysiwyg: {
              minLines: 12
            },
            defaultValue: undefined,
            label: 'State To Propagate Evaluator',
            conditional: {
              json: {
                '===': [{var: 'data.customOptions.actionType'}, 'navigate']
              }
            }
          },
          {
            key: 'customOptions.postToUrlConfig.url',
            weight: 200,
            type: 'textfield',
            input: true,
            label: 'URL To POST To',
            placeholder: 'URL To POST To',
            validate: {
              required: true
            },
            conditional: {
              json: {
                '===': [{var: 'data.customOptions.actionType'}, 'postToUrl']
              }
            }
          },
          {
            key: 'customOptions.postToUrlConfig.methodName',
            weight: 200,
            type: 'select',
            input: true,
            label: 'API Method',
            placeholder: 'API Method',
            validate: {
              required: true
            },
            dataSrc: 'values',
            data: {
              values: [
                {label: 'POST', value: 'POST'},
                {label: 'PUT', value: 'PUT'}
              ]
            },
            defaultValue: 'POST',
            conditional: {
              json: {
                '===': [{var: 'data.customOptions.actionType'}, 'postToUrl']
              }
            }
          },
          {
            key: 'customOptions.postToUrlConfig.requestBodyParameter',
            weight: 200,
            type: 'textfield',
            input: true,
            label: 'Request Body data parameter',
            placeholder: 'Request Body data parameter',
            conditional: {
              json: {
                '===': [{var: 'data.customOptions.actionType'}, 'postToUrl']
              }
            }
          },
          {
            key: 'customOptions.postToUrlConfig.skipValidation',
            weight: 200,
            type: 'checkbox',
            input: true,
            label: 'Skip Validation',
            conditional: {
              json: {
                '===': [{var: 'data.customOptions.actionType'}, 'postToUrl']
              }
            }
          },
          {
            key: 'customOptions.postToUrlConfig.responseContextVariable',
            weight: 200,
            type: 'textfield',
            input: true,
            label: 'Response Context Variable',
            placeholder: '',
            conditional: {
              json: {
                '===': [{var: 'data.customOptions.actionType'}, 'postToUrl']
              }
            }
          },
          {
            key: 'customOptions.postToUrlConfig.beforePost',
            weight: 200,
            type: 'textarea',
            input: true,
            editor: 'ace',
            as: 'javascript',
            wysiwyg: {
              minLines: 12
            },
            defaultValue: undefined,
            label: 'Before POST',
            placeholder: 'Before POST',
            conditional: {
              json: {
                '===': [{var: 'data.customOptions.actionType'}, 'postToUrl']
              }
            }
          },
          {
            key: 'customOptions.postToUrlConfig.afterPost.action',
            weight: 200,
            type: 'select',
            input: true,
            dataSrc: 'values',
            data: {
              values: [
                {label: 'Navigate To URL', value: AfterPostToUrlAction.navigate},
                {label: 'Show Popup', value: 'showPopup'},
                {
                  label: 'Show Popup & Navigate',
                  value: 'showPopupAndNavigate'
                },
                {label: 'Download File', value: 'downloadFile'},
                {
                  label: 'Process API Response',
                  value: AfterPostToUrlAction.processApiResponse
                },
                {label: 'No Action', value: 'noAction'}
              ]
            },
            defaultValue: 'noAction',
            label: 'After POST',
            placeholder: 'After POST',
            conditional: {
              json: {
                '===': [{var: 'data.customOptions.actionType'}, 'postToUrl']
              }
            }
          },
          {
            key: 'customOptions.postToUrlConfig.afterPost.navigationUrl',
            weight: 201,
            type: 'textfield',
            input: true,
            label:
              'Navigation URL (applicable only if After POST action is Download File or Navigate To URL)',
            placeholder: 'URL to navigate to after file is downloaded, or URL to navigate after POST action',
            tooltip:
              'Navigation URL (applicable only if After POST action is Download File or Navigate To URL)',
            conditional: {
              json: {
                'or': [
                  {'===': [{var: 'data.customOptions.postToUrlConfig.afterPost.action'}, AfterPostToUrlAction.downloadFile]},
                  {'===': [{var: 'data.customOptions.postToUrlConfig.afterPost.action'}, AfterPostToUrlAction.navigate]},
                ]
              },
            }
          },
          {
            key: 'customOptions.scriptExecutionWithPopupConfig.scriptToExecute',
            weight: 200,
            type: 'textarea',
            input: true,
            editor: 'ace',
            as: 'javascript',
            wysiwyg: {
              minLines: 25
            },
            defaultValue: undefined,
            label: 'Script to execute',
            conditional: {
              json: {
                '===': [
                  {var: 'data.customOptions.actionType'},
                  CustomActionType.executeScriptAndShowPopup
                ]
              }
            }
          },
          {
            key: 'customOptions.scriptExecutionWithPopupConfig.popupScreenId',
            weight: 201,
            type: 'textfield',
            input: true,
            label: 'Popup Screen Id',
            placeholder: 'ID of the Screen to be used for popup.',
            tooltip: 'ID of the Screen to be used for popup.',
            conditional: {
              json: {
                '===': [
                  {var: 'data.customOptions.actionType'},
                  CustomActionType.executeScriptAndShowPopup
                ]
              }
            }
          },

          {
            key: 'customOptions.postToUrlConfig.afterPost.apiResponseProcessor',
            weight: 300,
            type: 'textarea',
            input: true,
            editor: 'ace',
            as: 'javascript',
            wysiwyg: {
              minLines: 25
            },
            defaultValue: undefined,
            label: 'API Response Processor',
            conditional: {
              json: {
                '===': [
                  {
                    var: 'data.customOptions.postToUrlConfig.afterPost.action'
                  },
                  AfterPostToUrlAction.processApiResponse
                ]
              }
            }
          },
          {
            key: 'customOptions.postToUrlConfig.showPopup',
            weight: 200,
            type: 'checkbox',
            input: true,
            label: 'Show Popup',
            conditional: {
              json: {
                '===': [{var: 'data.customOptions.actionType'}, 'postToUrl']
              }
            }
          },
          {
            key: 'customOptions.postToUrlConfig.executePopupCondition',
            weight: 200,
            type: 'textarea',
            input: true,
            editor: 'ace',
            as: 'javascript',
            wysiwyg: {
              minLines: 12
            },
            defaultValue: undefined,
            label: 'Execute Popup Condition',
            placeholder: 'Execute Popup Condition',
            conditional: {
              json: {
                '===': [
                  {var: 'data.customOptions.postToUrlConfig.showPopup'},
                  true
                ]
              }
            }
          },
          {
            key: 'success-popup',
            type: 'panel',
            title: 'On Success Popup',
            theme: 'default',
            collapsible: true,
            weight: 105,
            conditional: {
              json: {
                or: [
                  {
                    '===': [
                      {
                        var: 'data.customOptions.postToUrlConfig.afterPost.action'
                      },
                      'showPopup'
                    ]
                  },
                  {
                    '===': [
                      {
                        var: 'data.customOptions.postToUrlConfig.afterPost.action'
                      },
                      'showPopupAndNavigate'
                    ]
                  }
                ]
              }
            },
            components: [
              {
                key: 'customOptions.postToUrlConfig.afterPost.successPopupConfig',
                type: 'container',
                placeholder: 'Popup Config',
                legend: 'Success Message Popup Config',
                weight: 300,
                conditional: {
                  json: {
                    or: [
                      {
                        '===': [
                          {
                            var: 'data.customOptions.postToUrlConfig.afterPost.action'
                          },
                          'showPopup'
                        ]
                      },
                      {
                        '===': [
                          {
                            var: 'data.customOptions.postToUrlConfig.afterPost.action'
                          },
                          'showPopupAndNavigate'
                        ]
                      }
                    ]
                  }
                },
                components: [
                  {
                    key: 'redirectUrlAfterSubmission',
                    weight: 200,
                    type: 'textfield',
                    input: true,
                    label: 'URL To Navigate To',
                    placeholder: 'URL To Navigate To',
                    tooltip: 'URL To Navigate To After Submission',
                    conditional: {
                      json: {
                        '===': [
                          {
                            var: 'data.customOptions.postToUrlConfig.afterPost.action'
                          },
                          'showPopupAndNavigate'
                        ]
                      }
                    }
                  },
                  {
                    weight: 200,
                    type: 'textfield',
                    input: true,
                    key: 'headerMessage',
                    label: 'Header Message',
                    placeholder: 'Text to be shown in popup header.',
                    tooltip: 'Text to be shown in popup header.'
                  },
                  {
                    weight: 200,
                    type: 'textfield',
                    input: true,
                    key: 'popupScreenId',
                    label: 'Popup Screen Id',
                    placeholder: 'ID of the Screen to be used for popup.',
                    tooltip: 'ID of the Screen to be used for popup.'
                  },
                  {
                    weight: 200,
                    type: 'textfield',
                    input: true,
                    key: 'message',
                    label: 'Message',
                    placeholder: 'Message to be displayed in popup.',
                    tooltip: 'Message to be displayed in popup.'
                  }
                ]
              }
            ]
          },
          {
            key: 'error-popup',
            type: 'panel',
            title: 'On Error Popup',
            theme: 'default',
            collapsible: true,
            weight: 105,
            conditional: {
              json: {
                or: [
                  {
                    '===': [
                      {
                        var: 'data.customOptions.postToUrlConfig.afterPost.action'
                      },
                      'showPopup'
                    ]
                  },
                  {
                    '===': [
                      {
                        var: 'data.customOptions.postToUrlConfig.afterPost.action'
                      },
                      'showPopupAndNavigate'
                    ]
                  }
                ]
              }
            },
            components: [
              {
                key: 'customOptions.postToUrlConfig.afterPost.errorPopupConfig',
                type: 'container',
                placeholder: 'Popup Config',
                legend: 'Success Message Popup Config',
                weight: 300,
                conditional: {
                  json: {
                    or: [
                      {
                        '===': [
                          {
                            var: 'data.customOptions.postToUrlConfig.afterPost.action'
                          },
                          'showPopup'
                        ]
                      },
                      {
                        '===': [
                          {
                            var: 'data.customOptions.postToUrlConfig.afterPost.action'
                          },
                          'showPopupAndNavigate'
                        ]
                      }
                    ]
                  }
                },
                components: [
                  {
                    key: 'redirectUrlAfterSubmission',
                    weight: 200,
                    type: 'textfield',
                    input: true,
                    label: 'URL To Navigate To',
                    placeholder: 'URL To Navigate To',
                    tooltip: 'URL To Navigate To After Submission',
                    conditional: {
                      json: {
                        '===': [
                          {
                            var: 'data.customOptions.postToUrlConfig.afterPost.action'
                          },
                          'showPopupAndNavigate'
                        ]
                      }
                    }
                  },
                  {
                    weight: 200,
                    type: 'textfield',
                    input: true,
                    key: 'headerMessage',
                    label: 'Header Message',
                    placeholder: 'Text to be shown in popup header.',
                    tooltip: 'Text to be shown in popup header.'
                  },
                  {
                    weight: 200,
                    type: 'textfield',
                    input: true,
                    key: 'popupScreenId',
                    label: 'Popup Screen Id',
                    placeholder: 'ID of the Screen to be used for popup.',
                    tooltip: 'ID of the Screen to be used for popup.'
                  },
                  {
                    weight: 200,
                    type: 'textfield',
                    input: true,
                    key: 'message',
                    label: 'Message',
                    placeholder: 'Message to be displayed in popup.',
                    tooltip: 'Message to be displayed in popup.'
                  }
                ]
              }
            ]
          },

          Object.assign(getDataFromApiFormioModel(), {}),

          Object.assign(showPopupActionFormioModel(), {}),

          Object.assign(fileDownloadFormioModel(), {}),

          {
            key: 'customOptions.smartTableToApplyAdvancedSearchAction',
            type: 'select',
            input: true,
            label: 'Attach To Table',
            weight: 10,
            multiple: true,
            tooltip: 'Refresh data when another field changes.',
            dataSrc: 'custom',
            valueProperty: 'value',
            data: {
              custom(context) {
                let values = [{}];
                context.utils.eachComponent(
                  context.instance.options.editForm.components,
                  function (component, path) {
                    if (
                      component.key !== context.data.key &&
                      component.type == 'smarttable'
                    ) {
                      values.push({
                        label: component.label + ' (' + component.key + ')',
                        value: path
                      });
                    }
                  }
                );
                return values;
              }
            },
            conditional: {
              json: {
                '===': [
                  {var: 'data.customOptions.actionType'},
                  CustomActionType.advanceFilterAction
                ]
              }
            }
          },

          {
            key: 'customOptions.smartTableResetSearchResultsConfig.tablesToReset',
            type: 'select',
            input: true,
            label: 'Attach To Table',
            weight: 10,
            multiple: true,
            tooltip: 'Refresh data when another field changes.',
            dataSrc: 'custom',
            valueProperty: 'value',
            data: {
              custom(context) {
                let values = [{}];
                context.utils.eachComponent(
                  context.instance.options.editForm.components,
                  function (component, path) {
                    if (
                      component.key !== context.data.key &&
                      component.type == 'smarttable'
                    ) {
                      values.push({
                        label: component.label + ' (' + component.key + ')',
                        value: path
                      });
                    }
                  }
                );
                return values;
              }
            },
            conditional: {
              json: {
                '===': [
                  {var: 'data.customOptions.actionType'},
                  CustomActionType.resetSmartTableResults
                ]
              }
            }
          },

          {
            key: 'customOptions.smartTableResetSearchResultsConfig.otherFieldsToReset',
            type: 'select',
            input: true,
            label: 'Other Fields To Reset',
            weight: 11,
            multiple: true,
            tooltip: 'Reset fields.',
            dataSrc: 'custom',
            valueProperty: 'value',
            data: {
              custom(context) {
                let values = [{}];
                context.utils.eachComponent(
                  context.instance.options.editForm.components,
                  function (component, path) {
                    if (
                      component.key !== context.data.key &&
                      component.type !== 'smarttable' &&
                      component.key.startsWith('filter')
                    ) {
                      values.push({
                        label: component.label + ' (' + component.key + ')',
                        value: path
                      });
                    }
                  }
                );
                return values;
              }
            },
            conditional: {
              json: {
                '===': [
                  {var: 'data.customOptions.actionType'},
                  CustomActionType.resetSmartTableResults
                ]
              }
            }
          }
        ]
      }
    ]
  };
}

function triggerEvent() {
  return [
    {
      key: 'triggerEventConfig.eventName',
      weight: 200,
      type: 'textfield',
      input: true,
      label: 'Event Name',
      placeholder: 'eventName',
      tooltip: 'Event name to trigger',
      customConditional(context) {
        return context.row.actionType === CustomButtonActionType.triggerEvent;
      }
    }
  ];
}

function toggleComponentsVisibility() {
  return [
    {
      key: 'toggleComponentsVisibilityConfig.componentsToToggle',
      type: 'select',
      input: true,
      weight: 10,
      multiple: true,
      tooltip: '',
      dataSrc: 'custom',
      valueProperty: 'value',
      data: {
        custom(context) {
          let values = [{}];
          context.utils.eachComponent(
            context.instance.options.editForm.components,
            function (component, path) {
              if (component.key !== context.data.key) {
                values.push({
                  label: component.label + ' (' + component.key + ')',
                  value: path
                });
              }
            }
          );
          return values;
        }
      },
      label: 'Components to toggle their visibility',
      customConditional(context) {
        return (
          context.row.actionType ===
          CustomButtonActionType.toggleComponentsVisibility
        );
      }
    }
  ];
}

function setFieldValues() {
  return [
    {
      key: 'fieldValuesConfig',
      weight: 201,
      type: 'editgrid',
      label: 'Set Value On Fields',
      customConditional(context) {
        return context.row.actionType === CustomButtonActionType.setFieldValues;
      },
      components: [
        {
          key: 'fieldName',
          weight: 201,
          type: 'textfield',
          label: 'Field Name'
        },
        {
          key: 'fieldValue',
          weight: 202,
          type: 'textarea',
          editor: 'ace',
          rows: 2,
          description: 'Execute Javascript and return `value`',
          label: 'Field Value'
        },
        {
          key: 'appendIfArray',
          weight: 203,
          type: 'checkbox',
          description: 'If checked value will be appended to the field.',
          label: 'Append if field is an array'
        }
      ]
    }
  ];
}

function redrawComponents() {
  return [
    {
      key: 'redrawComponentsConfig.componentsToRedraw',
      type: 'select',
      input: true,
      weight: 10,
      multiple: true,
      tooltip: '',
      dataSrc: 'custom',
      valueProperty: 'value',
      data: {
        custom(context) {
          let values = [{}];
          context.utils.eachComponent(
            context.instance.options.editForm.components,
            function (component, path) {
              if (component.key !== context.data.key) {
                values.push({
                  label: component.label + ' (' + component.key + ')',
                  value: path
                });
              }
            },
            true
          );
          return values;
        }
      },
      label: 'Components to refresh/redraw',
      customConditional(context) {
        return (
          context.row.actionType === CustomButtonActionType.redrawComponents
        );
      }
    }
  ];
}

function executeCustomLogic() {
  return [
    {
      key: 'customLogicConfig.scriptToExecute',
      weight: 200,
      type: 'textarea',
      input: true,
      editor: 'ace',
      as: 'javascript',
      wysiwyg: {
        minLines: 25
      },
      defaultValue: undefined,
      label: 'Script to execute',
      customConditional(context) {
        return (
          context.row.actionType === CustomButtonActionType.executeCustomLogic
        );
      }
    }
  ];
}

function navigationConfig() {
  return [
    {
      key: 'navigationConfig.navigateToUrl',
      weight: 200,
      type: 'textfield',
      input: true,
      label: 'URL To Navigate To',
      placeholder: 'URL To Navigate To',
      tooltip: 'URL To Navigate To',
      customConditional(context) {
        return context.row.actionType === CustomButtonActionType.navigateToUrl;
      }
    },
    {
      key: 'navigationConfig.stateToPropagate',
      weight: 200,
      type: 'textfield',
      input: true,
      label: 'State To Propagate',
      customConditional(context) {
        return context.row.actionType === CustomButtonActionType.navigateToUrl;
      }
    },
    {
      key: 'navigationConfig.stateToPropagateEvaluator',
      weight: 200,
      type: 'textarea',
      input: true,
      editor: 'ace',
      as: 'javascript',
      wysiwyg: {
        minLines: 12
      },
      defaultValue: undefined,
      label: 'State To Propagate Evaluator',
      customConditional(context) {
        return context.row.actionType === CustomButtonActionType.navigateToUrl;
      }
    }
  ];
}

function sendDataToApiModel() {
  return [
    {
      key: 'apiConfig.url',
      weight: 200,
      type: 'textfield',
      input: true,
      label: 'URL To POST To',
      placeholder: 'URL To POST To',
      validate: {
        required: true
      },
      customConditional(context) {
        return context.row.actionType === CustomButtonActionType.sendDataToApi;
      }
    },
    {
      key: 'apiConfig.isConditional',
      type: 'checkbox',
      label: 'Trigger API On Condition (if true)',
      tooltip: '',
      customConditional(context) {
        return context.row.actionType === CustomButtonActionType.sendDataToApi;
      },
      weight: 7
    },
    {
      key: 'apiConfig.conditionToEvaluate',
      type: 'textarea',
      label: 'Trigger API On Condition (if true)',
      description: 'Trigger API based on condition',
      placeholder: 'show = true',
      tooltip: `write javascript code which is evaluated to give the input for action.`,
      editor: 'ace',
      as: 'javascript',
      wysiwyg: {
        minLines: 12
      },
      customConditional(context) {
        return (
          context.row.actionType === CustomButtonActionType.sendDataToApi &&
          context.row.apiConfig.isConditional
        );
      },
      defaultValue: 'show = true',
      weight: 7
    },
    {
      key: 'apiConfig.methodName',
      weight: 200,
      type: 'select',
      input: true,
      label: 'API Method',
      placeholder: 'API Method',
      validate: {
        required: true
      },
      dataSrc: 'values',
      data: {
        values: [
          {label: 'POST', value: 'POST'},
          {label: 'PUT', value: 'PUT'},
          {label: 'GET', value: 'GET'}
        ]
      },
      defaultValue: 'POST',
      customConditional(context) {
        return context.row.actionType === CustomButtonActionType.sendDataToApi;
      }
    },
    {
      key: 'apiConfig.requestBodyParameter',
      weight: 200,
      type: 'textfield',
      input: true,
      label: 'Request Body Field',
      placeholder: 'Request Body data parameter',
      customConditional(context) {
        return context.row.actionType === CustomButtonActionType.sendDataToApi;
      }
    },
    {
      key: 'apiConfig.skipValidation',
      weight: 200,
      type: 'checkbox',
      input: true,
      label: 'Skip Validation',
      tooltip: 'Skips validation of the form/screen before calling the API',
      customConditional(context) {
        return context.row.actionType === CustomButtonActionType.sendDataToApi;
      }
    },
    {
      key: 'apiConfig.requestBody',
      weight: 200,
      type: 'textarea',
      input: true,
      label: 'Request Body',
      description:
        'If `Request Body Field` is provided, then the value in this field will be ignored. Assign the value to be used a request body to `request` variable.',
      placeholder: 'Request to be sent in the API',
      editor: 'ace',
      as: 'javascript',
      wysiwyg: {
        minLines: 12
      },
      customConditional(context) {
        return context.row.actionType === CustomButtonActionType.sendDataToApi;
      }
    },
    {
      key: 'apiConfig.dataPath',
      weight: 200,
      type: 'textfield',
      input: true,
      label: 'Data path to extract from API response',
      customConditional(context) {
        return context.row.actionType === CustomButtonActionType.sendDataToApi;
      }
    }
  ];
}

function popupModel() {
  return [
    {
      key: 'popupAction.popupType',
      type: 'select',
      label: 'Popup Type',
      input: true,
      tooltip: '',
      dataSrc: 'values',
      data: {
        values: [
          {label: 'Confirmation Popup', value: 'confirmation'},
          {label: 'Information Popup', value: 'information'},
          {label: 'Reasoning Popup', value: 'reasoning'},
          {label: 'Custom Popup', value: 'custom'}
        ]
      },
      customConditional(context) {
        return context.row.actionType === CustomButtonActionType.showPopup;
      },
      validate: {
        required: true
      },
      weight: 2
    },
    {
      key: 'popupAction.message',
      type: 'textfield',
      label: 'Popup Message',
      input: true,
      tooltip: '',
      customConditional(context) {
        return context.row.actionType === CustomButtonActionType.showPopup;
      },
      weight: 3
    },
    {
      key: 'popupAction.headerText',
      type: 'textfield',
      label: 'Header Text',
      input: true,
      tooltip: '',
      customConditional(context) {
        return context.row.actionType === CustomButtonActionType.showPopup;
      },
      weight: 4
    },
    {
      key: 'popupAction.screenId',
      type: 'textfield',
      label: 'Poppup Screen Id',
      input: true,
      tooltip: '',
      customConditional(context) {
        return (
          context.row.actionType === CustomButtonActionType.showPopup &&
          context.row.popupAction?.popupType === 'custom'
        );
      },
      weight: 5
    },
    {
      key: 'popupAction.inputContextVariable',
      type: 'textfield',
      label: 'Context variable for input data',
      input: true,
      description:
        'Input data will be passed to the popup with the value that is assigned to this variable name. Use formInstance or formData as the base.',
      customConditional(context) {
        return (
          context.row.actionType === CustomButtonActionType.showPopup &&
          context.row.popupAction?.popupType === 'custom'
        );
      },
      weight: 5
    },
    {
      key: 'popupAction.width',
      type: 'textfield',
      label: 'Width of Poppup Screen',
      input: true,
      defaultValue: '45%',
      tooltip: 'Provide a percentage. Example, 45%',
      customConditional(context) {
        return (
          context.row.actionType === CustomButtonActionType.showPopup &&
          context.row.popupAction?.popupType === 'custom'
        );
      },
      weight: 6
    },
    {
      key: 'popupAction.isConditionalPopup',
      type: 'checkbox',
      label: 'Show On Condition (if true)',
      tooltip: '',
      customConditional(context) {
        return context.row.actionType === CustomButtonActionType.showPopup;
      },
      weight: 7
    },
    {
      key: 'popupAction.conditionToEvaluate',
      type: 'textarea',
      label: 'Show On Condition (if true)',
      description: 'Show popup based on condition',
      placeholder: 'show = true',
      tooltip: `write javascript code which is evaluated to give the input for action.`,
      editor: 'ace',
      as: 'javascript',
      wysiwyg: {
        minLines: 12
      },
      customConditional(context) {
        return (
          context.row.actionType === CustomButtonActionType.showPopup &&
          context.row.popupAction.isConditionalPopup
        );
      },
      defaultValue: 'show = true',
      weight: 7
    }
  ];
}

function conditionalTab() {
  return {
    label: 'Conditional',
    key: 'conditional',
    weight: 40,
    components: [
      {
        key: 'simple-conditional',
        type: 'panel',
        title: 'Simple',
        theme: 'default',
        weight: 105,
        components: [
          {
            key: 'conditional.show',
            type: 'select',
            input: true,
            label: 'This component should Display:',
            dataSrc: 'values',
            data: {
              values: [
                {label: 'True', value: 'true'},
                {label: 'False', value: 'false'}
              ]
            }
          },
          {
            type: 'select',
            input: true,
            label: 'When the form component:',
            key: 'conditional.when',
            dataSrc: 'custom',
            valueProperty: 'value',
            data: {
              custom(context) {
                return getContextComponents(context);
              }
            }
          },
          {
            type: 'textfield',
            input: true,
            label: 'Has the value:',
            key: 'conditional.eq'
          }
        ]
      },
      {
        type: 'textfield',
        label: 'ACL Key',
        key: 'aclResource',
        weight: 5,
        placeholder: 'ACL Key',
        input: true
      },
      {
        type: 'panel',
        title: 'JavaScript',
        collapsible: true,
        collapsed: false,
        style: {'margin-bottom': '10px'},
        key: 'customConditional-js',
        customConditional() {
          return true;
        },
        components: [
          {
            type: 'textarea',
            key: 'customConditional',
            rows: 5,
            editor: 'ace',
            hideLabel: true,
            as: 'javascript',
            input: true
          },
          {
            type: 'htmlelement',
            tag: 'div',
            content: `<p>Enter custom javascript code.</p>`
          }
        ]
      }
    ]
  };
}

function dataTab() {
  return {
    key: 'data',
    label: 'Data',
    components: [
      {
        key: 'customOptions.options',
        weight: 20,
        label: 'Values (DO NOT USE - DEPRECATED)',
        tooltip: '',
        type: 'datagrid',
        input: true,
        reorder: true,
        components: [
          {
            key: 'label',
            label: 'Label',
            type: 'textfield',
            input: true,
            validate: {
              required: false
            }
          },
          {
            key: 'value',
            label: 'Value',
            type: 'textfield',
            input: true,
            allowCalculateOverride: true,
            calculateValue: {_camelCase: [{var: 'row.label'}]},
            validate: {
              required: false
            }
          }
        ]
      },
      {
        key: 'customOptions.advanceSearchAttributesTemplate',
        weight: 703,
        type: 'textarea',
        input: true,
        label: 'Advanced Search Query',
        description: 'Advanced Search Query',
        placeholder: 'lodash template code',
        tooltip: `write javascript code which is evaluated to give the input for action.`,
        editor: 'ace',
        as: 'javascript',
        wysiwyg: {
          minLines: 12
        },
        defaultValue: undefined
      }
    ]
  };
}

function fileDownloadFormioModel() {
  return {
    key: 'download-file-action',
    type: 'panel',
    title: 'Download File',
    theme: 'primary',
    collapsible: true,
    weight: 105,
    conditional: {
      json: {
        '===': [
          {var: 'data.customOptions.actionType'},
          CustomActionType.downloadFile
        ]
      }
    },
    components: [
      {
        key: 'customOptions.fileDownloadConfig.apiUrl',
        weight: 200,
        type: 'textfield',
        input: true,
        label: 'API URL For File Download',
        placeholder: '',
        tooltip: '',
        validate: {
          required: true
        },
        conditional: {
          json: {
            '===': [
              {var: 'data.customOptions.actionType'},
              CustomActionType.downloadFile
            ]
          }
        }
      },
      {
        key: 'customOptions.fileDownloadConfig.apiMethod',
        weight: 200,
        type: 'select',
        input: true,
        label: 'API Method',
        placeholder: 'API Method',
        validate: {
          required: true
        },
        dataSrc: 'values',
        data: {
          values: [
            {label: 'GET', value: 'GET'},
            {label: 'POST', value: 'POST'},
            {label: 'PUT', value: 'PUT'}
          ]
        },
        defaultValue: 'GET',
        conditional: {
          json: {
            '===': [
              {var: 'data.customOptions.actionType'},
              CustomActionType.downloadFile
            ]
          }
        }
      },
      {
        weight: 200,
        type: 'textarea',
        input: true,
        key: 'customOptions.fileDownloadConfig.apiRequest',
        label: 'API Request Body to use',
        rows: 5,
        editor: 'ace',
        as: 'javascript',
        placeholder: '',
        tooltip: '',
        conditional: {
          json: {
            and: [
              {
                or: [
                  {
                    '===': [
                      {
                        var: 'data.customOptions.fileDownloadConfig.apiMethod'
                      },
                      'POST'
                    ]
                  },
                  {
                    '===': [
                      {
                        var: 'data.customOptions.fileDownloadConfig.apiMethod'
                      },
                      'PUT'
                    ]
                  }
                ]
              },
              {
                '===': [
                  {var: 'data.customOptions.actionType'},
                  CustomActionType.downloadFile
                ]
              }
            ]
          }
        }
      },
      {
        key: 'customOptions.fileDownloadConfig.navigateToUrl',
        weight: 200,
        type: 'textfield',
        input: true,
        label: 'Navigate After Download',
        conditional: {
          json: {
            and: [
              {
                '===': [
                  {var: 'data.customOptions.actionType'},
                  CustomActionType.downloadFile
                ]
              }
            ]
          }
        }
      }
    ]
  };
}

function showPopupActionFormioModel() {
  return {
    key: 'show-popup-action',
    type: 'panel',
    title: 'Show Popup Action',
    theme: 'primary',
    collapsible: true,
    weight: 105,
    conditional: {
      json: {
        or: [
          {
            '===': [
              {var: 'data.customOptions.actionType'},
              CustomActionType.showPopup
            ]
          },
          {
            '===': [
              {var: 'data.customOptions.postToUrlConfig.showPopup'},
              true
            ]
          }
        ]
      }
    },
    components: [
      {
        key: 'customOptions.showPopupConfig.headerMessage',
        weight: 200,
        type: 'textfield',
        input: true,
        label: 'Popup Header Message',
        placeholder: '',
        tooltip: '',
        validate: {
          required: true
        },
        conditional: {
          json: {
            or: [
              {
                '===': [
                  {var: 'data.customOptions.actionType'},
                  CustomActionType.showPopup
                ]
              },
              {
                '===': [
                  {var: 'data.customOptions.postToUrlConfig.showPopup'},
                  true
                ]
              }
            ]
          }
        }
      },
      {
        key: 'customOptions.showPopupConfig.message',
        weight: 200,
        type: 'textfield',
        input: true,
        label: 'Popup Message',
        placeholder: '',
        tooltip: '',
        validate: {
          required: true
        },
        conditional: {
          json: {
            or: [
              {
                '===': [
                  {var: 'data.customOptions.actionType'},
                  CustomActionType.showPopup
                ]
              },
              {
                '===': [
                  {var: 'data.customOptions.postToUrlConfig.showPopup'},
                  true
                ]
              }
            ]
          }
        }
      },
      {
        weight: 200,
        type: 'textfield',
        input: true,
        key: 'customOptions.showPopupConfig.popupScreenId',
        label: 'Form/Screen ID to show in popup',
        placeholder: 'Form/Screen ID to show in popup.',
        tooltip: '',
        validate: {
          required: true
        },
        conditional: {
          json: {
            or: [
              {
                '===': [
                  {var: 'data.customOptions.actionType'},
                  CustomActionType.showPopup
                ]
              },
              {
                '===': [
                  {var: 'data.customOptions.postToUrlConfig.showPopup'},
                  true
                ]
              }
            ]
          }
        }
      },
      {
        weight: 200,
        type: 'textfield',
        input: true,
        key: 'customOptions.showPopupConfig.returnValueStorageAttribute',
        label: 'Storage Attribute',
        placeholder: 'Attribute to store the return value from popup.',
        tooltip: '',
        validate: {
          required: false
        },
        conditional: {
          json: {
            or: [
              {
                '===': [
                  {var: 'data.customOptions.actionType'},
                  CustomActionType.showPopup
                ]
              },
              {
                '===': [
                  {var: 'data.customOptions.postToUrlConfig.showPopup'},
                  true
                ]
              }
            ]
          }
        }
      },
      {
        weight: 200,
        type: 'textarea',
        input: true,
        key: 'customOptions.showPopupConfig.responseProcessor',
        label: 'Response Processor',
        rows: 5,
        editor: 'ace',
        as: 'javascript',
        placeholder: '',
        tooltip: '',
        conditional: {
          json: {
            or: [
              {
                '===': [
                  {var: 'data.customOptions.actionType'},
                  CustomActionType.showPopup
                ]
              },
              {
                '===': [
                  {var: 'data.customOptions.postToUrlConfig.showPopup'},
                  true
                ]
              }
            ]
          }
        }
      }
    ]
  };
}

function getDataFromApiFormioModel() {
  return {
    key: 'get-api-data',
    type: 'panel',
    title: 'GET API Data',
    theme: 'primary',
    collapsible: true,
    weight: 105,
    conditional: {
      json: {
        '===': [
          {var: 'data.customOptions.actionType'},
          CustomActionType.getDataFromApi
        ]
      }
    },
    components: [
      {
        key: 'customOptions.getDataFromApiConfig.apiUrl',
        weight: 200,
        type: 'textfield',
        input: true,
        label: 'URL for GET API',
        placeholder: '',
        tooltip: '',
        description: `api url that can be interpolated. for interpolation the context data is <code>this._formInstance.submission</code><br>
            Ex: <code>api/core/cashier-module-payment-reference-collection-view/{{data.pac}}</code>`,
        validate: {
          required: true
        },
        conditional: {
          json: {
            '===': [
              {var: 'data.customOptions.actionType'},
              CustomActionType.getDataFromApi
            ]
          }
        }
      },
      {
        weight: 200,
        type: 'textarea',
        input: true,
        key: 'customOptions.getDataFromApiConfig.beforeApiHook',
        label: 'Before API Call Hook',
        rows: 5,
        editor: 'ace',
        as: 'javascript',
        placeholder: '',
        tooltip: '',
        description: 'Assign the processed value to <code>value</code> variable, which is considered as payload for the API call ',
        conditional: {
          json: {
            '===': [
              {var: 'data.customOptions.actionType'},
              CustomActionType.getDataFromApi
            ]
          }
        }
      },
      {
        weight: 201,
        type: 'textfield',
        input: true,
        key: 'customOptions.getDataFromApiConfig.storageAttribute',
        label: 'Store Data In Attribute',
        placeholder: 'Attribute Name.',
        tooltip: '',
        description: `The API response, before evaluating the responseProcessor logic, is merged with the existing value, from the <code>_this._formInstance.submission.data</code>, defined with field <code>storageAttribute</code>  then the final result is merged <code>_this._formInstance.submission.data</code>`,
        validate: {
          required: false
        },
        conditional: {
          json: {
            '===': [
              {var: 'data.customOptions.actionType'},
              CustomActionType.getDataFromApi
            ]
          }
        }
      },
      {
        weight: 202,
        type: 'textfield',
        input: true,
        key: 'customOptions.getDataFromApiConfig.smartTableStorageAttribute',
        label: 'Store Attribute Data Into Smart Table',
        placeholder: 'Attribute Name.',
        tooltip: '',
        description: ` reads the value defined by field <code>smartTableStorageAttribute</code>from the <b>responseValueProcessor</b> and refreshes the smartTAble with the data. If <code>smartTableStorageAttribute</code> is not defined, then the responseValueProcessor is set as is on the smartTable`,
        validate: {
          required: false
        },
        conditional: {
          json: {
            '===': [
              {var: 'data.customOptions.actionType'},
              CustomActionType.getDataFromApi
            ]
          }
        }
      },
      {
        weight: 200,
        type: 'textfield',
        input: true,
        key: 'customOptions.getDataFromApiConfig.dataPath',
        label: 'Data Path',
        placeholder: '',
        tooltip: '',
        description: `fieldName to pluck the data from the response json`,
        validate: {
          required: false
        },
        conditional: {
          json: {
            '===': [
              {var: 'data.customOptions.actionType'},
              CustomActionType.getDataFromApi
            ]
          }
        }
      },
      {
        weight: 200,
        type: 'textarea',
        input: true,
        key: 'customOptions.getDataFromApiConfig.responseProcessor',
        label: 'Response Processor',
        rows: 5,
        editor: 'ace',
        as: 'javascript',
        placeholder: '',
        description: `Evaluates the logic and reads the value assigned to variable  <code>value</code> and sets the value to the fields defined by <b>storageAttribute</b> in <code>_this._formInstance.submission.data</code>
<br>Note: the responseProcessor is executed after setting the apiResponse in  <b>storageAttribute</b> of <code>_this._formInstance.submission.data</code>`,
        tooltip: '',
        conditional: {
          json: {
            '===': [
              {var: 'data.customOptions.actionType'},
              CustomActionType.getDataFromApi
            ]
          }
        }
      },
      {
        key: 'customOptions.getDataFromApiConfig.smartTableComponent',
        type: 'select',
        input: true,
        label: 'Associated Smart Table Or GridData component',
        weight: 10,
        multiple: true,
        tooltip:
          'Select Smart Table or GridData component that should be actioned once the API data is loaded.',
        dataSrc: 'custom',
        valueProperty: 'value',
        data: {
          custom(context) {
            let values = [{}];
            context.utils.eachComponent(
              context.instance.options.editForm.components,
              function (component, path) {
                console.log(component.key, ' ', component.type);
                if (
                  component.key !== context.data.key &&
                  (component.type == 'smarttable' ||
                    component.type == 'datagrid')
                ) {
                  values.push({
                    label: component.label + ' (' + component.key + ')',
                    value: path
                  });
                }
              }
            );
            return values;
          }
        },
        conditional: {
          json: {
            '===': [
              {var: 'data.customOptions.actionType'},
              CustomActionType.getDataFromApi
            ]
          }
        }
      },
      {
        key: 'customOptions.getDataFromApiConfig.action',
        type: 'select',
        label: 'Action',
        input: true,
        tooltip: '',
        dataSrc: 'values',
        data: {
          values: [
            {label: 'Refresh', value: 'refresh'},
            {label: 'Set Response As Value', value: 'setResponseAsValue'}
          ]
        },
        defaultValue: 'refresh',
        weight: 1
      }
    ]
  };
}

function getContextComponents(context) {
  const values = [];

  context.utils.eachComponent(
    context.instance.options.editForm.components,
    (component, path) => {
      if (component.key !== context.data.key) {
        // @ts-ignore
        values.push({
          label: `${component.label || component.key} (${path})`,
          value: path
        });
      }
    }
  );

  return values;
}

class Status {
  static status = new Map();
}

export function registerCustomActionButtonComponent(
  injector: Injector,
  screen: string
) {
  if (!Status.status.has(screen)) {
    try {
      registerCustomFormioComponent(
        COMPONENT_OPTIONS,
        CustomActionButtonComponent,
        injector
      );
      Status.status.set(screen, true);
    } catch (e) {
      console.log('Error while registering Toggle Component: ' + screen);
    }
  }
}
