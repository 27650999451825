import { SubmitSuccessPopup } from '../../../portal/application-forms/all-forms-renderer/screen-config';
import { CustomButtonActionType } from '@shared/components/custom-action-button/handlers/custom-button-action-handler-registry';
import {FieldValues, IPopupConfig} from '@shared/components/screen-configuration/screen-configuration.model';

//Model for Chained Actions

export class ActionProcessor {
  actionType: CustomButtonActionType;
  actionName: string;
  apiConfig: SendDataToApiAction;
  popupAction: PopupAction;
  navigationConfig: NavigationConfig;

  customLogicConfig: CustomLogicConfig;

  redrawComponentsConfig: RedrawComponentsConfig;

  fieldValuesConfig: FieldValues[];

  toggleComponentsVisibilityConfig: ToggleComponentsVisibilityConfig;

  triggerEventConfig: TriggerEventConfig;

  isConditional: boolean = false;
  conditionToEvaluate?: string
}

export class TriggerEventConfig {
  eventName: string; //TODO: make it parameterized
}

export class ToggleComponentsVisibilityConfig {
  componentsToToggle: string[];
}

export class RedrawComponentsConfig {
  componentsToRedraw: string[];
}
export class CustomLogicConfig {
  scriptToExecute: string;
}
export class SendDataToApiAction {
  url: string;
  methodName: string;
  requestBodyParameter: string;
  skipValidation: boolean;
  requestBody: string;

  dataPath: string;
  isConditional?: boolean = false;
  conditionToEvaluate?: string;
}
export class PopupAction {
  popupType: string;
  message: string;
  headerText: string;
  screenId: string;
  inputContextVariable?: string;
  width: string;
  isConditionalPopup: boolean;
  conditionToEvaluate: string;
}

//Models uses in both the legacy and current version of implementation
export class NavigationConfig {
  navigateToUrl: string;
  stateToPropagate?: any;

  stateToPropagateEvaluator?: string;
}

//Models for legacy/previous version of implementation
export class FileDownloadConfig {
  apiUrl: string;
  apiMethod: string = 'GET';
  apiRequest?: string;

  navigateToUrl?: string;
}

export class GetDataFromApiConfig {
  apiUrl: string;

  storageAttribute?: string; //default will be submission.data

  dataPath: string; //default will be root node

  beforeApiHook?: string;

  responseProcessor?: string;

  apiMethod: string = 'GET';

  smartTableComponent?: string;

  smartTableStorageAttribute?: string;

  action?: string;
}

export class PopupConfig extends IPopupConfig {
  headerMessage: string;
  override popupScreenId = 'generic-pop-up';
  parentInputData?: string;
  message: string;
  width?: string = '45%'; //Default width to be 45%

  returnValueStorageAttribute?: string;
  closeRefOnDestroy?: boolean = false;

  isConditionalPopup?: boolean = false;

  conditionToEvaluate?: string;
}

export class ExecuteScriptAndShowPopupConfig {
  scriptToExecute: string;
  popupScreenId: string;
}

export class PostToUrlConfig {
  url: string;
  beforePost?: string;
  afterPost?: PostToUrlConfigAfterPost;
  requestBodyParameter?: string;
  methodName: 'POST';

  responseContextVariable?: string;

  skipValidation: boolean = false;

  showPopup: boolean = false;

  executePopupCondition?: string;
}

export class PostToUrlConfigAfterPost {
  action?: string;
  successPopupConfig?: SubmitSuccessPopup;
  errorPopupConfig?: SubmitSuccessPopup;

  apiResponseProcessor?: string;

  navigationUrl?: string;
}

export class SmartTableResetSearchResultsConfig {
  tablesToReset: string[];
  otherFieldsToReset: string[];
}
